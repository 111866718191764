import React, {useState} from 'react';
import { Container } from 'components/ActivityContainer';
import Card, { CardContent } from 'components/Card';
import { IconNote, MainHeading, SlightEmphasisIconNote } from 'components/Typography';
import  {  TwoColumns } from 'components/ResponsiveSections';
import Button, { FixedButton } from 'components/Button';
import LinkButton from 'components/LinkButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { foodLogModalPathFor, homePathFor, logMealModalMatchFor, logMealModalPathFor, userMealPathFor, viewMealPathFor, logActivityPathFor } from 'config/paths';
import { paywallPathFor } from 'redux/helpers';
import moment from 'moment';
import { ActivityTabs, ActivityTab } from 'components/BottomNav';
import { connect } from 'react-redux';
import { loadedDatesSelector, userRecordSelector, makeWeightRecordForDateSel, getComprehensiveWodSelector, getMealInfoMatrixSelector, remainingMacrosSel, loggedMealsForDateSel, propsMacroTargetsSel } from 'redux/selectors';
import { loadUser, logFoodFromDB, skipMeal, dismissTooltip, logDailyWeight } from 'redux/actions';
import { useTranslation } from 'react-i18next';
import loadingContainer, { LoadingContainerParent, fpDefaultMap } from 'components/LoadingHOC';
import FoodLogModal from 'views/FoodLogModal';
import { dateFormat, NUTRITION, TRAINING } from 'config/settings';
import LoggedMealsTable from 'partials/LoggedMealsTable';
import ScannedFoodLogModal from 'partials/ScannedFoodModal';
import MealLogModal from 'partials/MealLogModal';
import RecipeMealRow, { OffPlanRecipeMealRow, SkippedMealRow } from 'partials/RecipeMealRow';
import { MacroSummaryText } from 'partials/MacroSummary';
import * as _ from 'lib/utilities';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { WeightRecord, User } from 'lib/classes';
import { NumberInput } from 'components/TextInput';
import { InputWithErrors, formikInputProps } from 'components/Form';
import {IconMessage} from 'components/Typography';
import { useHistory } from "react-router-dom";
import classnames from 'classnames';
import WorkoutCard from 'partials/WorkoutCard';
import { AppleHealthSyncCard, ClientFormsTodoSection, MealPlanSetupCard, MealPlanStandaloneSetupSection, WorkoutPlanSetupCard, WorkoutPlanStandaloneSetupSection } from 'partials/SetupCards';
import withWorkout from 'partials/RoutineStateHOC';
import { SyncWorkoutsModal } from 'partials/WorkoutSyncModal';
import RatingPrompt from 'partials/RatingPrompt';
import todayOverviewImage from 'assets/img/TodayOverviewBoxGscale.png';
import FloatingActionButton, { FabChild } from 'components/FAB';
import { ResponsiveContainer } from 'components/ResponsiveContainer';
import { ProLinkButton } from 'partials/ProRequiredButton';
import { PlanNotReady, PlanNotReadyCard } from 'partials/PlanNotReady';
import { LikeRecipeForClientsPopup } from 'partials/PickClientPopups';
import { HabitLogCard } from 'partials/HabitLogCard';
import ActivityLogList from 'partials/ActivityLogList';
import { NotificationSettingsModal } from 'partials/NotificationSettings';

export const DayTabs = (props) => {
    const { now } = props;
    const dates = [now.clone().subtract(2,'days'),now.clone().subtract(1,'days'),now,now.clone().add(1,'days'),now.clone().add(2,'days')];

    return (
        <ActivityTabs>
            {dates.map(date => <ActivityTab id={"day-tab-link-"+date.format('MMDD')} path={homePathFor(date)} label={date.format('ddd')} key={date.format(dateFormat)} />)}
        </ActivityTabs>
    )
}

const ConnectedWorkoutCard = withWorkout('fullLoad',"card",false,true)(WorkoutCard);

const FixedLogActivityBtn = ({ date }) => {
    const history = useHistory();

    return (<FixedButton icon="plus" onClick={() => history.push(logActivityPathFor(date))} id="fixed-log-activity-btn" />)
}

const WorkoutSection = ({ date, user }) => {
    const { t } = useTranslation();

    return (
        <div>
            <h4 className="conversion-heading ml0">{t('Workout')}</h4>
            <ConnectedWorkoutCard date={date} basePath={homePathFor(date)} className="mb10" />
            <ActivityLogList date={date} fixedBtn={user.showTrainingOnly() && (<FixedLogActivityBtn date={date} />)} />
        </div>
    )
}

const StandaloneActivityLogSection = ({ date, user }) => {
    const { t } = useTranslation();

    return (
        <div id="standalone-activity-log">
            <h4 className="conversion-heading ml0">{t('Activity Log')}</h4>
            <ActivityLogList showEmptyMsg date={date} fixedBtn={user.showTrainingOnly() && (<FixedLogActivityBtn date={date} />)} />
        </div>
    )
}

const CompletedHabits = ({ habitLogs, square, hasWorkout, t }) => {

    return (
        <div>
            <div className="no-margin-top no-margin-bottom left-align">
                {t("Today's Habits")}:
                <div className={classnames("circle-check-cont",{ 'no-upsizing': (square || hasWorkout) })}>
                    {habitLogs.map(habitLog => {
                        return (
                            <div key={habitLog.id} className={classnames("circle-check", { logged: habitLog.isLogged()})} title={habitLog.habit.resolvedName(t)}>
                                {habitLog.isLogged() && (<FontAwesomeIcon icon={'check'} className="success-color" />)}
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export const OverviewCard = ({ user, remainingMacros, workout, square, getWorkoutPath, overlay, playIcon="play", children, noWorkout, date }) => {
    const { t } = useTranslation();
    const { calories, targetCalories, flexibleMacros, ...macros } = remainingMacros;
    let caloriesRemaining = _.roundToF(calories,10);
    if(caloriesRemaining < 0 && caloriesRemaining > -55) {
        caloriesRemaining = 0;
    }
    const pctDone = Math.min(Math.max((1 - caloriesRemaining/targetCalories)*100,0),100)
    const overshoot = caloriesRemaining < 0 ? Math.abs(caloriesRemaining) : null;
    const pctError = overshoot ? Math.min(Math.max((overshoot/targetCalories)*100,0),100) : null;
    const habitLogs = user.habitLogsByDate(date);
    const showHabits = habitLogs.length > 0;
    const showWorkout = (!noWorkout && user.workoutPlanSetupAndVisible());

    return (
        <div className="row">
            <div className="col s12 m10 offset-m1 l12">
                <div className={classnames("overview-card",{ square, mt15: !square, 'has-overlay': overlay })}>
                    {overlay}
                    <img src={todayOverviewImage} alt="" />
                    <div className="content-wrapper" >
                        <div className="today-circle-container">
                            <svg viewBox="0 0 36 36" className="circular-chart cc-white">
                            <path className="circle-bg" d="M18 2.0845
                                a 15.9155 15.9155 0 0 1 0 31.831
                                a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                            <path className="today-circle" strokeDasharray={`${pctDone}, 100`} d="M18 2.0845
                                a 15.9155 15.9155 0 0 1 0 31.831
                                a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                            {overshoot && (<path className="error-circle" strokeDasharray={`${pctError}, 100`} d="M18 2.0845
                                a 15.9155 15.9155 0 0 1 0 31.831
                                a 15.9155 15.9155 0 0 1 0 -31.831"></path>)}
                            <text x="18" y="15.35" className={classnames(`circle-text c${caloriesRemaining}`)}>{caloriesRemaining}</text>
                            <text x="18" y="19.35" className="circle-text ct-subtitle">{t("calories left")}</text>
                            </svg>
                            <div className="calorie-goal"><FontAwesomeIcon icon={overshoot ? "exclamation-circle" : "trophy"} className={classnames({ 'red-text': overshoot})} /> {targetCalories}</div>
                        </div>
                        <div className="position-relative">
                            <div className="no-wrap today-macro-container">
                                {Object.entries(macros).map(([macro,param]) => {
                                    let amount = Math.round(param.amount);
                                    if(param.dir > 0) {
                                        amount = Math.max(amount,0);
                                    }
                                    return (
                                        <div key={macro} className="calorie-spacing macros">
                                            <span className="macro-number-overview">{amount > 0 && (param.dir < 0 ? '<' : '>')} <span>{amount}</span>{t('g')}</span>
                                            <div>
                                                <div className={`macros-bg ${macro}-color`}>
                                                    {amount < 0 && (<FontAwesomeIcon icon="exclamation-circle" />)} {t(`${macro} ${param.dir < 0 ? 'left' : 'needed'}`)}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                                <div className="calorie-spacing macros">
                                    <span className="macro-number-overview">{t('Flexible')}</span>
                                    <div style={{ color: 'white' }}>
                                        {flexibleMacros.map((macro,index) => (
                                            <React.Fragment key={macro}>
                                                <div className={`macros-bg ${macro}-color`}>{t(macro)}</div>
                                                {index === 0 && flexibleMacros.length > 1 && ' & '}
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {(showHabits || showWorkout) && (
                            <React.Fragment>
                                {!(showHabits && showWorkout) && (<div className={classnames("divider-line mb10 mt15", { "hide-on-large-only": !square })}></div>)}
                                <div className="active-workout today-workout-container position-relative font-white">
                                    {showHabits && showWorkout && (<div className={classnames("divider-line full mb10 mt15", { "hide-on-large-only": !square })}></div>)}
                                    {showWorkout && (<div>
                                        <p className="no-margin-top no-margin-bottom left-align">{t("Today's Workout")}:</p>
                                        <div className={classnames("mt5 display-flex",{ clickable: workout })} id={workout ? '' : 'rest-day-header'}>
                                            {workout && (<LinkButton noColor noShadow className="transparent-btn" id={`workout-header-${workout.id}`} to={getWorkoutPath ? getWorkoutPath(workout) : user.resolvedWorkoutPreviewPath(workout.date)}>
                                                <FontAwesomeIcon icon={playIcon} />
                                                {_.abbrText(workout.displayName(t),30)}
                                            </LinkButton>)}
                                            {!workout && (<span className="transparent-btn-text">{t("Rest Day")}</span>)}
                                            <div className="ml10">
                                                <FontAwesomeIcon icon={workout ? "medal" : "snooze"} className={classnames("today-page-medal", { active: (workout && workout.logged), rest: !workout })} />
                                            </div>
                                        </div>
                                    </div>)}
                                    {showHabits && showWorkout && (<div className={classnames("divider-line full mb10 mt10")}></div>)}
                                    {showHabits && (
                                        <CompletedHabits habitLogs={habitLogs} t={t} square={square} hasWorkout={showWorkout} />
                                    )}
                                </div>
                            </React.Fragment>
                        )}
                    </div>    
                </div>
                {children}
            </div>
        </div>
    )
}

const makeMapStateToHeaderProps = () => {    
    return (state,props) => {
        const wodSelector = getComprehensiveWodSelector(props.date.format(dateFormat))
        return {
            remainingMacros: remainingMacrosSel(state,props),
            workout: wodSelector(state)
        }
    }
}

const ConnectedOverviewCard = connect(makeMapStateToHeaderProps)(OverviewCard);

const WeightLogSuccess = ({ weightRecord, setStatus, user, t }) => {
    
    return (
        <div style={{fontSize: '20px'}} className="clickable" id="logged-weight-container" onClick={() => setStatus("DEFAULT")}>
            <IconMessage message={`${_.roundToF(weightRecord.unitWeight(user.unitPreference),0.01)}${t(User.weightSuffix(user.unitPreference))}`} icon="check" size="1x" />
            <FontAwesomeIcon icon="pencil-alt" className="faint-color ml5" />
        </div>
    )
}

const WeightLogDefault = ({ user, formikProps, t }) => {

    return (
        <div id="unlogged-weight-container">
            <InputWithErrors
                label={t(User.weightSuffix(user.unitPreference))}
                name="weight"
                className="inline"
                component={NumberInput}
                {..._.pick(formikProps,formikInputProps)}
            />
            <Button tag="button" rounded color="primary" className="ml5" id="log-weight-btn" outlined type="submit"><FontAwesomeIcon icon="check" /> {t("Log")}</Button> 
        </div>
    )
}

const WeightLogLOC = loadingContainer({
    "DEFAULT": WeightLogDefault,
    "SUCCESS": WeightLogSuccess
})

class WeightLogSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = { status: (props.weightRecord ? "SUCCESS" : "DEFAULT") };
        this.setStatus = this.setStatus.bind(this);
    }

    setStatus(status) {
        this.setState({ status });
        if(status === 'SUCCESS') {
            const { requestRating } = this.props;
            requestRating && requestRating();
        } 
    }

    render() {
        const { weightRecord, user, date, t, logDailyWeight } = this.props;
        const { status } = this.state; 
        const resolvedStatus = weightRecord ? status : "DEFAULT";
        const initVals = { weight: (weightRecord ? weightRecord.unitWeight(user.unitPreference) : ''), units: user.unitPreference, date: date.format(dateFormat) };
    
        return (
            <div>
                <h4 className="conversion-heading ml0">
                    {t('Weight Log')}
                </h4>
                <Card className="weight-log text-center" key={weightRecord ? weightRecord.id : ''}>
                    <Formik initialValues={ initVals }
                            validationSchema={Yup.object().shape({
                                weight: WeightRecord.weightValidationObj(user.unitPreference)
                            })}
                            onSubmit={_.formikHandlerCreator(this,this.setStatus,logDailyWeight)}
                            initialErrors={{}} >
                                {({handleSubmit, ...formikProps}) => {
                                    return (
                                        <form onSubmit={handleSubmit}>
                                            <CardContent>
                                                <WeightLogLOC 
                                                    formikProps={formikProps}
                                                    user={user}
                                                    weightRecord={weightRecord}
                                                    setStatus={this.setStatus}
                                                    loadState={resolvedStatus}
                                                    t={t}
                                                />
                                            </CardContent>
                                        </form>
                                    )
                                }}
                    </Formik>
                </Card>
            </div>
        )
    }

    componentWillUnmount() {
        if(this.loadPromise) {
            this.loadPromise.cancel()
        }
    }
}

const makeMapStateToWeightLogProps = () => {
    const weightRecordSelector = makeWeightRecordForDateSel();
    return (state,props) => ({
        weightRecord: weightRecordSelector(state,props)
    })
}

const mapDispatchToWeightLogProps = dispatch => ({
    logDailyWeight: (data) => dispatch(logDailyWeight(data))
})

export const ConnectedWeightLog = connect(makeMapStateToWeightLogProps,mapDispatchToWeightLogProps)(WeightLogSection)

const MealPlanSetupSection = ({ user, dismissSetupCard }) => {
    const { t } = useTranslation();
    return (
        <div id="meal-plan-setup-section">
            <h4 className="conversion-heading ml0">{t('Meal Plan')}</h4>
            <MealPlanSetupCard user={user} dismissSetupCard={dismissSetupCard} />
        </div>
    )
}

const WorkoutPlanSetupSection = ({ user, dismissSetupCard }) => {
    const { t } = useTranslation();
    return (
        <div id="workout-plan-setup-section">
            <h4 className="conversion-heading ml0">{t('Workout Plan')}</h4>
            <WorkoutPlanSetupCard user={user} dismissSetupCard={dismissSetupCard} />
        </div>
    )
}

const FoodLogTable = ({ user, date, entries, readOnly, targets }) => {
    const { t } = useTranslation();
    const showTotals = !!targets || (entries && entries.length > 1);

    return (
        <Card className={classnames("text-center food-log mb5", { 'has-multiple': showTotals})}>
            {showTotals && (<div className={classnames("food-log-totals",{ 'with-goals': !!targets })}></div>)}
            { (!entries || entries.length === 0) && (
                <div className="pa25">
                    <IconNote variant="info" text={t("Nothing logged today.")} />
                </div>
            )}
            { ((entries && entries.length > 0) || showTotals) && (
                <div className="ma10 position-relative"><LoggedMealsTable userMeals={entries} readOnly={readOnly} targets={targets} /></div>
            )}
        </Card>
    )
}

const mapStateToFoodLogTableProps = (state, props) => ({
    entries: loggedMealsForDateSel(state,props),
    targets: propsMacroTargetsSel(state,props)
})

export const ConnectedFoodLogTable = connect(mapStateToFoodLogTableProps)(FoodLogTable);

const FoodLogSectionCore = ({ user, date, scanProps }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const clickHandler = () => history.push(foodLogModalPathFor(date));
    const activityBtn = (<FabChild label={t("Activity")} icon="running" className="fat-color" id="activity-log-btn" onClick={() => history.push(logActivityPathFor(date))} />)

    return (
        <ResponsiveContainer render={({ belowBreakpoint }) => {
            return (
                <div>
                    <h4 className="conversion-heading ml0">{t('Food Log')}</h4>
                    <ConnectedFoodLogTable user={user} date={date} />
                    {!belowBreakpoint && (<div className="text-right">
                        <LinkButton rounded color="primary" outlined className="shadow" to={foodLogModalPathFor(date)} id="food-log-modal-btn">
                            <FontAwesomeIcon icon="plus"></FontAwesomeIcon> {t("Other Food")}
                        </LinkButton>
                        {scanProps && (<Button rounded color="primary" outlined className="shadow" {...scanProps}>
                            <FontAwesomeIcon icon="barcode"></FontAwesomeIcon> {t("Scan")}
                        </Button>)}
                    </div>)}
                    {belowBreakpoint && (<FloatingActionButton
                        id={"log-off-plan-foods-btn"} 
                        btnClass="btn-primary" 
                        icon={"plus"}
                    >
                        {activityBtn}
                        <FabChild label={scanProps ? t("Other Food") : t('Food')} icon="utensils-alt" className="carb-color" id="food-log-modal-btn" onClick={clickHandler} />
                        {scanProps && (<FabChild id="fab-scan-btn" label={t('Scan')} icon="barcode" className="red" {...scanProps} />)}
                    </FloatingActionButton>)}
                    <RatingPrompt basePath={homePathFor(date)} context='logFood' render={({ requestRating }) => (
                        <FoodLogModal date={date} requestRating={requestRating} />
                    )} />
                </div>
            )
        }} />
    )
}

const ScannedModal = ({ logScanned, user, date, render }) => {
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <ScannedFoodLogModal 
            basePath={homePathFor(date)} 
            confirmTitle={(<React.Fragment><FontAwesomeIcon icon="check" /><span>{t("Log")}</span></React.Fragment>)} 
            confirmAction={(data) => logScanned({ ...data, date: date.format(dateFormat)})} 
            render={({ launchHandler }) => {
                let actionProps; 
                if(user.isPro()) {
                    actionProps = { onClick: launchHandler };
                } else {
                    actionProps = { onClick: () => history.push(paywallPathFor('scan_food',user)) };
                }

                return render({ scanProps: actionProps })
        }} />
    )
}

const FoodLogSection = ({ date, logScanned, user }) => {
    const sharedProps = { user, date };

    return (
        <ScannedModal 
            logScanned={logScanned}
            user={user}
            date={date}
            render={({ scanProps }) => (<FoodLogSectionCore {...sharedProps} scanProps={scanProps} />)}
        />
    )
}

const mapDispatchToFoodLogProps = dispatch => ({
    logScanned: (data) => dispatch(logFoodFromDB(null,data,'food'))
})

const ConnectedFoodLogSection = connect(null,mapDispatchToFoodLogProps)(FoodLogSection);

const SkippedMeal = ({ userMeal, t, skipMeal }) => {

    return (
        <Card className="meal-cards-today">
            <div className="card-tab recipe-card clickable" id={`unskip-meal-btn-${userMeal.id}`} onClick={skipMeal}>
                <FontAwesomeIcon icon="undo" /> {t("Unskip")}
            </div>
            <SkippedMealRow userMeal={userMeal} />
        </Card>
    )
}

const SkipTab = ({ userMeal, skipMeal }) => {
    const { t } = useTranslation();
    return (
        <div className="card-tab recipe-card clickable" id={`skip-meal-btn-${userMeal.id}`} onClick={skipMeal}>
            <FontAwesomeIcon icon="times" /> {t("Skip")}
        </div>
    )
}

export const UnskippedMeal = ({ user, date, userMeal, t, topTab, type='today' }) => {
    const hideLogButton = (type === 'single' && user.isPro());
    const PrepComp = type === 'single' ? LinkButton : ProLinkButton;
    let prepCompProps = null;
    if(!userMeal.isOffPlan()) {
        prepCompProps = type === 'single' ? { to: viewMealPathFor(userMeal.urlStub(),1) } : { user, to: userMealPathFor(userMeal.id,userMeal.mainRecipeId(),userMeal.date), blockTypes: ['old','hard'] }
    }

    return (
        <Card className="meal-cards-today">
            {topTab}
            {userMeal.isOffPlan() && (<React.Fragment>
                <OffPlanRecipeMealRow userMeal={userMeal} />
                <div className="meal-card-bottom">
                    <div className="flex-grow"></div>
                    <div className="prep-buttons">
                        <ProLinkButton blockTypes={['old','hard']} context="log_meal" user={user} key="log-btn" rounded color="primary" id={`log-meal-btn-${userMeal.id}`} outlined to={foodLogModalPathFor(moment(userMeal.date),userMeal.id)}>
                            <FontAwesomeIcon icon="check"></FontAwesomeIcon> {t("Log")}
                        </ProLinkButton>
                    </div>
                </div>
            </React.Fragment>)}
            {!userMeal.isOffPlan() && (<React.Fragment>
                {userMeal.recipeMeals.map((recipeMeal,index) => (
                    <RecipeMealRow 
                        key={recipeMeal.id} 
                        recipeMeal={recipeMeal} 
                        index={index} 
                        user={user} 
                        type={type} 
                    />
                ))}
                <div className="meal-card-bottom">
                    <div className="total-info">
                        <div className="today-total-macros">
                            {t("Total")}: <MacroSummaryText macroHash={userMeal.unloggedMacroHash()} />
                        </div>
                        <div className="today-total-macros"><FontAwesomeIcon icon={["far","clock"]} /> {userMeal.totalActiveTime()}{t("mins")}</div>
                    </div>
                    <div className="prep-buttons">
                            <PrepComp {...prepCompProps} key="prep-btn" rounded color="primary" id={`prep-meal-btn-${userMeal.id}`} outlined>
                                <FontAwesomeIcon icon="utensils-alt"></FontAwesomeIcon> {t("Prep")}
                            </PrepComp>
                            {!hideLogButton &&(<ProLinkButton blockTypes={['old','hard']} context="log_meal" user={user} key="log-btn" rounded color="primary" id={`log-meal-btn-${userMeal.id}`} className="ml5" outlined to={type === 'today' ? logMealModalPathFor(homePathFor(date),userMeal.id) : ''}>
                                <FontAwesomeIcon icon="check"></FontAwesomeIcon> {t("Log")}
                            </ProLinkButton>)}
                    </div>
                </div>
            </React.Fragment>)}
        </Card>
    )
}

const PlannedMeal = ({ user, userMeal, t, date, skipMeal }) => {

    const [skippedOverride,setSkippedOverride] = useState(null);

    const isSkipped = _.isBlank(skippedOverride) ? userMeal.isSkipped() : skippedOverride;

    const doSkipMeal = () => {
        setSkippedOverride(!isSkipped);
        skipMeal({ id: userMeal.id, type: isSkipped ? 'unskip' : 'skip' }).then(() => setSkippedOverride(null)).catch(() => setSkippedOverride(null));
    }

    return (
        <React.Fragment>
            <div className="today-meal-container">
                {userMeal.logTitle(null,t)}
            </div>
            {isSkipped && (<SkippedMeal userMeal={userMeal} t={t} skipMeal={doSkipMeal} />)}
            {!isSkipped && (<UnskippedMeal user={user} date={date} userMeal={userMeal} t={t} topTab={<SkipTab skipMeal={doSkipMeal} userMeal={userMeal} />} />)}
        </React.Fragment>
    )
}

const PlannedMealsList = ({userMeals, user, skipMeal, date, t }) => {

    return (
        <React.Fragment>
            {_.every(userMeals,userMeal => userMeal.isLogged()) && (<Card id="all-meals-logged-card" className="text-center"><div className="pa20"><IconMessage message={t("All planned meals logged!")} icon="check" /></div></Card>)}
            {userMeals.map(userMeal => (!userMeal.isLogged() && (<PlannedMeal key={userMeal.id} user={user} userMeal={userMeal} t={t} date={date} skipMeal={skipMeal} />)))}
            <RatingPrompt basePath={homePathFor(date)} context='logMeal' render={({ requestRating }) => (
                <MealLogModal pathMatch={logMealModalMatchFor(homePathFor(date))} user={user} basePath={homePathFor(date)} requestRating={requestRating}  />
            )}/>
        </React.Fragment>
    )
}

const PlannedMealsSection = ({ mealInfoMatrix, user, skipMeal, date }) => {
    const { t } = useTranslation();
    const userMeals = mealInfoMatrix.mealsOnDate(date);
    const mealPlanStarted = user.mealPlanStartedBy(date);
    const mealPlanGenerated = user.mealPlanGeneratedOn(date);
    const mealPlanPublished = mealInfoMatrix.isPublished();

    return (
        <div>
            <h4 className="conversion-heading ml0">{t("Planned Meals")}</h4>
            {!mealPlanStarted && (<SlightEmphasisIconNote id="meal-plan-not-started-note" text={t("Your meal plan doesn't start until X", { weekday: moment.weekdays()[user.mealPlanWeekday || 0] })} />)}
            {mealPlanStarted && (!mealPlanGenerated || !mealPlanPublished) && (<SlightEmphasisIconNote id="meals-not-generated-note" text={t("This week's meals haven't been generated yet")} />)}
            {mealPlanStarted && mealPlanGenerated && mealPlanPublished && (<PlannedMealsList userMeals={userMeals} user={user} skipMeal={skipMeal} date={date} t={t} />)}
        </div>

    )
}

const mapDayStateToPlannedMealProps =  (state,{ user, date }) => {
    const week = user.weekForDate(date,true);
    const mealInfoMatrixSelector = getMealInfoMatrixSelector(week);
    return {
        mealInfoMatrix: mealInfoMatrixSelector(state)
    }
}

const mapDispatchToPlannedMealsProps = dispatch => ({
    skipMeal: (params) => dispatch(skipMeal(params))
})

const ConnectedPlannedMeals = connect(mapDayStateToPlannedMealProps,mapDispatchToPlannedMealsProps)(PlannedMealsSection);

export const HabitsSection = ({ habitLogs, date, readOnly }) => {
    const { t } = useTranslation();

    return (
        <div>
            <MainHeading>{t('Habits')}</MainHeading>
            {habitLogs.length === 0 && (
                <SlightEmphasisIconNote text={t("no habits today")} />
            )}
            {habitLogs.map(habitLog => (<HabitLogCard key={habitLog.id}habitLog={habitLog} readOnly={readOnly} />))}
        </div>
    )
}



const DaySuccess = (props) => {
    const { user, dismissSetupCard, date } = props;
    const { t } = useTranslation();

     
    if(user.isUninitClient()) {
        return (
            <PlanNotReady user={user} />
        )
    }

    if(user.showTrainingOnly() && user.showWorkoutSetupCard()) {
        return (
            <WorkoutPlanStandaloneSetupSection user={user} />
        )
    }

    if(user.showNutritionOnly() && user.showMealPlanSetupCard()) {
        return (
            <MealPlanStandaloneSetupSection user={user} />
        )
    }

    const habitLogs = user.habitLogsByDate(date);

    const forms = user.getClientDashFormsOn(date);

    return (
        <React.Fragment>
            <Container>
                <div className="limit-50-h">
                    {user.mealPlanSetupAndVisible() && <ConnectedOverviewCard user={user} date={date} />}
                    {!user.showDayTabs() && (<div className="row"><div className="col s12"><h3 className="conversion-heading mt-inset-30 ml0 mb0">{t("Let's Get Started")}</h3></div></div>)}
                    <TwoColumns>
                        {forms.length > 0 && (<ClientFormsTodoSection forms={forms} />)}
                        {user.showMealPlanSetupCard() && (<MealPlanSetupSection user={user} dismissSetupCard={dismissSetupCard} />)}
                        {user.showWorkoutSetupCard() && (<WorkoutPlanSetupSection user={user} dismissSetupCard={dismissSetupCard} />)}
                        {user.showAppleHealthSyncCard() && (<AppleHealthSyncCard />)}
                        {user.showMealPlanNotReadyCard() && (<PlanNotReadyCard type={NUTRITION} />)}
                        {user.showWorkoutPlanNotReadyCard() && (<PlanNotReadyCard type={TRAINING} />)}
                        {user.mealPlanSetupAndVisible() && (<ConnectedFoodLogSection {...props} user={user} />)}
                        {user.mealPlanSetupAndVisible() && (<ConnectedPlannedMeals {...props} user={user} />)}
                        {(user.mealPlanSetupAndVisible() || user.workoutPlanSetupAndVisible()) && (
                            <RatingPrompt basePath={homePathFor(date)} context="logWeight" render={({ requestRating }) => (
                                <ConnectedWeightLog {...props} t={t} user={user} requestRating={requestRating} />
                            )}/>
                        )}
                        {user.workoutPlanSetupAndVisible() && (<WorkoutSection {...props} t={t} user={user} />)}
                        {user.showStandaloneActivityLog() && (<StandaloneActivityLogSection date={date} user={user} />)}
                        {(user.hasAnyAssignedHabits() || habitLogs.length > 0) && (<HabitsSection habitLogs={habitLogs} date={date} />)}
                    </TwoColumns>
                </div>
            </Container>
            <SyncWorkoutsModal basePath={homePathFor(date)} />
            <NotificationSettingsModal user={user} />
            <LikeRecipeForClientsPopup />
        </React.Fragment>
    )
}

const DayLoadingContainer = loadingContainer({
    ...fpDefaultMap,
    'SUCCESS': DaySuccess,
})

class Day extends React.Component {

    constructor(props) {
        super(props);
        this.preloaded = this.preloaded.bind(this);
    }

    render() {
        const { date, now, user, dismissSetupCard, workout } = this.props;
        return (
            <LoadingContainerParent 
                component={DayLoadingContainer}
                load={this.props.loadUser.bind(null,now)}
                preloaded={this.preloaded}
                date={date}
                user={user}
                workout={workout}
                dismissSetupCard={dismissSetupCard}
            />
        )
    }

    preloaded() {
        const { loadedDates, date } = this.props;
        return loadedDates.includes(date.format(dateFormat));
    }
}

const mapDayStateToProps =  (state) => {
    return {
        user: userRecordSelector(state),
        loadedDates: loadedDatesSelector(state)
    }
}

const mapDayDispatchToProps = dispatch => ({
    loadUser: (curDate) => dispatch(loadUser({ curDate })),
    dismissSetupCard: tipName => dispatch(dismissTooltip(tipName))
})

Day = connect(mapDayStateToProps,mapDayDispatchToProps)(Day);


export default function Today(props) {
    let { now, match: { params: { date } } } = props;
    date = moment(date);

    if(date.isBetween(now.clone().subtract(2,'days'),now.clone().add(2,'days'),'day','[]')) {
        return (
            <Day key={date.format('MM-DD')} date={date} now={now} />
        )
    } else {
        return <div></div>;
    }
}