import React, { useState } from 'react';
import * as _ from 'lib/utilities';
import moment from 'moment';
import { dateFormat } from 'config/settings';
import classnames from 'classnames';
import { AnimatedLineChart } from 'components/AnimatedLineChart';
import { FadeIn } from 'components/FadeIn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Chart = ({ data, yAxesOpts={}, yAxes, chartOpts={}, chartProps, scales}) => {
    scales = scales || {
        xAxes: [{
            type: 'time',
            time: {
                displayFormats: {
                    day: 'MMM D',
                    week: 'MMM D',
                    month: 'MMM D',
                    quarter: 'MMM D'
                },
                minUnit: 'day'
            },
            ticks: { autoSkip: true, maxTicksLimit: 5 }
        }],
        yAxes: yAxes || [{
            ticks: { autoSkip: true, maxTicksLimit: 4 },
            ...yAxesOpts
        }]
    }
    
    return (
        <AnimatedLineChart
            chartType="line"
            data={data}
            scales={scales}
            chartOpts={chartOpts}
            chartProps={chartProps}
        />
    )

}

const deltaMap = {
    0: {
        1: {
            slow: 1.0,
            moderate: 1.25,
            fast: 1.5
        },
        [-1]: {
            slow: -1.25,
            moderate: -1.5,
            fast: -1.75
        }
    },
    1: {
        1: {
            slow: 0.4,
            moderate: 0.5,
            fast: 0.6
        },
        [-1]: {
            slow: -0.55,
            moderate: -0.7,
            fast: -0.85
        }
    }
}

const planPaceAdj = { 0: 0.9, 1: 1, 2: 1.1 }

const bfPaces = {
    slow: 0.43,
    moderate: 0.5,
    fast: 0.57
}

export const getEndDateFor = (args) => {
    const { meta } = getDatasetInfoFor(args);
    const { endDate } = meta;

    return endDate;
}

export const getDatasetInfoFor = ({ unitPreference, weightGoal, simpleWeight, simpleGoalWeight, curBodyFat, goalBodyFat, specialEventDate, planPace, pace, t }) => {
    //data [{t: time, y: value}...]
    //label, pointStyle, lineTension, fill, borderColor
    const startDate = moment();
    const eventDate = _.isBlank(specialEventDate) ? null : moment(specialEventDate);
    let data = [];
    let endWeight,endDate;
    let diffByEvent, valByEvent = null;
    let minimalDiffByEvent = false;
    let weeklyDelta,weeksToGoal,goalDiff,startVal,goalSuffix;
    const minPoints = 40;
    const paceAdj = planPaceAdj[planPace];

    if(weightGoal === 0) {
        goalSuffix = `% ${t('Body Fat').toLowerCase()}`;
        curBodyFat = curBodyFat + 3;
        goalBodyFat = goalBodyFat + 3;
        endWeight = goalBodyFat;
        goalDiff = goalBodyFat - curBodyFat;
        if(goalDiff === 0) {
            goalDiff = -5;
            endWeight = curBodyFat + goalDiff;
        }
        
        weeklyDelta = goalDiff < 0 ? -bfPaces[pace] : bfPaces[pace];//bf % points
        weeklyDelta = weeklyDelta*paceAdj;

        if(Math.abs(goalDiff/weeklyDelta) > 50) {
            goalDiff = weeklyDelta*50;
            endWeight = curBodyFat + goalDiff;
        }
        startVal = curBodyFat;
        endWeight = `${endWeight}${goalSuffix}`;
    } else {
        goalSuffix = `  ${_.getUnitStr(unitPreference,t)}`;
        endWeight = simpleGoalWeight;
        goalDiff = simpleGoalWeight - simpleWeight;
        if(weightGoal*goalDiff < 0) {
            weightGoal = goalDiff <= 0 ? -1 : 1;
        }
        const slowPace = deltaMap[unitPreference][weightGoal].slow*paceAdj;
        weeklyDelta = deltaMap[unitPreference][weightGoal][pace]*paceAdj;

        if(Math.abs(goalDiff) < 5) {
            goalDiff = 10*weightGoal;
            endWeight = Math.round(simpleWeight + goalDiff);
        } else if(Math.abs(goalDiff/slowPace) > 50) {
            goalDiff = slowPace*50;
            endWeight = Math.round(simpleWeight + goalDiff);
        }
        startVal = simpleWeight;
        endWeight = `${endWeight}${goalSuffix}`;
    }
    weeksToGoal = Math.max(Math.abs(Math.round(goalDiff/weeklyDelta)),2);
    endDate = startDate.clone();
    endDate.add(weeksToGoal*7,'days');

    data.push({ t: startDate.format(dateFormat), y: startVal });
    let eventIndex = null;
    let extraPointsNeeded = minPoints - weeksToGoal;
    let extraPointsPerWeek = Math.max(Math.round(extraPointsNeeded / weeksToGoal),1);
    let hoursPerPoint = (7*24)/(extraPointsPerWeek + 1);

    for(let i=0; i < weeksToGoal; i++) {
        const newDate = startDate.clone();
        const week = i+1;
        newDate.add(week*7,'days');
        const weekVal = startVal + week*weeklyDelta;

        if(eventDate && newDate.isSameOrAfter(eventDate) && !diffByEvent) {
            const diff = Math.abs(Math.round(week*weeklyDelta));
            diffByEvent = `${diff}${goalSuffix}`;
            valByEvent = `${Math.round(weekVal)}${goalSuffix}`;
            minimalDiffByEvent = (week < 4);
            eventIndex = data.length;
        }
        for(let j=0; j < extraPointsPerWeek; j++) {
            const midDate = startDate.clone();
            midDate.add((week-1)*7*24 + hoursPerPoint*(j+1),'hours');
            const midVal = weeklyDelta < 0 ? _.random(weekVal+weeklyDelta,weekVal-weeklyDelta,true) : _.random(weekVal-weeklyDelta,weekVal+weeklyDelta,true);
            data.push({ t: midDate, y: midVal });
        }
        data.push({ t: newDate, y: weekVal });
    }

    const goalIndex = data.length-1;
    const flatDays = Math.round(weeksToGoal*7*0.1);
    const flatDate = startDate.clone();
    flatDate.add(weeksToGoal*7+flatDays,'days');
    data.push({ t: flatDate, y: startVal + weeksToGoal*weeklyDelta });

    return { 
        meta: { endWeight, endDate, type: (goalDiff > 0 ? 'gain' : 'lose'), diffByEvent, valByEvent, minimalDiffByEvent, goalIndex, eventIndex },
        datasets: [{ data, label: 'weight', pointStyle: 'line', lineTension: 0.2, fill: true, showLine: true, backgroundColor: '#8BC34A44', borderColor: '#8BC34A', duration: 1500, delay: 500 }]
    }
}

const GoalTip = ({ title, value, outlined, bottom, style, color, className }) => {
    const { top, left, ...rest } = style;
    const finalTop = _.isNumeric(top) ? (bottom ? top+10 : top-10) : top;

    return (
        <div className={classnames("est-prog-chart-tip",{ outlined, bottom, [color]: color, [className]: className })} style={{ left, top: finalTop, ...rest }}>
            <div>{title}</div>
            {!_.isBlank(value) && (<div>{value}</div>)}
        </div>
    )
}

export const EstimatedProgressChart = ({ data, goalIndex, eventIndex, valByEvent, specialEvent, endWeight, t }) => {
    const [visIndex,setVisIndex] = useState(0);
    const [goalCoords,setGoalCoords] = useState(null);
    const [eventCoords,setEventCoords] = useState(null);
    const eventToBottom = goalCoords && eventCoords && Math.abs(eventCoords.left - goalCoords.left) < 50;
    const { datasets } = data;

    return (
        <div style={{ pointerEvents: 'none', position: 'relative' }}>
            <Chart 
                data={{ datasets: [{ ...datasets[0], incrementCallback: (i) => setVisIndex(i)}]}} 
                chartOpts={ {
                    legend: {
                        display: false
                    }, 
                    tooltips: {
                        enabled: false
                    }
                }}
                chartProps={{
                    animation: { duration: 0 },
                    plugins: [{
                        afterDraw: function(chart, easing) {

                            [[eventIndex, setEventCoords],[goalIndex,setGoalCoords]].forEach(([i,setCoords]) => {
                                var tooltipModel = chart.getDatasetMeta(0).data[i];
                                if(tooltipModel) {
                                    setCoords({
                                        left: tooltipModel._model.x,
                                        top: tooltipModel._model.y
                                    })
                                }
                            })
                        },
                        afterRender: function(chart, easing) {

                            [[eventIndex, setEventCoords],[goalIndex,setGoalCoords]].forEach(([i,setCoords]) => {
                                var tooltipModel = chart.getDatasetMeta(0).data[i];
                                if(tooltipModel) {
                                    setCoords({
                                        left: tooltipModel._model.x,
                                        top: tooltipModel._model.y
                                    })
                                }
                            })
                        }
                    }]
                }}
            />
            {visIndex > goalIndex && goalCoords && (
                <GoalTip style={goalCoords} title={t('Goal')} value={endWeight} />
            )}
            {visIndex > eventIndex && eventCoords && (
                <GoalTip style={eventCoords} title={specialEvent} value={valByEvent} outlined bottom={eventToBottom} />
            )}
        </div>
    )
}

function interpolate(points, density) {
    const result = [];
    const paddedPoints = [ points[0], ...points, points[points.length-1] ];
    const n = paddedPoints.length;

    // Helper function to calculate spline between points
    function spline(p0, p1, p2, p3, t) {
        const v0 = (p2 - p0) / 2;
        const v1 = (p3 - p1) / 2;
        const t2 = t * t;
        const t3 = t * t2;
        return (2 * p1 - 2 * p2 + v0 + v1) * t3 + (-3 * p1 + 3 * p2 - 2 * v0 - v1) * t2 + v0 * t + p1;
    }

    // Generate points on the spline
    for (let i = 1; i < n - 2; i++) {
        for (let t = 0; t < 1; t += 1 / density) {
            const tVal = spline(paddedPoints[i - 1].t, paddedPoints[i].t, paddedPoints[i + 1].t, paddedPoints[i + 2].t, t);
            const yVal = spline(paddedPoints[i - 1].y, paddedPoints[i].y, paddedPoints[i + 1].y, paddedPoints[i + 2].y, t);
            result.push({ t: tVal, y: yVal });
        }
    }

    // Add the last point manually to close the curve
    result.push(paddedPoints[n-2]);

    const curTime = moment();
    const endTime = moment().add(1,'year');
    const range = points[points.length-1].t - points[0].t;
    const firstVal = points[0].t;

    result[0].t = curTime;
    result[result.length-1].t = endTime;

    result.forEach((res,i) => {
        if(i === 0 || i === result.length-1) {
            return;
        }

        const pctDone = (res.t - firstVal)/range;
        const newTime = curTime.clone();
        res.t = newTime.add(Math.round(pctDone*365),'days');
    })

    return result;
}

function stepInterpolate(points, density) {
    const result = [];
    const n = points.length;
    const timeDiff = points[1].t - points[0].t;

    // Generate points on the spline
    for (let i = 0; i < n-1; i++) {
        result.push(points[i]);
        for(let j=1; j < density; j++) {
            const tVal = points[i].t + (j/density)*timeDiff;
            result.push({ t: tVal, y: points[i].y });
        }
    }

    result.push(points[n-1]);

    const curTime = moment();
    const endTime = moment().add(1,'year');
    const range = points[points.length-1].t - points[0].t;
    const firstVal = points[0].t;

    result[0].t = curTime;
    result[result.length-1].t = endTime;

    result.forEach((res,i) => {
        if(i === 0 || i === result.length-1) {
            return;
        }

        const pctDone = (res.t - firstVal)/range;
        const newTime = curTime.clone();
        res.t = newTime.add(Math.round(pctDone*365),'days');
    })

    return result;
}

const psuedoReboundDatasets = () => {

    return {
        datasets: [
            { 
                data: interpolate([{ t: 0, y: 35 }, { t: 20, y: 40 }, { t: 40, y: 37 }, { t: 60, y: 40 }, { t: 80, y: 35 }, { t: 100, y: 41 }],5), 
                duration: 1000, 
                label: 'good',
                delay: 2000,  
                pointRadius: 0,
                fill: true,
                showLine: true,
                borderColor: '#8BC34A',
                backgroundColor: "#e1efd3"
            },
            { 
                data: interpolate([{ t: 0, y: 35 }, { t: 20, y: 45 }, { t: 40, y: 60 }, { t: 60, y: 65 }, { t: 80, y: 80 }, { t: 100, y: 100 }],5), 
                duration: 1000, 
                label: 'other',
                delay: 500,  
                pointRadius: 0,
                fill: true,
                showLine: true,
                borderColor: '#ff9800',
                backgroundColor: '#ff980044'
            }
        ]
    }
}

const psuedoFatLossDatasets = () => {

    return {
        datasets: [
            { 
                data: interpolate([{ t: 0, y: 100 }, { t: 20, y: 55 }, { t: 40, y: 35 }, { t: 60, y: 20 }, { t: 80, y: 15 }, { t: 100, y: 10 }],5), 
                duration: 1000, 
                label: 'good',
                delay: 2000,  
                pointRadius: 0,
                fill: true,
                showLine: true,
                borderColor: '#8BC34A',
                backgroundColor: "#e1efd3"
            },
            { 
                data: interpolate([{ t: 0, y: 100 }, { t: 20, y: 70 }, { t: 40, y: 50 }, { t: 60, y: 70 }, { t: 80, y: 40 }, { t: 100, y: 90 }],5), 
                duration: 1000, 
                label: 'other',
                delay: 500,  
                pointRadius: 0,
                fill: true,
                showLine: true,
                borderColor: '#ff9800',
                backgroundColor: '#ff980044'
            }
        ]
    }
}

const psuedoMuscleGainDatasets = () => {

    return {
        datasets: [
            { 
                data: interpolate([{ t: 0, y: 25 }, { t: 20, y: 32 }, { t: 40, y: 27 }, { t: 60, y: 37 }, { t: 80, y: 26 }, { t: 100, y: 36 }],5), 
                duration: 1000, 
                label: 'other',
                delay: 500,  
                pointRadius: 0,
                fill: true,
                showLine: true,
                borderColor: '#ff9800',
                backgroundColor: '#ff980044'
            },
            { 
                data: interpolate([{ t: 0, y: 25 }, { t: 20, y: 37 }, { t: 40, y: 45 }, { t: 60, y: 56 }, { t: 80, y: 68 }, { t: 100, y: 75 }],5), 
                duration: 1000, 
                label: 'good',
                delay: 2000,  
                pointRadius: 0,
                fill: 1,
                showLine: true,
                borderColor: '#8BC34A',
                backgroundColor: '#8BC34A44'
            }
        ]
    }
}

const dsetMap = { gain: psuedoMuscleGainDatasets, lose: psuedoFatLossDatasets, rebound: psuedoReboundDatasets }

export const ComparativeChart = ({ type }) => {
    const data = dsetMap[type]();

    return (
        <div className="chartholder" style={{ pointerEvents: 'none', width: '480px', height: '100%' }}>
            <Chart 
                data={data} 
                scales={{
                    xAxes: [{
                        offset: false,
                        type: 'time',
                        ticks: {
                            display: false
                        },
                        scaleLabel: {
                            display: false
                        },
                        gridLines: { display: false, drawBorder: false }
                    }],
                    yAxes: [{
                        offset: false,
                        ticks: {
                            display: false,
                            beginAtZero: true,
                            suggestedMax: type === 'gain' ? 90 : 120
                        },
                        scaleLabel: {
                            display: false
                        },
                        gridLines: { display: false, drawBorder: false }
                    }]
                }}
                chartOpts={ {
                    legend: {
                        display: false
                    }, 
                    tooltips: {
                        enabled: false
                    },
                    layout: {
                        padding: { left: 0, right: 0, top: 0, bottom: 0 }
                    },
                    responsive: true,
                    maintainAspectRatio: true,
                    aspectRatio: 1.777
                }}
                chartProps={{
                    height: 270,
                    width: 480,
                    animation: { duration: 0 },
                    plugins: [{
                        afterDraw: function(chart, easing) {

                           
                        },
                        afterRender: function(chart, easing) {

                          
                        }
                    }]
                }}
            />
        </div>
    )
}

const strtstDelay = 500;
export const progOvrPerfDelay = 1500;
export const progOvrDiffDelay = 2000;

const psuedoProgOverloadDatasets = () => {

    return {
        datasets: [
            { 
                data: stepInterpolate([{ t: 0, y: 15 }, { t: 20, y: 27 }, { t: 40, y: 35 }, { t: 60, y: 46 }, { t: 80, y: 58 }, { t: 100, y: 65 }],5), 
                duration: 1000, 
                label: 'other',
                delay: progOvrDiffDelay,  
                pointRadius: 0,
                fill: true,
                showLine: true,
                lineTension: 0,
                borderColor: '#ff9800',
                backgroundColor: '#ff980044'
            },
            { 
                data: interpolate([{ t: 0, y: 25 }, { t: 20, y: 37 }, { t: 40, y: 45 }, { t: 60, y: 56 }, { t: 80, y: 68 }, { t: 100, y: 75 }],5), 
                duration: 1500, 
                label: 'good',
                delay: progOvrPerfDelay,  
                pointRadius: 0,
                fill: 1,
                showLine: true,
                borderColor: '#8BC34A',
                backgroundColor: '#8BC34A44'
            }
        ]
    }
}

const psuedoNoProgOverloadDatasets = () => {

    return {
        datasets: [
            { 
                data: interpolate([{ t: 0, y: 15 }, { t: 20, y: 27 }, { t: 40, y: 40 }, { t: 60, y: 40 }, { t: 80, y: 40 }, { t: 100, y: 40 }],5), 
                duration: 1500, 
                label: 'good',
                delay: progOvrPerfDelay-1000,  
                pointRadius: 0,
                fill: true,
                showLine: true,
                borderColor: '#8BC34A',
                backgroundColor: '#8BC34A44'
            },
            { 
                data: interpolate([{ t: 0, y: 60 }, { t: 20, y: 63 }, { t: 40, y: 55 }, { t: 60, y: 60 }, { t: 80, y: 55 }, { t: 100, y: 60 }],5), 
                duration: 1000, 
                label: 'other',
                delay: progOvrDiffDelay-1000,  
                pointRadius: 0,
                fill: 1,
                showLine: true,
                lineTension: 0,
                borderColor: '#ff9800',
                backgroundColor: '#ff980044'
            }
        ]
    }
}

const progOvrLoadDsets = { overload: psuedoProgOverloadDatasets(), noOverload: psuedoNoProgOverloadDatasets() };

export const ProgOverloadChart = ({ t, type }) => {
    const data = progOvrLoadDsets[type];

    return (
        <div className="chartholder" style={{ pointerEvents: 'none', width: '480px', height: '100%' }}>
            <Chart 
                data={data} 
                scales={{
                    xAxes: [{
                        offset: false,
                        type: 'time',
                        ticks: {
                            display: false
                        },
                        scaleLabel: {
                            display: false
                        },
                        gridLines: { display: false, drawBorder: false }
                    }],
                    yAxes: [{
                        offset: false,
                        ticks: {
                            display: false,
                            beginAtZero: true,
                            suggestedMax: 90
                        },
                        scaleLabel: {
                            display: false
                        },
                        gridLines: { display: false, drawBorder: false }
                    }]
                }}
                chartOpts={ {
                    legend: {
                        display: false
                    }, 
                    tooltips: {
                        enabled: false
                    },
                    layout: {
                        padding: { left: 0, right: 0, top: 0, bottom: 0 }
                    },
                    responsive: true,
                    maintainAspectRatio: true,
                    aspectRatio: 1.777
                }}
                chartProps={{
                    height: 270,
                    width: 480,
                    animation: { duration: 0 }
                }}
            />
            {type === 'overload' && (<FadeIn delay={strtstDelay}>
                <GoalTip 
                    style={{ maxWidth: '54px', whiteSpace: 'normal', lineHeight: 1, fontSize: '9px', padding: '3px', top: '68%', left: '7px' }} 
                    outlined 
                    color="blue-tip" 
                    title={t('Strength Test')} 
                />
            </FadeIn>)}
        </div>
    )
}

const workoutIndices = [0,10,20];

const mpsDatasets = () => {

    return {
        datasets: [
            { 
                data: interpolate([{ t: 0, y: 0 }, { t: 10, y: 50 }, { t: 20, y: 30 }, { t: 30, y: 50 }, { t: 40, y: 30 }, { t: 50, y: 50 }],5), 
                duration: 1000, 
                label: 'good',
                delay: 500,  
                pointRadius: 0,
                fill: true,
                showLine: true,
                borderColor: '#8BC34A',
                backgroundColor: '#8BC34A44'
            }
        ]
    }
}

export const MpsChart = ({ t }) => {
    const { datasets } = mpsDatasets();
    const [visIndex,setVisIndex] = useState(0);
    const [workoutCoords,setWorkoutCoords] = React.useState([]);
    const maxIndex = _.filter(workoutIndices,i => (visIndex >= i)).length;

    return (
        <div className="chartholder" style={{ pointerEvents: 'none', width: '480px', height: '100%' }}>
            <Chart 
                data={{ datasets: [{ ...datasets[0], incrementCallback: (i) => setVisIndex(i)}]}} 
                scales={{
                    xAxes: [{
                        offset: false,
                        type: 'time',
                        ticks: {
                            display: false
                        },
                        scaleLabel: {
                            display: false
                        },
                        gridLines: { display: false, drawBorder: false }
                    }],
                    yAxes: [{
                        offset: false,
                        ticks: {
                            display: false,
                            beginAtZero: true,
                            suggestedMax: 90
                        },
                        scaleLabel: {
                            display: false
                        },
                        gridLines: { display: false, drawBorder: false }
                    }]
                }}
                chartOpts={ {
                    legend: {
                        display: false
                    }, 
                    tooltips: {
                        enabled: false
                    },
                    layout: {
                        padding: { left: 0, right: 0, top: 0, bottom: 0 }
                    },
                    responsive: true,
                    maintainAspectRatio: true,
                    aspectRatio: 1.777
                }}
                chartProps={{
                    height: 270,
                    width: 480,
                    animation: { duration: 0 },
                    plugins: [{
                        afterDraw: function(chart, easing) {
                            const newWorkoutCoords = _.compact(workoutIndices.map((dataI,i) => {
                                var tooltipModel = chart.getDatasetMeta(0).data[dataI];
                                if(tooltipModel) {
                                    return {
                                        left: tooltipModel._model.x-15,
                                        top: tooltipModel._model.y
                                    }
                                }

                                return null;
                            }))
                            setWorkoutCoords(newWorkoutCoords);
                        },
                        afterRender: function(chart, easing) {
                            const newWorkoutCoords = _.compact(workoutIndices.map((dataI,i) => {
                                var tooltipModel = chart.getDatasetMeta(0).data[dataI];
                                if(tooltipModel) {
                                    return {
                                        left: tooltipModel._model.x-15,
                                        top: tooltipModel._model.y
                                    }
                                }

                                return null;
                            }))
                            setWorkoutCoords(newWorkoutCoords);
                        }
                    }]
                }}
            />
            {workoutCoords.slice(0,maxIndex).map(coords => (<div style={{ position: 'absolute', ...coords }}><FontAwesomeIcon icon={['far','dumbbell']} /></div>))}
        </div>
    )
}