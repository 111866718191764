import React from 'react';
import { SetImplementation, registerInflection } from 'lib/record-base';
import * as _ from 'lib/utilities';
import { UserMeal } from 'lib/user-meal';
import { StrengthStandards } from 'lib/user';
import { Trans } from 'react-i18next';
import { workoutDoPathFor } from 'config/paths';
import { AMRAP_TIP } from 'config/tooltips';
import { InitialTip } from 'partials/Utilities';

export class ExerciseSet extends SetImplementation {
    static NAME = 'ExerciseSet'
    static LOGGABLE_ATTRS = ['logType','reps','kgWeight','weight','unbroken','seconds','minutes','minSecTime','isoTime','time','distance','kmDistance','mileDistance','arbitraryMeasure']
    static ASSOCS = { 
        exerciseSpecification: { type: 'belongsTo' },
        setTemplate: { type: 'belongsTo' }
    }

    nextPath(checkRest=false) {
        return this.resolvedWorkout().nextPath(this,checkRest);
    }

    afterInitialPath() {
        return this.supersetArr().afterInitialPath(this);
    }

    pathFor(suffix='') {
        return workoutDoPathFor(this.date(),this.workoutSetIndex(),suffix);
    }

    nextSet() {
        return this.resolvedWorkout().nextSetFor(this);
    }

    useCountdownDuration() {
        if(this.isAmrap() || this.isFixedReps() || !_.isBlank(this.maxReps)) {
            return false;
        } else {
            return true;
        }
    }

    useWarmupInstructions() {
        return (this.warmup || this.isWarmupSetType());
    }

    durationStr(t) {
        if(this.useCountdownDuration()) {
            return t('the duration');
        } else if(this.isAmrap()) {
            return t("as long as possible");
        } else {
            return this.rangeRepGoalStr(t,true);
        }
    }

    setInstructions(t) {
        if(this.isVideoOnly()) {
            return t('Follow along with the video');
        } else if(this.isTimedActivity()) {
            return t('Start the timer and do this activity for the duration');
        } else if(this.isOpenEndedActivity()) {
            return t('Do this activity for as long as you want. Use the timer to keep track.');
        } else if(this.isMaxTest()) {
            return t("Work up to a new X rep max", {reps: this.minReps});
        } else if(this.useWarmupInstructions() || (this.isDistanceBased() && !this.isCardio())) {
            return null;
        } else if(this.isRegularReps()) {
            if(this.isIsometric()) {
                const duration = this.durationStr(t);
                return (
                    <React.Fragment>
                        <Trans i18nKey="Start the timer and hold for duration">
                            {this.useCountdownDuration() ? (<span>{{duration}}</span>) : (<b>{{duration}}</b>)}
                        </Trans> {!this.isAmrap() && t("Stop the set early if you can't hold any longer")} 
                         {this.isUnilateral() && t("Then reset the timer and do the same on the other side")}
                    </React.Fragment>
                )
            } else {
                const topKey = this.isUnilateral() ? "Do reps on one side" : "Do reps";
                const reps = this.rangeRepGoalStr(t,true);
                const innerComp = this.showAmrapTip() ? (
                    <InitialTip
                        options={{ 
                            html: "<b>Important!</b> The '+' sign means you should do <b>as many reps as possible</b>. Put in your best effort because the app uses your performance in these sets to progress future workouts.",
                            classes: ['square'],
                            position: 'top'
                        }}
                        component='b'
                        autoShowAfter={300}
                        tipName={AMRAP_TIP}
                        noMouseOver
                    >
                        {{reps}}
                    </InitialTip>
                ) : (<b>{{reps}}</b>);

                return (
                    <React.Fragment>
                        <Trans i18nKey={topKey} >
                            {innerComp}
                        </Trans> {t("Stop if your form falls apart")} 
                         {this.isUnilateral() && t("Then do the same on the other side")}
                         {this.isAlternating() && t("One cycle through each side is a single rep")}
                    </React.Fragment>
                )
            }
        } else if(this.isFixedReps()) {
            if(this.isIsometric()) {
                return (
                    <React.Fragment>
                        {t("Start the time and hold for the duration, pausing/resting as needed to finish")} 
                         {this.isUnilateral() && t("Then reset the timer and do the same on the other side")} 
                         {this.isAlternating() && t("Alternate sides every few seconds")}
                    </React.Fragment>
                )
            } else {
                const topKey = this.isUnilateral() ? "Do reps on each side" : "Do reps";
                const reps = this.rangeRepGoalStr(t,true);
                return (
                    <React.Fragment>
                        <Trans i18nKey={topKey}>
                            <b>{{reps}}</b>
                    </Trans> {t('Rest as needed to finish all reps')} {this.isAlternating() && t("One cycle through each side is a single rep")}
                    </React.Fragment>
                )
            }
        } else if(this.isTimed()) {
            if(this.isIsometric()) {
                return (
                    <React.Fragment>
                        {t("Start the timer and hold the position for as many seconds as possible over the duration")} 
                         {t("Rest as needed but keep the timer running")}
                         {this.isUnilateral() && t("Split the time evenly between sides")} 
                         {this.isAlternating() && t("Alternate sides every few seconds")}
                    </React.Fragment>
                )
            } else {
                return (
                    <React.Fragment>
                        {t("Start the timer and do as many reps as possible over the duration")}
                         {this.isUnilateral() && t("Split the time evenly between sides")} 
                         {this.isAlternating() && t("One cycle through each side is a single rep")}
                    </React.Fragment>
                )
            }
        } else { //time and distance based sets
            let transKey;
            if(this.isCardio()) {
                const targetRpe = this.targetRpe();
                switch(targetRpe) {
                    case 'fast': {
                        transKey = "go as fast as possible for dist_or_time";
                        break;
                    }
                    case 'hard': {
                        transKey = "go at a hard pace for dist_or_time";
                        break;
                    }
                    case 'moderate': {
                        transKey = "go at a challenging pace for dist_or_time";
                        break;
                    }
                    case 'easy': {
                        transKey = "go at a moderate pace for dist_or_time";
                        break;
                    }
                    case 'slow': {
                        transKey = "go at an easy pace for dist_or_time";
                        break;
                    }
                    default: {
                        transKey = "go at a moderate pace for dist_or_time";
                    }
                }
            } else {
                transKey = "go as far as you can before it runs out";
            }

            const dist_or_time = this.isTimeBased() ? t('the duration') : this.distStr(t);

            const TransComp = () => (
                <Trans i18nKey={transKey} >
                    <span className={this.isDistanceBased() ? 'capitalizer' : ''}></span>
                    <b></b>
                    {{dist_or_time}}
                </Trans>
            );

            if(this.isDistanceBased()) {
                return (<TransComp />);
            } else {
                return (<Trans i18nKey={"Start the timer and instruction"}><TransComp /></Trans>);
            }

        }
    }

    comments() {
        return this.exerciseSpecification.comments();
    }

    showAmrapTip() {
        const user = this.workout() && this.workout().user;
        if(user) {
            return this.isAmrap() && !user.sawTooltip(AMRAP_TIP);
        }

        return false;
    }

    workout() {
        return this.exerciseSpecification.workout;
    }

    exercisable() {
        return this.exerciseSpecification.exercisable();
    }

    resolvedWorkout() {
        if(this.workout().isWarmup || this.workout().isCooldown) {
            return this.workout().parentWorkout;
        } else {
            return this.workout();
        }
    }

    date() {
        return this.resolvedWorkout().date;
    }

    workoutSection() {
        return this.exerciseSpecification.workoutSection();
    }

    estimatedTime() {
        if(this.isVideoOnly()) {
            return this.exercise().videoDuration || 300;
        } else if(this.isTimedActivity()) {
            return this.time;
        } else if(this.isOpenEndedActivity()) {
            return 600;
        } else if(this.isTimeBased() || this.isTimed()) {
            return this.time + this.restTime;
        } else if(this.isDistanceBased()) {
            return (this.distance || 0)/3 + this.restTime;
        } else if(this.isIsometric()) {
            return this.repsForRpe() + this.restTime;
        } else if(this.isMaxTest()) {
            return 300;
        } else {
            return this.repsForRpe()*this.exercise().timePerRep() + this.restTime;
        }
    }

    exercise() {
        return this.exerciseSpecification.exercise;
    }

    validate(t) {
        let errors = {};

        if(this.isVideoOnly()) return errors;

        const valsToCheck = this.formValues();

        Object.entries(valsToCheck).forEach(([attr,val]) => {
            switch(attr) {
                case 'reps': {
                    if(_.isBlank(val)) {
                        errors[attr] = t("Required");
                    } else if(val < 0) {
                        errors[attr] = t("must be at least", {count: 0});
                    }
                    return;
                }
                case 'kgWeight':
                case 'weight': {
                    if(_.isBlank(val)) {
                        errors[attr] = t("Required");
                    } else if(val < 0 && !this.hasBaseWeight()) {
                        errors[attr] = t("must be at least", {count: 0});
                    }
                    return;
                }
                default: {
                    return;
                }
            }
        });
        return errors;
    }

    setIndex() {
        if(this.warmup) {
            return this.exerciseSpecification.warmupSets().indexOf(this) + 1;
        } else {
            return this.exerciseSpecification.workSets().indexOf(this) + 1;
        }
    }

    setIndexStr(t) {
        if(this.warmup) {
            const sets = this.exerciseSpecification.warmupSets();
            const totalSets = sets.length;
            return t("Warmup Set X of Y", { current_set: sets.indexOf(this) + 1, total_sets: totalSets });
        } else {
            const sets = this.exerciseSpecification.workSets();
            const totalSets = sets.length;
            return t("Set X of Y", { current_set: sets.indexOf(this) + 1, total_sets: totalSets });
        }
    }

    workoutSetIndex() {
        return `${this.exerciseSpecificationId}-${this.warmup ? 'warmup' : 'work'}-${this.setIndex()-1}`;
    }

    logged() {
        if(this.workout().isWarmup) {
            return !this.resolvedWorkout().needsWarmup();
        } else if(this.workout().isCooldown) {
            return !this.resolvedWorkout().needsCooldown();
        } else if(this.warmup) {
            return (this.logType === UserMeal.LOGGED || this.exerciseSpecification.anyWorkLogged());
        }
        return this.logType === UserMeal.LOGGED;
    }

    unlogged() {
        return !this.logged();
    }

    possibleAttrs() {
        return Object.keys(this.exerciseSpecification.logParams(val => val))
    }

    formValues(defaultReps=true) {
        const possibleAttrs = this.possibleAttrs();
        const loggableAttrs = _.intersection(possibleAttrs,ExerciseSet.LOGGABLE_ATTRS);
        if(defaultReps && (loggableAttrs.includes('reps') || loggableAttrs.includes('isoTime'))) {
            if(this.isAmrap() && this.minReps && _.isBlank(this.reps)) {
                this.reps = this.minReps;
            } else {
                this.reps = _.isBlank(this.reps) ? this.defaultReps() : this.reps;
            }
        }
        let attrs = this.extract(loggableAttrs);

        return _.parseObjForForm(attrs);
    }

    isoTime(setVal) {
        return this.timeSetter(setVal,'reps');
    }

    loadingField() {
        return _.intersection(this.possibleAttrs(),['arbitraryMeasure','kgWeight','weight'])[0];
    }

    distStr(t) {
        if(this.exerciseSpecification.useMeters()) {
            return `${Math.round(this.distance)}${t('m')}`
        } else if(this.exerciseSpecification.useMetricDistance()) {
            return `${this.kmDistance()} ${t('km')}`
        } else {
            return `${this.mileDistance()} ${t('mi')}`
        }
    }

    distAttr() {
        return Object.keys(this.exerciseSpecification.distHash(val => val))[0];
    }

    restStr(t) {
        return _.stopwatchFormat(this.restTime);
    }

    hasAutoTimers() {
        return (this.isTimed() || this.isTimeWork() || (this.isTimeWarmup() && !this.isUnilateral()));
    }

    autoAdvances() {
        return this.hasAutoTimers() && !this.isLastOfSuperset();
    }

    autoStarts() {
        return this.hasAutoTimers() && this.exerciseSpecification.exerciseSets[0] !== this;
    }

    restLogField() {
        if(this.isVideoOnly()) {
            return null;
        }

        if(this.restTime >= 20) {
            if(this.isTimed()) {
                if(!this.isIsometric()) {
                    return 'reps';
                }
            } else if(this.isTimeWork()) {
                return this.distAttr();
            }
        }
        return null;
    }

    isCountdownActivity() {
        return this.isTimedActivity() || this.isTimeWork();
    }

    walkthroughFields(t) {
        if(this.isVideoOnly()) {
            return {};
        } else if(this.isTimedActivity()) {
            return { countdown: { seconds: this.time, field: null } };
        } else if(this.isOpenEndedActivity()) {
            return { countup: { field: 'time'} };
        } else if(this.isMaxTest()) {
            return { repMaxReps: null }
        } else if(this.isWarmupReps() || this.warmup) {
            if(this.isIsometric()) {
                if(this.hasRangedReps()) {
                    return { isoRange: null, countup: { field: 'reps' }};
                } else {
                    return { countdown: { title: t('Hold for'), seconds: this.minReps, field: 'reps'} };
                }
            } else {
                return { repsGoal: null };
            }
        } else if(this.isAmrap()) {
            if(this.isIsometric()) {
                return { isoRange: null, countup: { field: 'reps' } };
            } else {
                return { reps: null };
            }
        } else if(this.isRegularReps()) {
            if(this.isIsometric()) {
                if(this.hasRangedReps()) {
                    return { isoRange: null, countup: { field: 'reps' } };
                } else {
                    return { countdown: { seconds: this.minReps, field: 'reps' } };
                }
            } else {
                return { reps: null };
            }
        } else if(this.isTimed()) {
            if(this.isIsometric()) {
                return { countdown: { seconds: this.time, field: null } };
            } else {
                const fields = { countdown: { seconds: this.time, field: null } };
                if(!this.autoAdvances()) {
                    fields.reps = null;
                }
                return fields;
            }

        } else if(this.isFixedReps()) {
            if(this.isIsometric()) {
                return { countdown: { seconds: this.minReps, field: 'unbroken' } };
            } else {
                return { repsGoal: null, unbroken: null };
            }
        } else if(this.isTimeWork()) {
            const fields = { countdown: {seconds: this.time, field: null } }
            if(!this.autoAdvances()) {
                fields[this.distAttr()] = null;
            }
            return fields;
        } else if(this.isDistanceWork()) {
            return { [`${this.distAttr()}Goal`]: null, countup: { field: 'time'} };
        } else if(this.isTimeWarmup()){
            return { countdown: { seconds: this.time, field: null, title: t("Do"), subtitle: this.isUnilateral() ? t('per side') : null }};
        } else if(this.isDistanceWarmup()) {
            return { [`${this.distAttr()}Goal`]: null };
        }
    }

    goal(t) {
        if(this.isVideoOnly()) {
            return t('Follow along with the video')
        } else if(this.isOpenEndedActivity()) {
            return t('None');
        } else if(this.isTimedActivity()) {
            return t('Do time',{ time: _.stopwatchFormat(this.time)} );
        } else if(this.isTimeBased()) {
            return t("Do time",{ time: _.stopwatchFormat(this.time)} );
        } else if(this.isDistanceBased()) {
            return t("Do dist", { dist: this.distStr(t) });
        } else if(this.isMaxTest()) {
            return t("New reps rep max", { reps: this.minReps })
        } else if(this.isTimed()) {
            const amrapStr = this.isIsometric() ? t('AMSAP') : t('AMRAP');
            return `${_.stopwatchFormat(this.time)} ${amrapStr}`;
        } else if(this.isFixedReps()) {
            return this.singleRepGoalStr(t,true);
        } else if(this.isAmrap()) {
            if(_.isBlank(this.minReps)) {
                return this.isIsometric() ? t('AMSAP') : t('AMRAP');
            } else {
                return this.singleRepGoalStr(t,true);
            }
        } else {
            return this.rangeRepGoalStr(t,true);
        }
    }

    abbrGoal(t) {
        if(this.isVideoOnly()) {
            return '';
        } else if(this.isTimeBased() || this.isTimed() || this.isTimedActivity()) {
            return _.stopwatchFormat(this.time);
        } else if(this.isDistanceBased()) {
            return this.distStr(t);
        } else if(this.isMaxTest()) {
            return t("New reps rep max", { reps: this.minReps })
        } else if(this.isFixedReps()) {
            return this.singleRepGoalStr(t);
        } else {
            return this.rangeRepGoalStr(t);
        }
    }

    isMetric() {
        return this.exerciseSpecification.isMetric();
    }

    unitWeight() {
        return this.isMetric() ? this.kgWeight() : this.weight;
    }

    weightSuffix(t) {
        return this.exerciseSpecification.weightSuffix(t);
    }

    useMeters(isMetric) {
        if(this.isTimeBased()) {
            return false;
        }

        if(isMetric) {
            return (_.isBlank(this.distance) || this.distance < 1000);
        } else {
            return (_.isBlank(this.distance) || this.distance < 1000);
        }
    }

    hasRangedReps() {
        return this.maxReps && this.maxReps !== this.minReps;
    }

    hasBaseWeight() {
        return this.exerciseSpecification.hasBaseWeight();
    }

    hasValidTemplate() {
        return this.exerciseSpecification.hasValidTemplate() && !!this.setTemplate;
    }

    isWeighted() {
        return this.exerciseSpecification.isWeighted();
    }

    isUnilateral() {
        return this.exerciseSpecification.isUnilateral();
    }

    isAlternating() {
        return this.exerciseSpecification.isAlternating();
    }

    hasArbMeasure() {
        return this.exerciseSpecification.hasArbMeasure();
    }

    hasLoading() {
        return this.exerciseSpecification.hasLoading();
    }

    isPercentMax() {
        return this.exerciseSpecification.isPercentMax();
    }

    isRpe() {
        return this.exerciseSpecification.isRpe();
    }

    isReferential() {
        return this.exerciseSpecification.isReferential();
    }

    isSpecificWeight() {
        return this.exerciseSpecification.isSpecificWeight();
    }


    isRegularReps() {
        return this.exerciseSpecification.isRegularReps();
    }

    isWarmupReps() {
        return this.exerciseSpecification.isWarmupReps();
    }

    isAmrap() {
        return this.amrap && this.isRegularReps() && !this.isCardio();
    }

    isTimed() {
        return this.exerciseSpecification.isTimed();
    }

    isFixedReps() {
        return this.exerciseSpecification.isFixedReps();
    }

    isTimeWork() {
        return this.exerciseSpecification.isTimeWork();
    }

    isTimeWarmup() {
        return this.exerciseSpecification.isTimeWarmup();
    }

    isDistanceWork() {
        return this.exerciseSpecification.isDistanceWork();
    }

    isDistanceWarmup() {
        return this.exerciseSpecification.isDistanceWarmup();
    }

    isTimeBased() {
        return this.exerciseSpecification.isTimeBased();
    }

    isDistanceBased() {
        return this.exerciseSpecification.isDistanceBased();
    }

    isOpenEndedActivity() {
        return this.exerciseSpecification.isOpenEndedActivity();
    }

    isTimedActivity() {
        return this.exerciseSpecification.isTimedActivity();
    }

    isVideoOnly() {
        return this.exerciseSpecification.isVideoOnly();
    }

    isMaxTest() {
        return this.exerciseSpecification.isMaxTest();
    }

    isWarmupSetType() {
        return this.exerciseSpecification.isWarmupSetType();
    }   

    isCardio() {
        return this.exerciseSpecification.isCardio();
    }

    isIsometric() {
        return this.exerciseSpecification.isIsometric();
    }

    isGeneralActivity() {
        return this.exerciseSpecification.isGeneralActivity();
    }

    canHaveMultipleSets() {
        return !this.isMaxTest() && !this.isVideoOnly() && !this.isGeneralActivity();
    }

    repMaxReps() {
        return this.exerciseSpecification.repMaxReps();
    }

    repsForRpe() {
        if(this.maxReps) {
            return Math.round((this.minReps + this.maxReps)/2)
        } else {
            return this.minReps;
        }
    }

    defaultReps() {
        const firstTry = this.reps || this.repsForRpe();
        if(firstTry) {
            return firstTry;
        } else {
            if(this.isIsometric() && this.isTimed()) {
                return this.time || 30;
            } else {
                return 10;
            }
        }
    }

    repMaxPercent() {
        if(this.hasValidTemplate()) {
            return this.setTemplate.repMaxPercent;
        } else if(!_.isBlank(this.minReps)) {
            return StrengthStandards.workWeight(100,this.repMaxReps(),this.repsForRpe()).to_i
        } else {
            return 100;
        }
    }

    repsDefined() {
        return (this.exerciseSpecification.repsDefined() && !_.isBlank(this.minReps))
    }

    targetRpe() {
        return this.hasValidTemplate() ? this.setTemplate.targetRpe : 'moderate';
    }

    linkedSets(excludeSelf=true) {
        return _.filter(this.exerciseSpecification.workSets(),otherSet => (this.shouldCopyLoading(otherSet) && (otherSet.id !== this.id || !excludeSelf)));
    }

    shouldCopyLoading(otherSet) {
        return (otherSet.unlogged() && this.hasLoading() && this.loadingKey() === otherSet.loadingKey());
    }

    needsInitialization() {
        return this.exerciseSpecification.needsInitialization();
    }

    hasInitDoneScreen() {
        return this.exerciseSpecification.hasInitDoneScreen();
    }

    supersetArr() {
        return this.exerciseSpecification.supersetArr(true);
    }

    isLastOfSuperset() {
        const arr = this.supersetArr().exerciseSets();
        return (arr.indexOf(this) === arr.length-1)
    }

    lastWarmupBeforeNextExercise() {
        if(this.warmup) {
            const next = this.nextSet();
            return next && next.exerciseSpecificationId !== this.exerciseSpecificationId;
        } else {
            return false;
        }
    }

    needsRestScreen() {
        return (this.restTime && this.restTime > 0 && !this.isLastOfSuperset() && !this.lastWarmupBeforeNextExercise())
    }

    pathSuffix(checkRest=false) {
        if(checkRest && this.needsRestScreen()) {
            return 'rest';
        } else if(this.supersetArr().needsPreview()) {
            return 'superset_preview';
        } else if(this.needsInitialization()) {
            return 'initial';
        } else {
            return '';
        }
    }

    loadingKey() {
        let key = '';
        if(this.warmup) {
            key = `warmup-${Math.random()}`;
        } else if(this.exerciseSpecification.hasValidTemplate()) {
            if(this.hasArbMeasure()) {
                if(this.setTemplate) {
                    key = `${this.setTemplate.arbitraryMeasure}-measure`;
                }
            } else if(this.isPercentMax()) {
                key = `${this.repMaxPercent()}-pct`;
            } else if(this.isRpe()) {
                if(this.repsDefined()) {
                    key = `${this.targetRpe()}-${this.repsForRpe()}-rpe`;
                } else {
                    key = `${this.targetRpe()}-rpe`;
                }
            } else if(this.isReferential()) {
                key = `${this.setTemplateId}`;
            } else if(!!this.setTemplate && !_.isBlank(this.setTemplate.weight)) {
                key = `${this.setTemplate.weight}-lbs`;
            }
        }

        return _.isBlank(key) ? `${this.exerciseSpecification.id}` : `${this.exerciseSpecification.id}-${key}`;

    }

    pickWeightPrompt(t) {
        let difficulty = 'challenging';
        if(this.isRpe()) {
            if(this.targetRpe() === 'light') {
                difficulty = 'easy';
            } else if(this.targetRpe() === 'moderate') {
                difficulty = 'challenging';
            } else {
                difficulty = 'very challenging';
            }
        }
        difficulty = t(difficulty);

        if(this.isTimeBased() || this.isTimed()) {
            return (
                <Trans i18nKey={'Pick a weight timed'}>
                    <b>{{ difficulty }}</b> {{ time: _.stopwatchFormat(this.time) }}
                </Trans> 
            )
        } else if(this.isDistanceBased()) {
            return (
                <Trans i18nKey={'Pick a weight distance'}>
                    <b>{{ difficulty }}</b> {{ distance: this.distStr(t) }}
                </Trans> 
            )
        } else if(this.isIsometric()) {
            return (
                <Trans i18nKey={'Pick a weight isometric'}>
                    <b>{{ difficulty }}</b> {{ time: _.stopwatchFormat(this.repsForRpe()) }}
                </Trans> 
            )
        } else {
            return (
                <Trans i18nKey={'Pick a weight reps'}>
                    <b>{{ difficulty }}</b> {{ reps: this.repsForRpe() }}
                </Trans> 
            )
        }

    }
}

registerInflection('exerciseSet','exerciseSets',ExerciseSet);