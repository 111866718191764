import React from 'react';
import Page from'components/Page';
import * as transitions from 'assets/transitions';
import { workoutLogMatch, workoutLogPathFor, exerciseInfoModalPathFor, workoutPreviewPathFor, 
        workoutPreviewMatch, progressionShowPathFor, progressionPickMatch, progressionShowMatch, swapExerciseSpecPathFor, swapExerciseSpecMatch, editWorkoutTemplatePathFor, editWorkoutTemplateMatch, workoutDoMatch, emailPaywallMatches, clientDetailsMatch, 
        workoutOnboardMatch} from 'config/paths';
import { Link } from 'react-router-dom';
import { Redirect } from "components/Routing";
import withWorkout from 'partials/RoutineStateHOC';
import { SimpleNav, SimpleNavTitle, SimpleNavContainer } from 'components/Nav';
import { ExerciseInfoModal } from 'partials/ExerciseModals';
import moment from 'moment';
import { ExerciseSpecListing } from 'partials/ExerciseListing';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation, withTranslation } from 'react-i18next';
import Card from 'components/Card';
import { Dropdown } from 'components/Dropdown';
import LinkButton from 'components/LinkButton';
import Button from 'components/Button';
import ProRequiredButton from 'partials/ProRequiredButton';
import { SignupModal } from 'partials/SignupModal';
import { resolvedHomePath } from 'redux/helpers';
import { workoutPlanSlideOutPaths } from 'partials/MainLayout';
import ClientSwitcher from 'partials/ClientSwitcher';

const transitionMap = {
    rules: [
        [[...workoutPlanSlideOutPaths,clientDetailsMatch],transitions.slideOut],
        [[workoutLogMatch,progressionPickMatch,progressionShowMatch,swapExerciseSpecMatch,editWorkoutTemplateMatch,workoutDoMatch,workoutOnboardMatch, ...emailPaywallMatches], transitions.slideOver]
    ]
};

const WarmupCard = ({ title, subtitle }) => {

    return (
        <Card className="swap warmup-card">
            <div className="woo-exercise-list swap">
                <div className="title-container pt15 pb15 pl10">
                    <div className="exercise-title abbr-text">{title}</div>
                    <div className="exercise-subtitle">{subtitle}</div>
                </div>
            </div>
        </Card>
    )
}

export const actionRender = (t,basePath) => ({ spec }) => {
        
    const infoPath = spec.isProgression() ? progressionShowPathFor(spec.exercisable().id) : exerciseInfoModalPathFor(basePath,spec.exercise.id);

    return (
        <React.Fragment>
        <Dropdown 
            contentComp='ul'
            options={{constrainWidth: false, alignment: 'right', coverTrigger: false}}
            triggerRender={({ ref, target }) => {
                return (
                    <div className="action-container no-border extra-padding" id={`spec-preview-actions-${spec.id}`} ref={ref} data-target={target} >
                        <FontAwesomeIcon icon="ellipsis-h" />
                    </div>
                )
            }}
            contentRender={({ recalcDims }) => {
                return (
                    <React.Fragment>
                        <li>
                            <Link to={infoPath} id={`spec-info-btn-${spec.id}`}>
                                <FontAwesomeIcon icon="info-circle" /> {spec.isProgression() ? t("Progression Info") : t("Exercise Info")}
                            </Link>
                        </li>
                        {!spec.anyLogged() && spec.user().allowedToEditOwnRoutine() && !spec.isVideoOnly() && (<li>
                            <ProRequiredButton 
                                user={spec.user()} 
                                proPath={swapExerciseSpecPathFor(spec.workout.date,spec.id)}  
                                blockTypes={['hard','soft']}
                                context="swap_exercise"
                                render={({ onClick }) => (
                                    <Link to="" id={`spec-swap-btn-${spec.id}`} onClick={onClick}>
                                        <FontAwesomeIcon icon="exchange-alt" /> {t("Swap Out")}
                                    </Link>
                                )} 
                            />
                        </li>)}
                    </React.Fragment>
                )
            }}
        />
        </React.Fragment>
    )
}

const WorkoutPreviewSuccess = ({ workout, t }) => {

    if(workout) {
        return (
            <div className="row">
                <div className="col s12 m12 l6 offset-l3">
                    <div className="m-center-column" id={`workout-preview-${workout.id}`}>
                        <div className="wod-title">
                            {workout.displayName(t)}
                            <div className="wod-subtitle">
                                <FontAwesomeIcon icon={['far',"clock"]} /> {workout.estTimeStr(t)}
                            </div>
                        </div>
                        <div className="hide-on-med-and-down text-center mb15">
                                <LinkButton rounded color="primary" to={workout.startPath()}>
                                    <FontAwesomeIcon icon="play"></FontAwesomeIcon> {t("Do It")}
                                </LinkButton>
                                <LinkButton rounded color="grey" className="ml10" outlined noShadow to={workoutLogPathFor(workout.date)}>
                                    <FontAwesomeIcon icon={['far','clipboard-check']}></FontAwesomeIcon> {t("Log It")}
                                </LinkButton>
                                {workout.workoutTemplate && workout.user.allowedToEditOwnRoutine() && (
                                    <ProRequiredButton  
                                        user={workout.user}
                                        proPath={editWorkoutTemplatePathFor(workout.workoutRoutineId(),workout.workoutTemplateId)}
                                        blockTypes={['hard','soft']}
                                        context="edit_workout"
                                        render={({ onClick }) => (
                                            <Button rounded color="grey" className="ml10" outlined noShadow onClick={onClick}>
                                                <FontAwesomeIcon icon={['far','edit']}></FontAwesomeIcon> {t("Edit")}
                                            </Button>
                                        )}

                                    />
                                )}
                        </div>
                        {workout.warmup && (<WarmupCard title={`${workout.warmupTitle(t)} (${t('optional')})`} subtitle={workout.warmupDesc(t)} />)}
                        <ExerciseSpecListing
                            workout={workout}
                            actionRender={actionRender(t,workoutPreviewPathFor(workout.date))}
                        /> 
                        {workout.cooldown && (<WarmupCard title={`${workout.cooldownTitle(t)} (${t('optional')})`} subtitle={workout.cooldownDesc(t)} />)}
                    </div>
                </div>
                <div className="transparent-bottom hide-on-large-only wod-btns">
                    <LinkButton rounded color="grey" outlined noShadow to={workoutLogPathFor(workout.date)}>
                        <FontAwesomeIcon icon={['far','clipboard-check']}></FontAwesomeIcon> {t("Log It")}
                    </LinkButton>
                    <LinkButton variant="floating" size="large" color="primary" className="ml10" id="do-it-btn" to={workout.startPath()}>
                        <FontAwesomeIcon icon="play"></FontAwesomeIcon>
                    </LinkButton>
                    {workout.workoutTemplate && workout.user.allowedToEditOwnRoutine() && (
                        <ProRequiredButton  
                            user={workout.user}
                            proPath={editWorkoutTemplatePathFor(workout.workoutRoutineId(),workout.workoutTemplateId)}
                            blockTypes={['hard','soft']}
                            context="edit_workout"
                            render={({ onClick }) => (
                                <Button rounded color="grey" className="ml10" outlined noShadow onClick={onClick}>
                                    <FontAwesomeIcon icon={['far','edit']}></FontAwesomeIcon> {t("Edit")}
                                </Button>
                            )}

                        />
                    )}
                </div>
                <SignupModal basePath={workoutPreviewMatch} />
            </div>
        )
    } else {
        return <Redirect to={resolvedHomePath()} />
    }
 
}

export const WorkoutPreviewShortcut = (props) => {
    const { t } = useTranslation();
    const { match: { params: { focusDate }} } = props;

    return (
        <ClientSwitcher {...props} transitionMap={transitionMap} title={t('Workout Overview')} afterPath={workoutPreviewPathFor(focusDate)} />
    )
}

const WithWorkout = withWorkout('dirty','page',false)(WorkoutPreviewSuccess)

class WorkoutPreviewPage extends React.Component {

    constructor(props) {
        super(props);
        props.setupTransitions(transitionMap);
    }

    render() {
        const { match: { params: { date }}, t, scrollRef } = this.props;

        return (
            <Page ref={scrollRef}>
                <SimpleNav primary shadow>
                    <SimpleNavTitle>{t("Workout Overview")}</SimpleNavTitle>
                </SimpleNav>
                <SimpleNavContainer className="pb70">
                    <WithWorkout date={moment(date)} t={t} />
                </SimpleNavContainer>
                <ExerciseInfoModal basePath={workoutPreviewPathFor(date)} baseMatch={workoutPreviewMatch} />
            </Page>
        )
    }
}

export default withTranslation()(WorkoutPreviewPage);
