export const MEAL_PLAN_START1 = "meal_plan_start_tip1"

export const VIEW_TOGGLE = "view_toggle_tip"
export const VIEW_TOGGLE_OA = [MEAL_PLAN_START1]

export const WEEKLY_MEAL_DAYS = "weekly_meal_days_tip"
export const WEEKLY_MEAL_DAYS_OA = [MEAL_PLAN_START1,VIEW_TOGGLE]

export const MACRO_ERROR = "macro_error_tip"
export const MACRO_ERR_ONLY_AFTER = [MEAL_PLAN_START1,VIEW_TOGGLE,WEEKLY_MEAL_DAYS]

export const EDIT_CLIENT_ROUTINE = 'edit_client_routine_tip'

export const CLIENT_EMAIL_TIP = 'client_email_tip'

export const TCREATE_ROUTINE_TIP = 'tcreate_routine_tip'

export const EDIT_CLIENT_MEAL_PLAN = 'edit_client_meal_plan_tip'

export const INITIAL_HABITS_TIP = 'initial_habits_tip'

export const INDETERMINATE_TIP = 'indeterminate_habits_tip'

export const GENERATE_FIRST_MP_TIP = 'generate_first_mp_tip'

export const SIGN_IN_AS_CLIENT_TIP = 'sign_in_as_client_tip'

export const SETUP_BRANDING_TIP = 'setup_trainer_branding'
export const APPLE_HEALTH_SYNC_TIP = 'apple_health_sync_tip2'
export const SETUP_NOTIFICATIONS_TIP = 'setup_notifications_tip'
export const SMART_REMINDERS_TIP = 'smart_reminders_tip'

export const MESSAGING_LIVE = 'messaging_live'

export const NOTIFICATION_SETTINGS = 'notification_settings'

export const DNP_EDIT_TIP = "DNP_EDIT_TIP"
export const DNT_EDIT_TIP = "DNT_EDIT_TIP"

export const UPGRADE_POPUP = "UPGRADE_POPUP"

export const FORM_DEFAULTS_TIP = "FORM_DEFAULTS_TIP";

export const STRTST_AUTO_WEIGHT_TIP = "STRTST_AUTO_WEIGHT_TIP";
export const STRTSTS_NOT_NEEDED = "STRTSTS_NOT_NEEDED";

export const SETUP_FORMS_TIP = "SETUP_FORMS_TIP";

export const WORKOUTS_ONBOARD_TIP = "WORKOUTS_ONBOARD_TIP";
export const AMRAP_TIP = "AMRAP_TIP";