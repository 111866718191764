import React from 'react';
import MSidenav from 'assets/js/sidenav';
import { withRouter, Route } from 'react-router-dom';
import { isExternal } from 'components/Routing';
import { TransitionGroup, Transition } from 'react-transition-group';

export class Sidenav extends React.PureComponent {

    constructor(props) {
        super(props);
        this.sidenavRef = React.createRef();
        this.handleManualClose = this.handleManualClose.bind(this);
        this.close = this.close.bind(this);
        this.popped = false;
    }

    componentDidMount() {
        this.sidenav = MSidenav.init(this.sidenavRef.current, { edge: 'right', onCloseStart: this.handleManualClose, inDuration: (process.env.REACT_APP_TEST === 'true' ? 0 : 250), outDuration: (process.env.REACT_APP_TEST === 'true' ? 0 : 250) });
        this.sidenav.open();
    }

    render() {
        const { render } = this.props;

        return (
            <ul className="sidenav" ref={this.sidenavRef}>
                {render({ close: this.close, closeAndGo: this.closeAndGo })}
            </ul>
        )
    }

    componentDidUpdate() {
        if (!this.popped && this.props.status === 'exiting') {
            this.popped = true;
            this.sidenav.close();
        }
    }

    componentWillUnmount() {
        this.sidenav.close();
        this.sidenav.destroy();
        if (this.afterClosePath) {
            if(isExternal(this.afterClosePath)) {
                if(window.isCordova && !this.openInApp) {
                    window.cordova.InAppBrowser.open(this.afterClosePath, '_system', '')
                } else {
                    window.location.href = this.afterClosePath;
                }
            } else {
                this.props.history.push(this.afterClosePath);
            }
        }
    }

    handleManualClose() {
        if(!this.popped) {
            this.popped = true;
            this.props.history.goBack();
        }
    }

    close() {
        this.sidenav.close();
    }

    closeAndGo = (path,openInApp) => {
        this.afterClosePath = path;
        this.openInApp = openInApp;
        this.sidenav.close();
    }

}

let RouteSidenav = (props) => {
    
    const { path, exact, location, match, history, ...otherProps } = props;
    const { options: { inDuration } = {} } = props;

    return (
        <TransitionGroup>
            <Transition key={location.pathname} timeout={inDuration || 250}>
                { state => (
                    <Route location={location} path={path} exact={exact} render={(routeProps) => (
                        <Sidenav {...routeProps} {...otherProps} status={state} />
                    )} />
                )}
            </Transition>
        </TransitionGroup>
    )

}

RouteSidenav = withRouter(RouteSidenav);

export default RouteSidenav;