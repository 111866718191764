import React, {Component} from 'react';
import { withTranslation, Trans } from 'react-i18next';
import FlowForm, { FlowPageContent, FlowQuestionContainer, AutoAdvanceButton, FlowSubtext, FullWidthNextButton, FixedSizeQuestion } from 'components/FlowForm';
import { FlowAnswerButton } from 'components/Button';
import { allMainMatches, workoutOnboardBaseFor, workoutPlanPath, workoutPreviewMatch, workoutPreviewPathFor } from 'config/paths';
import { formikHandlerCreator } from 'lib/utilities';
import { dismissTooltip } from 'redux/actions';
import { userRecordSelector } from 'redux/selectors'
import { connect } from 'react-redux';
import Page from 'components/Page';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import setsAndRepsImg from 'assets/img/sets_reps_infographic.webp';
import mpsF from 'assets/img/mps_f.webp';
import mpsM from 'assets/img/mps_m.webp';
import mpsGainF from 'assets/img/mps_gain_f.webp';
import mpsGainM from 'assets/img/mps_gain_m.webp';
import { MpsChart } from 'partials/EstimatedProgressChart';
import { Illustration } from 'partials/OnboardingComponents';
import SequentialFadeIn from 'components/SequentialFadeIn';
import { FadeIn } from 'components/FadeIn';
import * as transitions from 'assets/transitions';
import workoutScience from 'assets/img/workout_science.webp';
import repetition from 'assets/img/repetition.webp';
import { WORKOUTS_ONBOARD_TIP } from 'config/tooltips';

const InitialScreen = ({ user, flowProps, t }) => {
    const text = user.weightGoal === 1 ? t('briefintrogain') : t('briefintroloss');

    return (
        <React.Fragment>
            <FixedSizeQuestion className="mt0 pt0" text={t('briefintro', { text })} />
            <FlowSubtext className="mb20">
                {t("briefintrosub")}
            </FlowSubtext>
            <Illustration src={workoutScience} width={480} height={480} />
            <FullWidthNextButton 
                flowProps={flowProps}
                label={t('Continue')}
            />
        </React.Fragment>
    )
}

const SetsAndRepsQ = ({ flowProps, t }) => {

    return (
        <React.Fragment>
            <FixedSizeQuestion text={t("setsandrepsq")} />
            {["Yes","No"].map((answer,i) => {
                
                return (
                    <AutoAdvanceButton key={i} {...flowProps} attr="knowsSetsAndReps" value={answer} render={({ onClick }) => (
                        <FlowAnswerButton color="primary" rounded onClick={onClick} >
                            <span>{t(answer)}</span>
                        </FlowAnswerButton>
                    )} />
                )
            })}
        </React.Fragment>
    )
}

const SetsAndRepsExp = ({ user, flowProps, t }) => {
    const cta = user.weightGoal === 1 ? t('briefintrogain') : t('briefintroloss');

    return (
        <React.Fragment>
            <FixedSizeQuestion className="mt0 pt0" text={t('setsandrepsare')} />
            <Illustration src={setsAndRepsImg} width={480} height={480} />
            <FlowSubtext className="text-left mt20 mb10 ml10">
                {t("setsandrepssmooth")}
            </FlowSubtext>
            <FadeIn delay={3500}>
                <FullWidthNextButton 
                    flowProps={flowProps}
                    label={t(cta)}
                    icon={'arrow-right'}
                />
            </FadeIn>
        </React.Fragment>
    )
}

const WorkoutsWhyScreen = ({ user, flowProps, t }) => {
    const descs = user.weightGoal === 1 ? ['workoutwhy4','workoutwhy5'] : ['workoutwhy2','workoutwhy3'];
    const images = { mpsF, mpsM, mpsGainF, mpsGainM };
    const img = `mps${user.weightGoal === 1 ? 'Gain' : ''}${user.female() ? 'F' : 'M'}`;

    return (
        <React.Fragment>
            <FixedSizeQuestion className="mt0 pt0" text={t('workoutsonbkey')} />
            <Illustration src={images[img]} width={480} height={507} />
            <div className="text-left">
                <SequentialFadeIn delay={1000} initialDelay={500}>
                    <FlowSubtext className="mb10"><Trans i18nKey={'workoutwhy1'}><b></b></Trans></FlowSubtext>
                    {descs.map(desc => (<FlowSubtext className="mb10"><Trans i18nKey={desc}><b></b></Trans></FlowSubtext>))}
                </SequentialFadeIn>
            </div>

            <FadeIn delay={4000}>
                <FullWidthNextButton 
                    flowProps={flowProps}
                    label={t('Continue')}
                />
            </FadeIn>
        </React.Fragment>
    )
}

const MpsIllustration = ({ t }) => {

    return (
        <Illustration>
            <MpsChart />
            <div className="title">{t("mpsovertime")}</div>
            <div className="bot-axis-lbl ll">{t('Day X', { day: 1 })}</div>
            <div className="bot-axis-lbl rl">{t('Day X', { day: 7 })}</div>
            <div className="rdiets-label">
                {t("fatstorethresh")}
            </div>
            <div className="fat-store-thresh"></div>
        </Illustration>
    )
}

const AllItTakesScreen = ({ user, flowProps, t }) => {
    let type = t('15minworkout');
    let setNum = '1-2';
    let workoutLen = '15';
    if(user.liftingExperience > 0) {
        type = t('30minworkout');
        setNum = '2-4';
        workoutLen = '30-45';
    }

    return (
        <React.Fragment>
            <FixedSizeQuestion className="mt0 pt0" text={t('shortworkoutsfewdays', { type })} />
            <MpsIllustration t={t} />
            <div className="text-left">
                <SequentialFadeIn delay={1000} initialDelay={500}>
                    <FlowSubtext className="mb10"><Trans i18nKey={'mpslasts'}><b></b></Trans></FlowSubtext>
                    <FlowSubtext className="mb10">{t('allmpstakes', { num: setNum })}</FlowSubtext>
                    <FlowSubtext className="mb10">{t('mpsgetitdone', { mins: workoutLen })}</FlowSubtext>
                </SequentialFadeIn>
            </div>

            <FadeIn delay={4000}>
                <FullWidthNextButton 
                    flowProps={flowProps}
                    label={t('Continue')}
                />
            </FadeIn>
        </React.Fragment>
    )
}


const RepAndProgressScreen = ({ user, flowProps, t }) => {
    const descs = ["progimpoworep","repgreaterthanvar"];

    return (
        <React.Fragment>
            <FixedSizeQuestion className="mt0 pt0" text={t('repandprogress')} />
            <Illustration src={repetition} width={480} height={320} />
            <div className="text-left">
                <SequentialFadeIn delay={1000} initialDelay={500}>
                    <FlowSubtext className="mb10"><Trans i18nKey={"progwtrepsmps"}><b></b></Trans></FlowSubtext>
                    {descs.map(desc => (<FlowSubtext className="mb10"><Trans i18nKey={desc}><b></b></Trans></FlowSubtext>))}
                </SequentialFadeIn>
            </div>

            <FadeIn delay={4000}>
                <FullWidthNextButton 
                    flowProps={flowProps}
                    label={t('Continue')}
                />
            </FadeIn>
        </React.Fragment>
    )
}

const summaryDonts = [
    { icon: 'times', desc: 'dontneedmps1' },
    { icon: 'times', desc: 'dontneedmps2' },
    { icon: 'times', desc: 'dontneedmps3' },
    { icon: 'times', desc: 'dontneedmps4' }
]

const summaryDos = [
    { icon: ['far','clock'], desc: 'thatswhymps1' },
    { icon: 'redo', desc: 'thatswhymps2' },
    { icon: ['fal','chart-line'], desc: 'thatswhymps3' }
]

const FinalSummaryScreen = ({ user, flowProps, t, dismissTooltip, history, date }) => {

    return (
        <React.Fragment>
            <SequentialFadeIn delay={1500}>
                <div>
                    <FixedSizeQuestion className="mb15 mt25 pt0 text-left" text={t('dontneedmps')} />
                    <div>
                        <div className="onb-ol">
                            {summaryDonts.map(({ desc, icon },i) => {

                                if(user.needsCardio && i === summaryDonts.length-1) {
                                    return '';
                                }

                                return (
                                    <div className="onb-ol-li pt5 pb5 cntr" key={i}>
                                        <div className="icon bad">
                                            <FontAwesomeIcon icon={icon} />
                                        </div>
                                        <div className="title-cont">
                                            <label>{t(desc)}</label>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div>
                    <FixedSizeQuestion className="mb15 mt25 pt0 text-left" text={t('thatswhymps')} />
                    <div>
                        <div className="onb-ol">
                            {summaryDos.map(({ desc, icon },i) => {

                                return (
                                    <div className="onb-ol-li pt5 pb5 cntr" key={i}>
                                        <div className="icon">
                                            <FontAwesomeIcon icon={icon} />
                                        </div>
                                        <div className="title-cont">
                                            <label>{t(desc)}</label>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </SequentialFadeIn>

            <FadeIn delay={3000}>
                <FullWidthNextButton 
                    flowProps={{ ...flowProps, advance: values =>  {
                        dismissTooltip(WORKOUTS_ONBOARD_TIP);
                        history.replace(workoutPreviewPathFor(date))
                    }}}
                    label={t('Got It')}
                />
            </FadeIn>
        </React.Fragment>
    )
}

const getNextPage = (page,values) => {
    switch(pageToComp(page)) {
        case SetsAndRepsQ: {
            if(values.knowsSetsAndReps === 'Yes') {
                return page+2;
            }
            return page+1;
        }
        default: {
            return page+1;
        }
    }
}

const transitionMap = {
    rules: [
        [[...allMainMatches,workoutPreviewMatch],transitions.slideOut]
    ]
};

const fullFlow = [
    InitialScreen,
    SetsAndRepsQ,
    SetsAndRepsExp,
    WorkoutsWhyScreen,
    AllItTakesScreen,
    RepAndProgressScreen,
    FinalSummaryScreen
]

const pageToComp = (page) => {
    return fullFlow[page-1];
};

class WorkoutsOnboardingPage extends Component {

    constructor(props) {
        super(props);
        this.state = { loadState: 'DEFAULT', errorMsg: null };
        this.setSubmitState = this.setSubmitState.bind(this);
        props.setupTransitions(transitionMap);
        console.log('here')
    }

    setSubmitState(status,formikBag,data) {
        this.setState({ loadState: status, errorMsg: null });
    }

    render() {
        let { 
            t, 
            user, 
            scrollRef, 
            dismissTooltip,
            match: { params: { date } },
            history
        } = this.props;

        return (
            <Page white ref={scrollRef} className="fixed-next-flow">
                <FlowForm 
                    doReplace={true}
                    initialValues={{ knowsSetsAndReps: 'Yes' }}
                    validate={() => ({})}
                    validateOnMount={false}
                    basePath={workoutOnboardBaseFor(date)} 
                    nextPage={getNextPage}
                    highestValidPageFn={() => 1}
                    exitPath={workoutPlanPath}
                    startIndex={1}
                    advanceCallback={data => {}}
                    onSubmit={formikHandlerCreator(this,this.setSubmitState,() => Promise.resolve({ status: "SUCCESS"}))}
                >
                    {fullFlow.map((Comp,pg) => {
                        return (
                            <FlowPageContent key={pg} render={(flowProps) => {
                                return (
                                    <FlowQuestionContainer>
                                        <Comp flowProps={flowProps} t={t} user={user} dismissTooltip={dismissTooltip} history={history} date={date} />
                                    </FlowQuestionContainer>
                                )
                            }} />
                        )
                    })}
                </FlowForm>
            </Page>
        );
    }

    componentWillUnmount() {
        if(this.loadPromise){
            this.loadPromise.cancel();
        }
    }
}

//be careful reading state here because newEmailUser wipes all data and reloads user show view
//which caused multiple bugs with bannable progs not being loaded when expected
const mapStateToProps = state => ({
    user: userRecordSelector(state)
})

const mapDispatchToProps = dispatch => ({
    dismissTooltip: tip => dispatch(dismissTooltip(tip))
})

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(WorkoutsOnboardingPage));
