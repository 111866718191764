import * as _ from 'lib/utilities';
import { RecordBase, registerInflection } from 'lib/record-base';
import moment from 'moment';
import { MealType } from './classes';
import { mealPlanPath, mealPlanPathFor, workoutPlanPath, standaloneMealPlanPathFor, pdfSetupDonePath, workoutPreviewPathFor, workoutOnboardStartPath } from 'config/paths';
import { conversionPathFor } from 'redux/helpers';
import { calRoundFactor, dateFormat, logoUrl } from 'config/settings';
import { trainer } from 'lib/trainer';
import { trainerClient } from './trainer-client';
import { userLayout } from './user-layout';
import logoPlaceholder from 'assets/img/logo-placeholder.png';
import { chatConcern } from './chat-concern';
import { dnpConcern } from './dnp-concern';
import { MealSearchCategory } from './meal-search';
import { WORKOUTS_ONBOARD_TIP } from 'config/tooltips';

export class BasicProfile extends RecordBase {

    metricHeight() {
        return _.isBlank(this.height) ? null : _.roundToF(this.height*2.54,0.01);
    }

    metricWeight() {
        const curWeight = typeof this.currentWeight === 'function' ? this.currentWeight() : this.currentWeight;
        return _.isBlank(curWeight) ? null : _.roundToF(curWeight/2.2,0.01);
    }

    inchHeight() {
        return _.isBlank(this.height) ? null : this.height % 12;
    }

    footHeight() {
        return _.isBlank(this.height) ? null : Math.floor(this.height/12);
    }
}

export class ClientTag extends RecordBase {
    static NAME = 'ClientTag'

    static ASSOCS = {
        trainer: { type: 'belongsTo', tableName: 'users' },
        client: { type: 'belongsTo', tableName: 'users' }
    }
}

registerInflection('clientTag','clientTags',ClientTag);

export class User extends BasicProfile {
    static NAME = 'User'

    static DIET_TAGS = ['standard','pescetarian','vegetarian','paleo','keto','vegan'];
    static DIET_TAGS_FOR_FORM = this.DIET_TAGS.map(tag => tag === 'standard' ? ['No restriction',tag] : [_.capitalize(tag),tag]);
    static DIET_TYPES_FOR_FORM = this.DIET_TAGS.map((tag,index) => tag === 'standard' ? ['No restriction',index] : [_.capitalize(tag),index]);
    static MEAL_TYPES = [ ['breakfast','Breakfast'], ['lunch','Lunch'], ['dinner', 'Dinner'], ['snack','Snacks'] ] ;
    static METRIC = 1
    static IMPERIAL = 0
    static INACTIVE = 2
    static DISCOUNTED_AFFILIATES = {'alternativebalance30': 'Alternative Balance'}
    static TRAINER_TYPES = {
        app: 0,
        pdf: 1
    }
    static MEAL_PLANNER_PREFS = {
        default: 0,
        none: 1,
        clientSelect: 2
    }
    static WORKOUT_PLANNER_PREFS = {
        default: 0,
        none: 1,
        clientSelect: 2
    }
    
    static ASSOCS = {
        workouts: { type: 'hasMany' },
        exerciseSettings: { type: 'hasMany' },
        assignedRoutine: { type: 'belongsTo', tableName: 'workoutRoutines' },
        customExercises: { type: 'hasMany', tableName: 'exercises', inverse: 'owner' },
        mealTypes: { type: 'hasMany' },
        dailyNutritionProfiles: { type: 'hasMany' },
        notifications: { type: 'hasMany' },
        miniProfiles: { type: 'hasMany' },
        likedRecipes: { type: 'hasMany', hasAssocKey: 'resolvedLikedRecipeIds', tableName: 'recipes', sortAttr: 'name' },
        recipes: { type: 'hasMany', hasAssocKey: 'recipeIds', tableName: 'recipes', sortAttr: 'name' }, 
        teamRecipes: { type: 'hasMany', hasAssocKey: 'teamRecipeIds', tableName: 'recipes', sortAttr: 'name' }, 
        allTrainerLikedRecipes: { type: 'hasMany', hasAssocKey: 'resolvedAllTrainerRecipeIds', tableName: 'recipes', sortAttr: 'name' },
        foods: { type: 'hasMany', inverse: 'owner' },
        trainer: { type: 'belongsTo', tableName: 'users', inverse: 'clients' },
        clients: { type: 'hasMany', tableName: 'users', inverse: 'trainer' },
        master: { type: 'belongsTo' , tableName: 'users', inverse: 'children' },
        children: { type: 'hasMany', tableName: 'users', inverse: 'master'},
        workoutRoutines: { type: 'hasMany', inverse: 'owner', fk: 'ownerId' },
        workoutTemplates: { type: 'hasMany', inverse: 'owner', fk: 'ownerId' },
        clientTags: { type: 'hasMany', inverse: 'trainer' },
        trainerTags: { type: 'hasMany', inverse: 'client', tableName: 'clientTags' },
        trainerSubscription: { type: 'hasOne', tableName: 'subscriptions', where: sub => sub.type === 'TrainerSubscription' },
        subscription: { type: 'hasOne', where: sub => _.isBlank(sub.type) },
        recipePreferences: { type: 'hasMany' },
        tTrainerNotes: { type: 'hasMany', tableName: 'trainerNotes', inverse: 'trainer' },
        cTrainerNotes: { type: 'hasMany', tableName: 'trainerNotes', inverse: 'client' },
        ownedHabits: { type: 'hasMany', tableName: 'habits', inverse: 'owner' },
        habitLogs: { type: 'hasMany' },
        activityLogs: { type: 'hasMany' },
        schedulableSettings: { type: 'hasMany' },
        pdfExportSetting: { type: 'hasOne' },
        mpInfoStubs: { type: 'hasMany' },
        chatMemberships: { type: 'hasMany' },
        chatMessages: { type: 'hasMany', inverse: 'sender' },
        ownedForms: { type: 'hasMany', inverse: 'owner', tableName: 'forms', fk: 'ownerId' },
        assignedForms: { type: 'hasMany', inverse: 'user', tableName: 'forms', fk: 'userId' },
        userDataEntries: { type: 'hasMany' },
		ownedAssessments: { type: 'hasMany', inverse: 'owner', tableName: 'assessments', fk: 'ownerId' },
        progressPhotos: { type:'hasMany' }
        //COMMENT FOR GENERATOR, DO NOT TOUCH. ADD USER ASSOCS ABOVE
    }

    static genderCol(t) {
        return [
            { text: t('Male'), value: false },
            { text: t('Female'), value: true }
        ]
    }

    static weightGoalCol(t) {
        return [
            { text: t('Lose Fat'), value: -1 },
            { text: t('Maintain/Tone'), value: 0 },
            { text: t('Build Muscle'), value: 1 }
        ]
    }

    static activityLevelCol(t,ctxt) {
        const options = {
            [t('Sedentary')]: { value: 0, subtitle: t('sedentary subtitle') }, 
            [t('Lightly active')]: { value: 1, subtitle: t('lightly active subtitle') }, 
            [t('Moderately active')]: { value: 2, subtitle: t('moderately active subtitle') },
            [t('Heavily active')]: { value: 3, subtitle: t('heavily active subtitle') }, 
            [t('Extremely active')]: { value: 4, subtitle: t('extremely active subtitle') } 
        }
        if(ctxt === 'slider') {
            return options;
        }

        return Object.entries(options).map(([text,info]) => ({ text, value: info.value }))
    }

    static NOTIFICATION_DEFAULTS = { 
        workout: { at: 5*60, pm: true }, 
        breakfast: { at: 9*60, pm: false }, 
        lunch: { at: 60, pm: true }, 
        snack: { at: 4.5*60, pm: true }, 
        dinner: { at: 7.5*60, pm: true }, 
        eod: { at: 9*60, pm: true },
        fastStart: { at: 8*60, pm: true },
        fastEnd: { at: 8*60, pm: false }
    };

    static NOTIFICATION_TIMES = Array(24).fill().map((entry,i) => i*30);

    static workoutRemindCol(t,noneOption=false) {
        const col = noneOption ? [{ text: t('No reminder'), value: '' }] : [];
        this.NOTIFICATION_TIMES.forEach(mins => {
            const m = moment().startOf('day');
            m.add(mins,'minutes');
            col.push({ text: m.format('h:mm'), value: mins })
        })
        return col;
    }

    static workoutRemindPmCol(t) {
        return [
            { text: t('AM'), value: false },
            { text: t('PM'), value: true }
        ]
    }

    static dietTypesCol(t,withBlank=false) {
        let arr = this.DIET_TYPES_FOR_FORM.map(([text,value]) => ({ text: t(text), value }));
        if(withBlank) {
            arr.unshift({ text: '', value: ''})
        }
        return arr;
    }

    static dietTypesForSearchCol(t) {
        return this.DIET_TAGS.map((tag) => ({ text: t(_.capitalize(tag)), value: tag }))
    }

    static weeklyVarietyCol(t,withLabel=false,withBlank=false) {
        let arr = [
            {text: `1${withLabel ? ` - ${t('More repetitive')}` : '' }`, value: 0 },
            {text: `2`, value: 1 },
            {text: `3`, value: 2 },
            {text: `4`, value: 3 },
            {text: `5${withLabel ? ` - ${t('More cooking')}` : '' }`, value: 4 }
        ]
        if(withBlank) {
            arr.unshift({ text: '', value: ''})
        }
        return arr;
    }

    static budgetCol(t,withLabel=false,withBlank=false) {
        let arr = [
            {text: t('$'), value: 0 },
            {text: `${t('$')}${t('$')}`, value: 1 },
            {text: `${t('$')}${t('$')}${t('$')}`, value: 2 },
            {text: `${t('$')}${t('$')}${t('$')}${t('$')}`, value: 3 }
        ]
        if(withBlank) {
            arr.unshift({ text: '', value: ''})
        }
        return arr;
    }

    static complexityPreferenceCol(t,withLabel=false,withBlank=false,withBasics=false) {
        const offset = withBasics ? 1 : 0;
        let arr = [
            {text: `${1 + offset}${withLabel ? ` - ${t('Simple')}` : '' }`, value: 1 },
            {text: `${2 + offset}`, value: 2 },
            {text: `${3 + offset}`, value: 3 },
            {text: `${4 + offset}${withLabel ? ` - ${t('Complex')}` : '' }`, value: 4 }
        ]
        if(withBasics) {
            arr.unshift({text: `1${withLabel ? ` - ${t('Basics only')}` : '' }`, value: 0 })
        }
        if(withBlank) {
            arr.unshift({ text: '', value: ''})
        }
        return arr;
    }

    static mealTypesCol(t) {
        return this.MEAL_TYPES.map(([mt,title]) => ({ text: t(title), value: mt }))
    }

    static progressPeriodsCol(t,includeWeek=false,includeTwoWeek=false) {
        let initial = includeWeek ? [{ text: t('Last X days',{ days: 7 }), value: 7 }] : [];
        if(includeTwoWeek) {
            initial.push({ text: t('Last X days',{ days: 14 }), value: 14 })
        }
        return [
            ...initial,
            { text: t('Last X days',{ days: 30 }), value: 30 },
            { text: t('Last X months',{ months: 3 }), value: 90 },
            { text: t('Last X months',{ months: 6 }), value: 180 },
            { text: t('Last X months',{ months: 12 }), value: 365 },
            { text: t('All time'), value: 99999 }
        ]
    }

    static sortCol(t,inclSelect=false,trainer=null) {
        const initial = inclSelect ? [ { text: `${t('Selected')} \u2191`, value: 'selectedAsc' } ] : [];
        const lastActiveTxt = 'Last Active';
        const isPdfTrainer = (trainer && trainer.isPdfTrainer());
        return [ 
            ...initial,
            { text: `${t('Name')} \u2191`, value: 'nameAsc' },
            { text: `${t('Name')} \u2193`, value: 'nameDesc' },
            { text: isPdfTrainer ? t('Recent') : `${t(lastActiveTxt)} \u2193`, value: 'lastActiveDesc' },
            { text: isPdfTrainer ? t('Older') : `${t(lastActiveTxt)} \u2191`, value: 'lastActiveAsc' }
        ]
    }

    static female(gender) {
        return (gender === true || gender === 'true');
    }
    
    static male(gender) {
       return (gender !== true && gender !== 'true');
    }

    static metric(unitPref) {
        return (unitPref === this.METRIC);
    }

    static weightSuffix(unitPref) {
        return this.metric(unitPref) ? 'kgs' : 'lbs';
    }

    static excludedKeywordsTransform(excluded,extra) {
        return _.compact([_.compact(excluded).join(','),_.compact(extra).join(',')]).join(',');
    }

    static filterUnitValues = (values) => {
        if(this.metric(values.unitPreference)) {
            const { footHeight, inchHeight, currentWeight, ...rest } = values;
            return rest;
        } else {
            const { metricHeight, metricWeight, ...rest } = values;
            return rest;
        }
    }

    static BASIC_PROFILE_DEFAULTS = { 
        weightGoal: '', 
        gender: '', 
        unitPreference: 0,
        age: '', 
        metricHeight: '', 
        metricWeight: '', 
        footHeight: '', 
        inchHeight: '', 
        currentWeight: '',
        activityLevel: 0
    }

    static fromDiscountedAffiliateVal = () => {
        const og = _.getCookie('original_keyword');

        if(_.isBlank(og) || _.isBlank(this.DISCOUNTED_AFFILIATES[og])) {
            return {};
        }

        return { fromDiscountedAffiliate: this.DISCOUNTED_AFFILIATES[og] };
    }

    static defaultUser = () => (new this({...this.BASIC_PROFILE_DEFAULTS, ...this.fromDiscountedAffiliateVal() }))

    createdAtMom() {
        return moment(this.createdAt);
    }

    isFullyLoaded() {
        return this.createdAt !== undefined;
    }

    inviteSent() {
        return (this.inviteStatus === 'sent');
    }

    invitePending() {
        return this.inviteSent();
    }

    inviteClaimed() {
        return this.inviteStatus === 'claimed';
    }

    inviteNeeded() {
        return !this.inviteSent() && !this.inviteClaimed();
    }

    canBecomeTrainer(allowCurrentTrainers=false) {
        return _.isBlank(this.trainerId) && _.isBlank(this.masterId) && (allowCurrentTrainers || !this.isTrainer());
    }

    hasValidInvite() {
        return this.inviteSent();
    }

    hasApp() {
        return !_.isBlank(this.fcmId)
    }

    myRecipesNeedLoading() {
        if(this.isClientOrTrainer()) {
            return _.isBlank(this.allTrainerRecipeIds);
        } else {
            if(_.isBlank(this.recipeIds)) {
                return true;
            }
            const recs = [ ...this.likedRecipes, ...this.recipes ];
            const recIds = [ ...this.likedRecipeIds, ...this.recipeIds ];
            const needLoading = _.union(_.filter(recs,rec => !rec.isCategorizeLoaded()).map(rec => rec.id),_.difference(recIds,recs.map(rec => rec.id)));
            return needLoading.length > 0;
        }
    }

    myRecipesFor(mealSearch) {
        const filt = mealSearch.isRecipeSearch() ? (rec => true) : (rec => rec.canBeMainDish());
        return {
            owned: this.visibleActiveRecipes(filt),
            liked: this.activeLikedRecipes(filt),
            trainer: this.activeTrainerLikedRecipes(filt)
        }
    }

    isTrainerLikedRecipe(recipe) {
        const id = recipe.staticId();
        return this.trainerLikedRecipeIds.includes(id) || this.clientLikedRecipeIds.includes(id);
    }

    initialSignupValues() {
        const defaults = { ...User.BASIC_PROFILE_DEFAULTS, firstName: this.firstName || '', email: this.email || '', password: '' }
        const obj = this.extract(Object.keys(defaults));
        _.defaultValues(obj,defaults);
        return _.parseObjForForm(obj);
    }

    basicProfileValues(canShowSetupOwnCheck=false) {
        const vals = this.extract( [...Object.keys(User.BASIC_PROFILE_DEFAULTS) ], true);
        _.defaultValues(vals,User.BASIC_PROFILE_DEFAULTS);
        if(canShowSetupOwnCheck && this.showSetupOwnProfileCheck()) {
            vals.setupOwnProfile = !!this.setupOwnProfile;
            vals.setupOwnMpPrefs = !!this.setupOwnMpPrefs;
            if(this.isAppClient()) {
                vals.canEditOwnMealPlan = !!this.canEditOwnMealPlan;
                vals.pickOwnRoutine = !!this.pickOwnRoutine;
            } else {
                vals.canPickInitialRecipes = !!this.canPickInitialRecipes;
                vals.autoDeliverMpPdf = !!this.autoDeliverMpPdf;
            }
        }
        return _.parseObjForForm(vals);
    }

    recommendedCalsReadable() {
        return this.recommendedCalories && _.roundToF(this.recommendedCalories,calRoundFactor);
    }

    macrosInitialized() {
        return this.mainNutritionProfile() && this.mainNutritionProfile().macrosInitialized();
    }

    recMacroParams() {
        return this.mainNutritionProfile().recMacroParams();
    }

    onbRecMacroParams() {
        return this.canEditOwnMacros() ? this.recMacroParams() : this.baseActiveMacroParams();
    }

    onbRecCalsReadable() {
        return this.canEditOwnMacros() ? this.recommendedCalsReadable() : this.baseTargetCalsReadable();
    }

    baseTargetCalories() {
        return this.mainNutritionProfile().targetCalories();
    }

    baseTargetCalsReadable() {
        return this.mainNutritionProfile().targetCalsReadable();
    }

    baseRawActiveMacroParams() {
        return this.mainNutritionProfile().rawActiveMacroParams();
    }

    baseActiveMacroParams() {
        return this.mainNutritionProfile().activeMacroParams();
    }

    activeMacroParamDaysArr() {
        return [ ...(new Array(7)) ].map((noop,wday) => this.dnpOnWday(wday).activeMacroParams())
    }

    mealPlanValues(allergyTags,includeMacros,ctxt,forTrainer=false) {
        let ret = _.pick(this,['dietType','weeklyVariety','complexityPreference','budget','selectedMealTypes','mealPlanWeekday','minSnacks']);
        if(ctxt === 'settings') {
            ret.mealTypeDailyPercentages = this.mealTypeDailyPercentages();
        }
        ret.excludedKeywords = this.excludedKeywordsForForm(allergyTags);
        ret.extraKeywords = this.extraKeywords(allergyTags);
        if(includeMacros) {
            ret.dailyNutritionProfiles = this.mnpFormValArr();
        }
        if(forTrainer) {
            ret.setupOwnMpPrefs = this.setupOwnMpPrefs;
            ret.canEditOwnMealPlan = _.isBlank(this.canEditOwnMealPlan) ? false : this.canEditOwnMealPlan;
            _.defaultValues(ret,{dietType: 0, weeklyVariety: 1, complexityPreference: 2, budget: 2 })
            if(!ret.selectedMealTypes || ret.selectedMealTypes.length === 0) {
                ret.selectedMealTypes = MealType.ON_PLAN_CATS;
                ret.mealTypeDailyPercentages = this.mealTypeDailyPercentages(ret.selectedMealTypes);
            }
        } else {
            _.defaultValues(ret,{weeklyVariety: 0, complexityPreference: 1, budget: 0 })
        }
        return _.parseObjForForm(ret);
    }

    customMacrosValues() {
        return _.parseObjForForm({ dailyNutritionProfiles: this.mnpFormValArr(), activateDnpIds: [], deactivateDnpIds: [] });
    }

    bodyMeasurementValues() {
        const vals = this.extract(['gender','unitPreference','age','metricHeight','metricWeight','footHeight','inchHeight','currentWeight'],true);
        vals.unitPreference = _.isBlank(vals.unitPreference) ? 0 : vals.unitPreference;
        return _.parseObjForForm(vals);
    }

    activeOnPlanMealTypes() {
        return _.filter(this.mealTypes,mt => mt.isActiveOnPlan())
    }

    mealTypeDailyPercentages(selectedTypes=this.selectedMealTypes,minSnacks=this.minSnacks) {
        const mts = this.activeOnPlanMealTypes();
        const total = _.reduce(selectedTypes,(sum,cat) => {
            const mt = _.find(mts,mt => mt.category === cat)
            const add = mt ? mt.getPercentDailyIntake(minSnacks) : MealType.defaultPercentFor(cat,minSnacks);
            return sum + add;
        },0);
        const mtdps = {};
        selectedTypes.forEach(cat => {
            const mt = _.find(mts,mt => mt.category === cat)
            const val = mt ? mt.getPercentDailyIntake(minSnacks) : MealType.defaultPercentFor(cat,minSnacks);
            mtdps[cat] = Math.round(val/total*100)
        })
        let surplus = 0;
        const entries = Object.entries(mtdps);
        for(let i=0; i < entries.length*2; i++) {
            if(i >= entries.length && surplus === 0) {
                break;
            }
            let [category,value] = entries[i%entries.length];
            let newVal = value + surplus;
            if(newVal < 10) {
                surplus = newVal - 10;
                newVal = 10;
            } else if(newVal > 75) {
                surplus = newVal - 75;
                newVal = 75;
            } else {
                surplus = 0;
            }
            mtdps[category] = newVal;
        }

        return mtdps;
    }

    uncheckedNotifications() {
        return _.filter(this.notifications,notification => !notification.checked)
    }

    female() {
        return User.female(this.gender);
    }

    male() {
        return User.male(this.gender);
    }

    isPro() {
        if(this.isClient()) {
            return this.isTrainerSubInit();
        }
        if(this.isTrainer() && this.hasActiveTrainerSub()) {
            return true;
        }
        if(!this.proExpires) {
            return false;
        } else {
            const expiration = moment(this.proExpires).add(3,'days');
            return expiration.isAfter(moment());
        }
    }

    hasBasicProfileFields() {
        return !_.isBlank(this.weightGoal) && !_.isBlank(this.gender) && !_.isBlank(this.age) && !_.isBlank(this.height) && !_.isBlank(this.currentWeight) && !_.isBlank(this.activityLevel)
    }

    afterMealPlanSetupPath(forWeek,clientId) {
        if(clientId) {
            return standaloneMealPlanPathFor(this.id,forWeek);
        } else if(this.isPdfClient()) {
            return pdfSetupDonePath;
        }
        const defaultPath = (forWeek || forWeek === 0) ? mealPlanPathFor(forWeek) : mealPlanPath;
        const skipEmailPrompt = (this.webMealPlannerFlow === 'weekly' || !_.isBlank(this.webMealPlanFlow)) && !forWeek;
        if(this.isClient() || skipEmailPrompt) {
            return defaultPath;
        }
        return this.afterSetupPath('meal_plan',defaultPath);
    }

    afterWorkoutPlanSetupPath() {
        if(this.isClient()) {
            return workoutPlanPath;
        }
        return this.afterSetupPath('workout_plan',workoutPlanPath);
    }

    afterSetupPath(context,defaultPath) {
        return (this.isPro() || (this.hasSofterPaywall() && !_.isBlank(this.email))) ? defaultPath : conversionPathFor(context,this);
    }

    hasProAccess(blockTypes) {
        if(this.isPro()) {
            return true;
        }

        const accessType = this.ownPaywallType();

        return !blockTypes.includes(accessType);
    }

    hasNewProAccess() {
        return this.isPro() || this.newProAccess;
    }

    ownPaywallType() {
        if(this.hasNewProAccess()) {
            return 'old';
        } else if(this.hasSofterPaywall()) {
            return 'soft';
        } else {
            return 'hard';
        }
    }

    hasSofterPaywall() {
        return this.conversionPageVersion !== 3;
    }

    allowInitialRecipePick() {
        if(this.hasProAccess(['soft']) || _.isBlank(this.lastMealPlanDay) || !this.hasBasicProfile) {
            return true;
        }

        return false;
    }

    isAdmin() {
        return this.role === 'admin';
    }

    isContributor() {
        return this.role === 'contributor';
    }

    shouldRequestRating() {
        const now = moment();
         if(window.isCordova || process.env.NODE_ENV !== 'production' || process.env.REACT_APP_TEST === 'true') {
             if(this.hasBasicProfile) {
                 const createdAtCutoff = moment(this.createdAt).add(7,'days');
                 if(now.isSameOrAfter(createdAtCutoff)) {
                     if(_.isBlank(this.nextRatingRequest) || now.isSameOrAfter(moment(this.nextRatingRequest),'day')) {
                         return true;
                     }
                 }
             }
         }

         return false;
    }

    shouldShowWelcomePopup() {
        const isTest = process.env.REACT_APP_TEST === 'true';
        if(isTest) {
            return false;
        }

        if(this.seenTooltips && this.seenTooltips.includes('strongr_fastr_v2')) {
            return false;
        }
        const cutoff = moment('2020-11-27');
        const created = moment(this.createdAt);
        if(created.isSameOrBefore(cutoff,'day')) {
            return true;
        }

        return false;
    }

    strengthStandards(t) {
        return {
            intermediate: StrengthStandards.standards(this.gender,'intermediate',this.currentWeight,this.unitPreference,t),
            advanced: StrengthStandards.standards(this.gender,'advanced',this.currentWeight,this.unitPreference,t)
        }
    }

    dietTag() {
        return User.DIET_TAGS[this.diet_type || 0]
    }

    excludedKeywordsForForm(allergyTags) {
        return _.intersection(this.excludedKeywords.split(','),allergyTags.map(([label,val]) => val));
    }

    extraKeywords(allergyTags) {
        return _.difference(this.excludedKeywords.split(','),allergyTags.map(([label,val]) => val));
    }

    filteredMealSearchCats(categories,allergyTags,mealTypeCategory) {
        return MealSearchCategory.getSuitableCats(categories,{ dietType: this.dietTag(), acceptableFoodTypes: mealTypeCategory, excludedKeywords: this.excludedKeywordsForForm(allergyTags) })
    }

    isDeloaded() {
        if(this.lastDeload) {
            let { startDate, days } = this.lastDeload;
            days = days || 7;
            const cutoff = moment(startDate).add(days,'days');
            return moment().isSameOrBefore(cutoff,'day');
        }
        return false;
    }

    deloadButtonType() {
        if(this.isDeloaded()) {
            return 'undo';
        } else {
            const cutoff = this.routineStartDate().add(14,'days');
            if(moment().isAfter(cutoff,'day')) {
                return 'do';
            }
        }
        return null;
    }

    genderString() {
        return this.male() ? 'male' : 'female';
    }

    weightGoalString() {
        if(this.weightGoal < 0) {
            return 'lose';
        } else if (this.weightGoal > 0) {
            return 'gain';
        } else {
            return 'maintain';
        }
    }

    gymTypeString() {
        if (_.isBlank(this.gymType)) {
            return 'none' 
        } else {
            return ['none','bodyweight','gym'][this.gymType]
        }
    }

    liftingExpString() {
        if (_.isBlank(this.liftingExperience)) {
            return 'none' 
        } else {
            return ['beginner','intermediate','advanced'][this.liftingExperience]
        }
    }

    mealPlanInitialized() {
        return !_.isBlank(this.mealPlanStart);
    }

    mealPlanStartedBy(date) {
        return this.mealPlanStartMom().isSameOrBefore(date,'day')
    }

    firstMealMoment() {
        const mps = this.mealPlanStartMom();
        const now = moment().startOf('day');
        if(mps.isAfter(now)) {
            return mps.clone();
        }

        return now;
    }

    mealPlanStartMom() {
        return moment(this.mealPlanStart);
    }

    lastMealPlanDayMom() {
        return moment(this.lastMealPlanDay);
    }

    switchMpFocusDate(week) {
        if(_.isNumeric(week) || week === 'third' || week === 'fourth') {
            return this.activeWeekStart(week);
        }
        return null;
    }

    activeWeekStart(week) {
        if(week === 'next') {
            return _.weekStart(this.firstMealMoment().add(7,'days'),this.mealPlanWeekday);
        } else if(week === 'third') {
            return _.weekStart(this.firstMealMoment().add(14,'days'),this.mealPlanWeekday);
        } else if(week === 'fourth') {
            return _.weekStart(this.firstMealMoment().add(21,'days'),this.mealPlanWeekday);
        } else if(_.isNumeric(week)) {
            return _.weekStart(this.mealPlanStartMom().add(7*Number(week),'days'),this.mealPlanWeekday);
        } else if(week === 'last') {
            return _.weekStart(this.firstMealMoment(),this.mealPlanWeekday).subtract(7,'days');
        } else {
            return _.weekStart(this.firstMealMoment(),this.mealPlanWeekday);
        }
    }

    allMealPlanDates(week) {
        return _.nextWeekDates(this.activeWeekStart(week));
    }

    activeWeekEnd(week) {
        return this.activeWeekStart(week).add(6,'days')
    }

    mealPlanStartDateFor(week) {
        if(week === 'next') {
            return _.weekStart(this.firstMealMoment().add(7,'days'),this.mealPlanWeekday).format(dateFormat);
        } else if(week === 'last') {
            return this.firstMealMoment().subtract(7,'days').format(dateFormat);
        } else if(_.isNumeric(week)) {
            return _.weekStart(this.mealPlanStartMom().add(7*Number(week),'days'),this.mealPlanWeekday).format(dateFormat)
        } else if(week === 'third' || week === 'fourth') {
            return this.activeWeekStart(week).format(dateFormat);
        } else {
            return this.firstMealMoment().format(dateFormat);
        }
    }

    mealPlanEndDateFor(week) {
        if(week === 'last') {
            return this.firstMealMoment().subtract(1,'days');
        }
        return this.activeWeekEnd(week);
    }

    weekForDate(mom,allowLast=false) {
        if(mom.isAfter(moment().add(99999,'days'))) {
            const days = mom.diff(this.mealPlanStartMom(),'days');
            return Math.floor(days/7)
        }
        if(allowLast && mom.isBefore(this.firstMealMoment())) {
            return 'last';
        }
        for(let week of ['current','next','third','fourth']) {
            const start = this.activeWeekStart(week);
            const end = this.activeWeekEnd(week);
            if(mom.isBetween(start,end,'day','[]')) {
                return week;
            }
        }
        return 'current';
    }

    weekForUserMeal(userMeal) {
        if(userMeal) {
            const mom = moment(userMeal.date);
            return this.weekForDate(mom);
        }
        return 'current';
    }

    planMealTypes() {
        return _.sortBy(_.filter(this.mealTypes,mt => mt.category !== 'misc'),mt => MealType.CATEGORIES.indexOf(mt.category));
    }

    sortedActiveOnPlanMts() {
        return _.sortBy(this.activeOnPlanMealTypes(),mt => MealType.CATEGORIES.indexOf(mt.category));
    }

    mealPlanIsDirty(week) {
        if(this.isPdfClient() || _.isBlank(this.mealPlanDirty) || this.mealPlanDirty.length === 0) {
            return false;
        }

        const weekStart = this.activeWeekStart(week);

        return _.some(this.mealPlanDirty, dt => weekStart.isBetween(moment(dt),moment(dt).add(6,'days'),'day','[]'));
    }

    mealPlanGeneratedOn(date) {
        if(_.isBlank(this.lastMealPlanDay)) {
            return false;
        }

        return date.isSameOrBefore(moment(this.lastMealPlanDay),'day');
    }

    firstMealPlanIsGenerated() {
        if(_.isBlank(this.mealPlanStart) || _.isBlank(this.lastMealPlanDay)) {
            return false;
        }

        return this.lastMealPlanDayMom().isAfter(this.mealPlanStartMom(),'day');
    }

    mealPlanGenerateState(week) {
        if(week === 'single') {
            return 'generated';
        }

        if(_.isBlank(this.lastMealPlanDay)) {
            return 'ungenerated';
        }

        const lastDay = moment(this.lastMealPlanDay);
        const weekStart = this.activeWeekStart(week)
        const weekEnd = this.activeWeekEnd(week);
        if(lastDay.isBefore(weekEnd)) {
            if(lastDay.isBefore(weekStart)) {
                return 'ungenerated';
            } else {
                return 'partial';
            }
        }

        if(this.mealPlanIsDirty(week)) {
            return 'dirty';
        }

        return 'generated';
    }

    workoutPlanInitialized() {
        return !_.isBlank(this.assignedRoutineId);
    }

    sawTooltip(tipName) {
        return this.seenTooltips && this.seenTooltips.includes(tipName)
    }

    routineStartDate() {
        return moment(this.routineStart);
    }

    currentWorkoutWeekStart(date=null) {
        date = date || moment();
        return _.weekStart(date,this.routineStartDate().day());
    }

    activeWorkoutWeeks(date=null) {
        date = date || moment();
        const curWeek = this.currentWorkoutWeekStart(date);
        return [curWeek.clone().subtract(7,'days'),curWeek,curWeek.clone().add(7,'days')];
    }

    exerciseSettingFor(exerciseId) {
        return _.find(this.exerciseSettings,exerciseSetting => (exerciseSetting.exerciseId === exerciseId));
    }

    isMetric(exercise=null) {
        if(exercise) {
            return User.metric(this.unitsFor(exercise));
        } else {
            return User.metric(this.unitPreference);
        }
    }

    mincrement(exercise) {
        const setting = this.exerciseSettingFor(exercise.id);
        if(setting && setting.isOverride() && !_.isBlank(setting.mincrement)) {
            return setting.mincrement;
        } else if(this.defaultMincrements && !_.isBlank(this.defaultMincrements[exercise.equipmentType])) {
            return this.defaultMincrements[exercise.equipmentType];
        } else {
            return this.defaultMincrement(this.unitsFor(exercise),exercise.equipmentType);
        }
    }

    unitMincrement(exercise) {
        return _.unitWeight(this.mincrement(exercise),this.unitsFor(exercise));
    }

    unitsFor(exercise) {
        const setting = this.exerciseSettingFor(exercise.id);
        if(setting && setting.isOverride() && !_.isBlank(setting.units)) {
            return setting.units;
        } else if(this.defaultUnits && !_.isBlank(this.defaultUnits[exercise.equipmentType])) {
            return this.defaultUnits[exercise.equipmentType];
        } else {
            return this.unitPreference;
        }
    }

    defaultMincrement(units,equipmentType) {
        return StrengthStandards.mincrement(units,equipmentType)
    }

    defaultEquipmentUnits(equipmentType) {
        if(this.defaultUnits && !_.isBlank(this.defaultUnits[equipmentType])) {
            return this.defaultUnits[equipmentType];
        } else {
            return this.unitPreference;
        }
    }

    defaultExerciseSettingUnits(exerciseSetting,equipmentType) {
        if(exerciseSetting && exerciseSetting.isOverride() && !_.isBlank(exerciseSetting.units)) {
            return exerciseSetting.units;
        } else {
            return this.defaultEquipmentUnits(equipmentType)
        }
    }

    defaultEquipmentMincrement(equipmentType) {
        if(this.defaultMincrements && !_.isBlank(this.defaultMincrements[equipmentType])) {
            return this.defaultMincrements[equipmentType];
        } else {
            return '';
        }
    }

    defaultExerciseSettingMincrement(exerciseSetting,equipmentType) {
        if(exerciseSetting && exerciseSetting.isOverride()) {
            if(!_.isBlank(exerciseSetting.mincrement)) {
                return exerciseSetting.mincrement;
            } else {
                return '';
            }
        } else {
            return this.defaultEquipmentMincrement(equipmentType);
        }
    }

    defaultMincrementType(exerciseSetting) {
        return exerciseSetting ? (exerciseSetting.mincrementType || '') : '';
    }

    defaultUnitMincrement(exerciseSetting,equipmentType,units) {
        let retVal = this.defaultExerciseSettingMincrement(exerciseSetting,equipmentType);

        if(_.isBlank(retVal)) {
            return retVal || '';
        } else {
            return _.unitWeight(retVal,units);
        }
    }

    mincrementPlaceholder(units,equipmentType,mincrementType) {
        const isOverride = (mincrementType === 1);
        let retVal = this.defaultMincrement(units,equipmentType);
        if(isOverride && this.defaultMincrements && !_.isBlank(this.defaultMincrements[equipmentType])) {
            retVal = this.defaultMincrements[equipmentType];
        }

        return _.unitWeight(retVal,units);
    }

    needsStrengthTest(exerciseId) {
        return !this.testedExercises.includes(exerciseId);
    }

    needsProgTest(progression) {
        return !this.testedProgs.includes(progression.id);
    }

    findUserMeal(category,date) {
        const mealType = _.find(this.mealTypes,mt => mt.category === category)
        if(mealType) {
            return _.find(mealType.userMeals,um => um.date === date)
        }
        return null;
    }

    findUserMealById(userMealId) {
        return _.find(_.flatMap(this.mealTypes,mt => mt.userMeals),userMeal => userMeal.id === Number(userMealId));
    }

    findMealType(id) {
        return _.find(this.mealTypes,mt => mt.id === id)
    }

    activeFoods() {
        if(this.isTrainer() && !this.isMaster()) {
            return this.getMasterAccount().activeFoods();
        }
        return _.filter(this.foods,food => !food.inactive)
    }

    mappedRecipeIds(ids) {
        const map = this.recipeOverrideMap || {};
        return ids.map(id => (map[id] || id))
    }

    resolvedLikedRecipeIds = () => {
        if(this.rlri) {
            return this.rlri;
        }

        if(this.likedRecipeIds) {
            this.rlri = this.mappedRecipeIds(this.likedRecipeIds);
            return this.rlri;
        }

        return this.likedRecipeIds;
    }

    resolvedAllTrainerRecipeIds = () => {
        if(this.ratri) {
            return this.ratri;
        }

        if(this.allTrainerRecipeIds) {
            this.ratri = this.mappedRecipeIds(this.allTrainerRecipeIds);
            return this.ratri;
        }
        
        return this.allTrainerRecipeIds;
    }

    visibleActiveRecipes(filter) {
        return _.filter(this.recipes,recipe => (recipe.isActive() && !this.dislikedRecipeIds.includes(recipe.staticId()) && filter(recipe)))
    }

    activeLikedRecipes(filter) {
        return _.filter(this.likedRecipes,recipe => (recipe.isActive() && filter(recipe)))
    }

    activeTrainerLikedRecipes(filter) {
        return _.filter(this.allTrainerLikedRecipes,recipe => (recipe.isActive() && filter(recipe)))
    }

    lastActiveMom() {
        if(_.isBlank(this.lastActiveAt)) {
            return null;
        }
        return moment(this.lastActiveAt);
    }

    lastActiveAtStr(t) {
        if(this.inviteSent()) {
            return [t('invite pending'),null];
        }

        if(this.lastActiveAt) {
            const mom = this.lastActiveMom();
            const diff = moment().diff(mom,'days');
            const fullStr = mom.format('MMMM Do, YYYY')
            if(diff <= 0) {
                return [t('today'),fullStr];
            } else if(diff <= 1) {
                return [t('yesterday'),fullStr];
            } else {
                return [t("X days ago", { days: diff }),fullStr]
            }
        } else {
            return [t('never'),null];
        }
    }

    habitLogsByDate(dt) {
        return _.filter(this.habitLogs,hl => hl.date === dt.format(dateFormat));
    }

    activityLogsByDate(dt) {
        return _.filter(this.activityLogs,al => (al.date === dt.format(dateFormat) && !al.isDeleted()));
    }

    activityLogById(id) {
        return _.find(this.activityLogs,al => (al.id === Number(id)));
    }

    habitSettings() {
        return _.filter(this.schedulableSettings,ss => ss.sourceType === 'Habit');
    }

    fcaSettings() {
        return _.filter(this.schedulableSettings,ss => ss.sourceType === 'Form');
    }

    currentHabitSettings() {
        return _.filter(this.habitSettings(), hs => hs.isCurrent());
    }

    hasAnyAssignedHabits() {
        return this.currentHabitSettings().length > 0;
    }

    activeAssignedHabits() {
        return this.currentHabitSettings().map(hs => hs.source);
    }

    hasSetupHabits() {
        return this.habitSettings().length > 0 || this.habitsSetup;
    }

    hasSetupForms() {
        return this.fcaSettings().length > 0 || this.formsSetup;
    }

    notificationSettingsValues(defaultToOn) {
        const baseAttrs = _.flatMap(Object.keys(this.constructor.NOTIFICATION_DEFAULTS),base => [`${base}Remind`,`${base}RemindPm`]);
        const vals = _.pick(this,[ 'remindToPlanMeals', ...baseAttrs ]);
        Object.entries(this.constructor.NOTIFICATION_DEFAULTS).forEach(([base,defaults]) => {
            const val = vals[`${base}Remind`];
            if(_.isBlank(val)) {
                vals[`${base}Remind`] = defaults.at;
                vals[`${base}RemindPm`] = defaults.pm;
                vals[`${base}RemindOn`] = !!defaultToOn;
            } else {
                vals[`${base}RemindOn`] = true;
            }
        })
        return _.parseObjForForm(vals);
    }

    notificationSettingsStrs(t) {
        const stubs = Object.keys(this.constructor.NOTIFICATION_DEFAULTS);
        const m = moment().startOf('day');
        const res = [];
        stubs.forEach(stub => {
            const mins = this[`${stub}Remind`];
            const pm = this[`${stub}RemindPm`];
            if(!['fastStart','fastEnd'].includes(stub)) {
                let label;
                switch(stub) {
                    case 'workout': {
                        label = t('Workout reminder');
                        break;
                    }
                    case 'eod': {
                        label = t("meal reminder",{ meal: t('EOD') })
                        break;
                    }
                    default: {
                        label = t("meal reminder", { meal: t(_.capitalize(stub))})
                        break;
                    }
                }
    
                if(_.isBlank(mins)) {
                    res.push([label,t('None')]);
                } else {
                    const newM = m.clone();
                    newM.add(mins,'minutes');
                    const timeStr = `${newM.format('h:mm')} ${pm ? t('PM') : t('AM')}`;
                    res.push([label,timeStr])
                }
            }
        })
        res.push([t('Plan meals reminder'),this.remindToPlanMeals ? t('On') : t('None')])
        return res;
    }

    allowedToEditOwnRoutine() {
        if(!this.isClient() || this.pickOwnRoutine) {
            return true;
        }

        return this.canEditOwnRoutine;
    }

    allowedToEditOwnMealPlan() {
        if(!this.isClient()) {
            return true;
        }

        return this.canEditOwnMealPlan;
    }

    showRecipePickStep() {
        if(!this.isClient()) {
            return true;
        }

        if(this.isAppClient()) {
            return this.canEditOwnMealPlan;
        }

        return this.canPickInitialRecipes;
    }

    getImagePathForSize(name,size) {
        return _.isBlank(size) ? (this[name] && this[name].url) : (this[name] && this[name][size] && this[name][size].url);
    }

    logoImagePath(size,allowPlaceholder) {
        const url = this.getImagePathForSize('logo',size);
        if(url && !url.includes('NoImageAvailable')) {
            return url;
        } else if(allowPlaceholder) {
            return logoPlaceholder;
        } else {
            return logoUrl();
        }
    }

    logoSource(size) {
        return this.getMasterAccount().logoImagePath(size,this.isTrainerAdmin());
    }

    gaTrainerType() {
        if(this.isTrainer()) {
            return this.isAppTrainer() ? 'app' : 'pdf';
        } else if(this.isClient()) {
            return 'client';
        }
        return '';
    }

    gaTrainerPlanType() {
        if(this.isClientOrTrainer()) {
            return this.getTrainerSubscription() && this.getTrainerSubscription().planType;
        }
        return ''; 
    }

    isFromDiscountedAffiliate() {
        return !_.isBlank(this.fromDiscountedAffiliate);
    }

    discountedAffiliateBrand() {
        return this.fromDiscountedAffiliate;
    }

    shouldShowGetAppPrompt() {
        return !this.hasApp() && (this.isPro() || (this.isTrainer() && this.isTrainerSubInit()) || !this.isFromDiscountedAffiliate());
    }

    hasDislikedRecipes() {
        return (this.dislikedRecipeIds && this.dislikedRecipeIds.length > 0) || (this.clientDislikedRecipeIds && this.clientDislikedRecipeIds.length > 0);
    }

    needsChatSubscription() {
        if(this.isClientOrTrainer() && !this.isPdfClient() && !this.isPdfTrainer()) {
            return true;
        }

        return false;
    }

    needsHealthkitSubscription() {
        if(this.hasApp() && (this.mealPlanInitialized() || this.workoutPlanInitialized())) {
            return true;
        }

        return false;
    }

    miniProfileById(id) {
        return _.find(this.miniProfiles,miniProfile => miniProfile.id === Number(id));
    }

    orderedCustomExercises(newlyCreated,t) {
        return _.recsToFront(_.sortBy(this.customExercises,ex => ex.fullName(t)),newlyCreated);
    }

    orderedProgressPhotos(angle) {
        let allPhotos;
        if(this.orderedProgPhotoCache) {
            allPhotos = this.orderedProgPhotoCache;
        } else {
            allPhotos = (this.orderedProgPhotoCache = _.sortBy(_.filter(this.progressPhotos, pp => pp.hasImage()),pp => pp.sortAttr()));
        }
        return _.isBlank(angle) ? allPhotos : _.filter(allPhotos,photo => photo.angle === angle);
    }

    hasAnyProgressPhotos(angle) {
        return this.orderedProgressPhotos(angle).length > 0;
    }

    getProgPhotoById(id) {
        return _.find(this.progressPhotos,pp => (pp.id === Number(id)));
    }

    showNewWorkoutOnboarding() {
       return (this.createdAtMom().isSameOrAfter(moment('2025-04-09'),'day') && (_.isBlank(this.liftingExperience) || this.liftingExperience < 2) && !this.isClientOrTrainer() && !this.sawTooltip(WORKOUTS_ONBOARD_TIP));
    }

    resolvedWorkoutPreviewPath(date) {
        if(this.showNewWorkoutOnboarding()) {
            return workoutOnboardStartPath(date);
        } else {
            return workoutPreviewPathFor(date);
        }


    }
}
Object.assign(User.prototype,trainer);
Object.assign(User.prototype,trainerClient);
Object.assign(User.prototype,userLayout);
Object.assign(User.prototype,chatConcern);
Object.assign(User.prototype,dnpConcern)

window.User = User;


registerInflection('user','users',User);

export class StrengthStandards {
    static STRENGTH_STANDARDS = { intermediate: [[1.5,1.15,1.7],[1.0,0.7,1.25]], advanced: [[2,1.5,2.5],[1.3,1,1.7]]};

    static WORK_WEIGHTS = [
        {load: 0.9, rep_max: 3},
        {load: 0.85, rep_max: 5},
        {load: 0.825, rep_max: 7},
        {load: 0.8, rep_max: 8},
        {load: 0.775,rep_max:9},
        {load: 0.75, rep_max: 10},
        {load: 0.725, rep_max: 11},
        {load: 0.7, rep_max: 12},
        {load: 0.675, rep_max: 13},
        {load: 0.65, rep_max: 15},
        {load: 0.65, rep_max: 15},
        {load: 0.6, rep_max: 20},
        {load: 0.575, rep_max: 25},
        {load: 0.55, rep_max: 30},
        {load: 0.525, rep_max: 35},
        {load: 0.5, rep_max: 40},
        {load: 0.4, rep_max: 50},
        {load: 0.33, rep_max: 75},
        {load: 0.25, rep_max: 100}
    ]

    static MAX_MAP = [1,0.94,0.91,0.88,0.86,0.83,0.8,0.78,0.76,0.75,0.72,0.7,0.68,0.66,0.65,0.64,0.63,0.62,0.61,0.6]

    static PROGRESS_INCREMENT = {upper: [7.5,8.25],
        lower: [15.0,16.5],
        reps: 1,
        isometric: 2,
        minimum: [5.0,5.5], minimumFlex: [2.5,2.75],
        maximum: 20, easy: [90,40], moderate: [50,20]
    }

    static DEFAULT_MINCREMENTS = {3: this.PROGRESS_INCREMENT.minimum,
         6: this.PROGRESS_INCREMENT.minimum,
         8: this.PROGRESS_INCREMENT.minimum,
         9: this.PROGRESS_INCREMENT.minimum,
         10: this.PROGRESS_INCREMENT.minimumFlex,
         11: this.PROGRESS_INCREMENT.minimumFlex,
         12: this.PROGRESS_INCREMENT.minimumFlex,
         13: this.PROGRESS_INCREMENT.minimumFlex,
         15: this.PROGRESS_INCREMENT.minimumFlex,
         18: this.PROGRESS_INCREMENT.minimumFlex,
         25: this.PROGRESS_INCREMENT.minimum,
         101: this.PROGRESS_INCREMENT.minimumFlex
    }

    static wilksCoeff(bw,gender) {
        let denom;
        if(User.female(gender) ) {
            denom = 594.31748 - 27.23842*bw + 0.82112*Math.pow(bw,2) - 0.009307*Math.pow(bw,3) + 0.00004731582*Math.pow(bw,4) - 0.00000009054*Math.pow(bw,5);
        } else {
            denom = -216.0475144 + 16.26063*bw - 0.002388645*Math.pow(bw,2) - 0.00113732*Math.pow(bw,3) + 0.00000701863*Math.pow(bw,4) - 0.00000001291*Math.pow(bw,5)
        }
        return 500.0/denom;
    }

    static standards(gender,level,bw,unitPref,t=(val) => val) {
        const defBw = User.female(gender) ? 132.0 : 165.0;
        const defWilks = this.wilksCoeff(defBw/2.2,gender);
        const userWilks = this.wilksCoeff(bw/2.2,gender);
        const wilksMult = defWilks/userWilks;
        const index = User.female(gender) ? 1 : 0;
        let standards = this.STRENGTH_STANDARDS[level][index];
        const suffix = User.weightSuffix(unitPref);
        standards = standards.map((bwMult) => {
            let poundVal = defBw*bwMult*wilksMult;
            if(User.metric(unitPref)) {
                return Math.round(poundVal/2.2);
            } else {
                return Math.round(poundVal);
            }
        })
        return { 
            squat: `${standards[0]} ${t(suffix)}`, 
            bench: `${standards[1]} ${t(suffix)}`, 
            deadlift: `${standards[2]} ${t(suffix)}` 
        }
    }

    static oneRepMaxCore(max,maxReps,baseWeight=0) {
        baseWeight = baseWeight || 0;

        const effectiveMax = baseWeight + max;
        const index = Math.min(maxReps-1,this.MAX_MAP.length-1);
        const pctOneRM = this.MAX_MAP[index];
        return effectiveMax/pctOneRM;
    }

    static oneRepMax(max,maxReps,baseWeight=0) {
        const rm = this.oneRepMaxCore(max,maxReps,baseWeight);

        return Math.round(rm - (baseWeight || 0));
    }

    static workWeight(max,maxReps,workReps,baseWeight=0) {
        baseWeight = baseWeight || 0;
        let percentMax = 0;
        if(workReps > 12) {
            if(workReps >= 95) {
                percentMax = 0.25;
            } else {
                for(const params of this.WORK_WEIGHTS) {
                    if(params.repMax >= workReps+5) {
                        percentMax = params.load;
                        break;
                    }
                }
            }
        } else {
            const { repMax, load } = this.WORK_WEIGHTS[workReps-1];
            if(repMax === maxReps) {
                return max;
            } else {
                percentMax = load;
            }
        }

        return percentMax*this.oneRepMaxCore(max,maxReps,baseWeight) - baseWeight;
    }

    static mincrement(unitPref,equipmentType) {
        const defaultMincrement = this.DEFAULT_MINCREMENTS[equipmentType];
        if(defaultMincrement) {
            return defaultMincrement[unitPref];
        } else {
            return this.PROGRESS_INCREMENT.minimum[unitPref]
        }
    }
}