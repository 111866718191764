import React, { useState } from 'react';
import Page from 'components/Page';
import { SimpleNav, SimpleNavTitle, SimpleNavContainer, SimpleNavRightButton } from 'components/Nav';
import { withTranslation, useTranslation } from 'react-i18next';
import * as transitions from 'assets/transitions';
import { workoutLogMatch, progressionPickMatch, workoutPreviewMatch, workoutResetMatch, 
    workoutDoMatch, workoutResetPathFor, workoutDoMatchFor, workoutDoBaseFor, 
    swapExerciseSpecMatch, swapExerciseSpecPathFor, strengthTestMatch, progressionTestMatch, progressionTestPathFor,
    progressionPickPathFor, strengthTestPathFor, plateCalculatorModalPathFor, emailPaywallMatches, modalPathFor, weightCalcModalPostfix } from 'config/paths';
import { connect } from 'react-redux';
import withWorkout from 'partials/RoutineStateHOC';
import { standaloneStartWorkout, restartWorkout, logExerciseSets, updateWorkout, updateExerciseSpecification, swapExerciseSpec, workoutsLoggedThisWeek } from 'redux/actions';
import { Link, useLocation } from 'react-router-dom';
import { Redirect } from "components/Routing";
import { RouteTransitionMap } from 'components/RouteTransitionMap';
import moment from 'moment';
import Card, { CardContent, IconOptionCard } from 'components/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import loadingContainer, { LoadingContainerParent, blankDefaultMap } from 'components/LoadingHOC';
import Button from 'components/Button';
import * as _ from 'lib/utilities';
import { ExerciseVideo } from 'partials/ExerciseImage';
import { workoutDoPathFor } from 'config/paths';
import { Dropdown } from 'components/Dropdown';
import { ExerciseInfoModal, WeightCalculatorModal, ExerciseSettingsModal, PlateCalculatorModal } from 'partials/ExerciseModals';
import { Formik } from 'formik';
import { ExerciseSetFields} from 'partials/ExerciseSetFields';
import { dateFormat } from 'config/settings';
import { useHistory } from 'react-router-dom';
import { CountdownTimer, MuteTimerButton } from 'partials/ConnectedTimers';
import { InputWithErrors, formikInputProps, formikInputWrapperProps } from 'components/Form';
import { NumberInput } from 'components/TextInput';
import { CountupTimer } from 'components/Timers';
import { Superset } from 'partials/ExerciseListing';
import { actionRender } from 'views/WorkoutPreview';
import classnames from 'classnames';
import { ExerciseSet } from 'lib/exercise-set';
import { WorkoutInput, InstructionBox} from 'partials/WorkoutInputs';
import { StandaloneExerciseSearch } from 'partials/ExerciseSearchForm';
import { PickButtonLC } from 'views/ProgressionPick';
import { ExerciseActionMenu } from 'views/WorkoutLog';
import { getStrengthTestSpecIdsSelector } from 'redux/selectors';
import ProRequiredButton from 'partials/ProRequiredButton';
import RatingPrompt from 'partials/RatingPrompt';
import { SignupModal } from 'partials/SignupModal';
import { resolvedHomePath } from 'redux/helpers';
import { workoutPlanSlideOutPaths } from 'partials/MainLayout';
import { RatingButtons } from 'components/RatingButtons';

const defaultRules = [
    [workoutResetMatch,transitions.flowFormOut]
]

const pickExerciseActionComp = (swapExerciseSpec,spec) => ({ exercisable, setBlockModals }) => {

    return (<LoadingContainerParent 
        load={swapExerciseSpec.bind(null,spec,exercisable)}
        preloaded={() => false}
        component={PickButtonLC}
        setBlockModals={setBlockModals}
        failCallback={() => setBlockModals(false)}
        className={`ex-swap-btn-${exercisable.id}`}
        skipAutoLoad
    />)
}

let PickSpecificExercise = ({ spec, swapExerciseSpec, infoModal }) => {

    const location = useLocation();
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <StandaloneExerciseSearch 
                context={{id: spec.id, type: 'ExerciseSpecification'}} 
                initialValues={spec.exerciseSearchValues()} 
                basePath={location.pathname}
                hideForm
                hideGeneric
                actionComp={pickExerciseActionComp(swapExerciseSpec,spec)}
                header={(<div className="label-3 text-center ml10 mr10 mb15 mt20">
                <FontAwesomeIcon icon="info-circle" className="primary-text" /> {spec.isCardio() ? t("What type of cardio do you want to do?") : t("Pick your next exercise from below")}
            </div>)}
            />
            {infoModal}
        </React.Fragment>
    )
}

const mapDispatchToSwapExercise = dispatch => ({
    swapExerciseSpec: (spec,exercisable) => dispatch(swapExerciseSpec(spec,exercisable))
})

PickSpecificExercise = connect(null,mapDispatchToSwapExercise)(PickSpecificExercise);

const InitCard = ({ path, onClick, icon, title, subtitle, id }) => {
    const history = useHistory();

    return (
        <IconOptionCard 
            title={title} 
            subtitle={subtitle} 
            icon={icon} 
            id={id ? id : ''} 
            onClick={(e) => (onClick ? onClick(e) : history.push(path) ) } 
        />
    )
}

let PickWeightForm = ({ exerciseSet, updateSets }) => {
    const spec = exerciseSet.exerciseSpecification;
    const { t } = useTranslation();
    const sets = spec.uniqueLoadingSets();
    const count = sets.length;
    const history = useHistory();

    return (
        <Formik
            initialValues={ _.parseObjForForm({ exerciseSets: sets.map(set => set.formValues(true)) }) }
            onSubmit={values => {
                ExerciseSet.updateAll(sets,values);
                updateSets(spec.resolvedWorkout(),sets,true);
                const set = _.find(sets,set => set.id === exerciseSet.id);
                history.push(set.afterInitialPath());
            }}
            validate={values => ExerciseSet.updateAll(sets,values)}
            initialErrors={{}}
        >
            {({ submitForm, ...formikProps }) => {
                const inputProps = _.pick(formikProps,formikInputWrapperProps);
                return (
                    <React.Fragment>
                        {sets.map((set,index) => {
                            const classNames = classnames('display-flex pt10 pb10 slight-bottom-border');
                            const loadingField = set.loadingField();

                            return (
                                <div className={classNames} key={set.id}>
                                    <div>
                                        <InstructionBox 
                                            className="mt0"
                                            id={`pick-weight-instr-${set.id}`}
                                            title={count === 1 ? `${t('Instructions')}:` : `${t('Weight')} ${index+1}:`} 
                                            instructions={set.pickWeightPrompt(t)} 
                                        />
                                    </div>
                                    <div>
                                        <WorkoutInput 
                                            exercise={set.exercise()}
                                            field={loadingField}
                                            fieldPrefix={`exerciseSets.${index}.`}
                                            inProps={{ id: `pick-${loadingField}-${set.id}`}}
                                            {...inputProps}
                                        />
                                    </div>
                                </div>
                            )
                        })}
                        <div className="mt25">
                            <Button rounded color="primary" className="ml5" onClick={submitForm} id="pick-weight-btn">
                                <FontAwesomeIcon icon="arrow-right"></FontAwesomeIcon> {t("Continue")}
                            </Button>
                        </div>
                    </React.Fragment>
                )
            }}
        </Formik>
    )
}

const mapDispatchToUpdateSets = dispatch => ({
    updateSets: (workout,sets,copyWeights) => dispatch(logExerciseSets(workout,sets,copyWeights))
})

PickWeightForm = connect(null,mapDispatchToUpdateSets)(PickWeightForm)

const InitializeButtons = ({ exerciseSet, setIndex }) => {
    const spec = exerciseSet.exerciseSpecification;
    const { t } = useTranslation();
    const { pathname } = useLocation();

    if(spec.needsToPickWeight()) {
        return <PickWeightForm exerciseSet={exerciseSet} />;
    } else if(spec.isProgression()) {
        return (
            <React.Fragment>
                <InitCard 
                    id="progression-test-card"
                    icon={'tachometer-alt'}
                    path={progressionTestPathFor(spec.id,spec.date(),0)}
                    title={t('Take a Strength Test')}
                    subtitle={t("Take a quick strength test to figure out which level of the progression to use")}
                    
                />
                <InitCard 
                    id="progression-pick-card"
                    icon={'hand-pointer'}
                    path={progressionPickPathFor(spec.id,spec.date())}
                    title={t('Pick a Progression Level')}
                    subtitle={t("Manually pick an appropriately difficult exercise from the progression")}
                />
            </React.Fragment>
        )
    } else if(spec.isWeighted()) {
        return (
            <React.Fragment>
                <InitCard 
                    id="strength-test-card"
                    icon={'tachometer-alt'}
                    path={strengthTestPathFor(spec.id,spec.date(),0)}
                    title={t('Take a Strength Test')}
                    subtitle={t("Take a quick strength test to figure out how much weight to use")}
                />
                <InitCard 
                    id="weight-calculator-card"
                    icon={'calculator'}
                    path={modalPathFor(weightCalcModalPostfix,pathname,{ id: spec.resolvedExerciseId() })}
                    title={t('Calculate Weight')}
                    subtitle={t("Calculate how much weight to use based on past lifts")}
                />
            </React.Fragment>
        )
    } else {
        return '';
    }
}

const InitializeContent = ({ exerciseSet, setIndex }) => {
    const spec = exerciseSet.exerciseSpecification;
    const { t } = useTranslation();
    const pickWeight = spec.needsToPickWeight();

    return (
        <React.Fragment>
            <ExerciseVideoAndInfoWrapper exerciseSet={exerciseSet} setIndex={setIndex} showTag isInitial suffix='initial'/>
            <div className="wo-wt-details-wrapper">
                <div className="wo-wt-intro-new">{spec.isProgression() ? t("New Exercise Progression!") : t('New Exercise!')}</div>
                <div className={`wo-wt-intro-text text-left`}>{pickWeight ? t('Pick your weight(s)') : t("Let's calibrate it for you")}</div>
                <div className="init-wrapper">
                    <InitializeButtons exerciseSet={exerciseSet} setIndex={setIndex} />
                    {pickWeight && spec.user().allowedToEditOwnRoutine() && (<div className="wo-wt-intro-text text-left ml10 mt30">
                            {t('Or')}
                        </div>)}
                    {spec.user().allowedToEditOwnRoutine() && (<ProRequiredButton 
                        signup 
                        user={spec.user()} 
                        proPath={swapExerciseSpecPathFor(spec.date(),spec.id)} 
                        blockTypes={['hard','soft']}
                        context="swap_exercise"
                        render={({ onClick }) => (
                            <InitCard 
                                id={'swap-exercise-card'}
                                icon={'exchange-alt'}
                                onClick={onClick}
                                title={t('Swap Out')}
                                subtitle={t("Find a different exercise that works similar muscle groups")}
                            />
                        )} 
                    />)}
                    
                </div>
            </div>
        </React.Fragment>
    )
}

const InitializeDone = ({ exerciseSet, setIndex }) => {
    const spec = exerciseSet.exerciseSpecification;
    const { t } = useTranslation();
    const relSet = spec.workSets()[0];
    const history = useHistory();

    return (
        <React.Fragment>
            <ExerciseVideoAndInfoWrapper exerciseSet={exerciseSet} setIndex={setIndex} showTag suffix='initial' />
            <div className="wo-wt-details-wrapper">
                <div className="wo-wt-intro-new">{spec.isProgression() ? `${t("Exercise progression calibrated")}!` : `${t('Exercise calibrated')}!`}</div>
                <div className="wo-wt-intro-text text-left">{t("You'll start with", { object: spec.isProgression() ? spec.exercise.name : '' })}</div>
                {spec.isProgression() && (<div id="prog-done-circle" className="calibrate-circle">
                    <FontAwesomeIcon icon="check" className="success-color vert-align-mid" size="3x"></FontAwesomeIcon>
                </div>)}
                {spec.isWeighted() && (<div id="strtst-done-circle" className="calibrate-circle">
                    {`${relSet.unitWeight()} ${relSet.weightSuffix(t)}`}
                </div>)}
                <div className="mt25">
                    <Button rounded color="primary" id="initialize-done-btn" onClick={() => history.push(exerciseSet.afterInitialPath())}>
                        <FontAwesomeIcon icon="arrow-right"></FontAwesomeIcon> {t("Continue")}
                    </Button>
                </div>
            </div>
        </React.Fragment>
    )
}

const InitializeScreen = ({ exerciseSet, setIndex }) => {
    const spec = exerciseSet.exerciseSpecification;

    if(spec.needsInitialization()) {
        return (<InitializeContent exerciseSet={exerciseSet} setIndex={setIndex} />)
    } else {
        return (<InitializeDone exerciseSet={exerciseSet} setIndex={setIndex} />)
    }
}

const advanceSupersetCreator = (exerciseSet,updateSpec,history) => () => {
    const spec = exerciseSet.exerciseSpecification;
    spec.supersetStarted = true;
    updateSpec(spec,{ supersetStarted: true });
    history.push(exerciseSet.supersetArr().afterConfirmPath());
}

let SupersetPreview = ({ exerciseSet, updateSpec }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const superset = exerciseSet.supersetArr();
    const [ label, instructions ] = superset.instructions(t);

    return (
        <div style={{ width: '523px', maxWidth: '97%', marginLeft: 'auto', marginRight: 'auto' }}>
            <div className="wo-wt-ex-title mb10 mt20">{t('Next up')} {superset.label(t,true)}</div>
            <Superset superset={superset} actionRender={actionRender(t,exerciseSet.pathFor('superset_preview'))} />
            <div className="mt20">
                <InstructionBox title={label} instructions={instructions} id={`${superset.isCircuit() ? 'circuit' : 'superset'}-instruction-box`} />
            </div>
            <div className="mt25">
                <Button id="superset-next-btn" rounded color="primary" onClick={advanceSupersetCreator(exerciseSet,updateSpec,history,'continue')}>
                    <FontAwesomeIcon icon="play"></FontAwesomeIcon> {t("Ok")}
                </Button>
            </div>
        </div>
    )
}

const mapDispatchToUpdateSpec = dispatch => ({
    updateSpec: (spec,updates) => dispatch(updateExerciseSpecification(spec,updates))
})

SupersetPreview = connect(null,mapDispatchToUpdateSpec)(SupersetPreview);

const RestLogCard = ({ exerciseSet, setAutoAdvance, timerStatus, field, t }) => {
    const [ hidden, setHidden ] = useState(false);
    const finishForm = () => {
        setAutoAdvance(true);
        setHidden(true);
    }

    if(hidden) {
        return '';
    } else {
        return (
            <Card className="workout-log rep-log">
                <div className="wol-right-icon clickable" onClick={finishForm}>
                    <FontAwesomeIcon icon="times"></FontAwesomeIcon>
                </div>
                <ExerciseSetFormWrapper 
                    exerciseSet={exerciseSet} 
                    advanceOnSubmit={timerStatus === 'finished'} 
                    checkRest={false} 
                    initValOverrides={{ [field]: '' }}
                    callback={finishForm}
                    render={(formikProps) => {
                        const { handleBlur, ...newFormikProps } = formikProps;
                        const onFocus = (e) => {
                            setAutoAdvance(false);
                        }
                        newFormikProps.handleBlur = (e) => {
                            handleBlur(e);
                            setAutoAdvance(true);
                        }
                        const inputProps = _.pick(newFormikProps,formikInputProps);
                        let helperText = '';
                        if(field === 'reps') {
                            helperText = _.lowerFirst(t('Reps'));
                        } else if(field === 'distance') {
                            helperText = t('meters');
                        } else if(field === 'kmDistance') {
                            helperText = t('kilometers');
                        } else if (field === 'mileDistance') {
                            helperText = t('miles');
                        }
                        return (
                            <React.Fragment>
                                <div className="display-flex wol-exercise-container">
                                    <div className="wo-wt-rest-opt-text">{`${t('Optional')}: ${field === 'reps' ? t('How many reps did you do?') : t('How far did you get?')}`}</div>
                                </div>
                                <div className="valign-wrapper cntr">
                                    <InputWithErrors 
                                        label={''}
                                        helperText={helperText}
                                        className="inline mr5"
                                        name={field}
                                        align="center"
                                        component={NumberInput}
                                        inProps={{ onFocus }}
                                        {...inputProps}
                                    />
                                    <Button color="primary" outlined rounded onClick={formikProps.submitForm}>
                                        <FontAwesomeIcon icon="check"></FontAwesomeIcon>
                                        {t('Log')}
                                    </Button>                                
                                </div>
                            </React.Fragment>
                        )
                }} />
            </Card>
        )
    }
}

export const RestScreen = ({ time, nextPath, nextExercise, nextGoal, nextSet, logCard, autoAdvance, basePath }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();

    return (
        <CountdownTimer seconds={time} autoStart onFinish={() => (autoAdvance && !location.pathname.includes('plate_calculator') && history.replace(nextPath))} render={({ display, status }) => {

            return (
                <div className="text-center">
                    <div className="wo-wt-ex-title rest-title">{t('Rest')}</div>
                    <div className="wo-wt-ex-num">{display}</div>
                    <div className="mt20">
                        <Button onClick={() => history.replace(nextPath)} color="grey" outlined rounded id="skip-rest-btn">
                            <FontAwesomeIcon icon="step-forward"></FontAwesomeIcon>
                            {t('Skip rest')}
                        </Button>
                    </div>
                    {logCard && logCard({ status })}
                    <Card className="workout-log rest">
                        <div className="wol-exercise-container display-flex">
                            <div className="wol-exercise-title">{t('Coming up')}</div>
                        </div>
                        <div className="ex-vid-container">
                            <ExerciseVideo exercise={nextExercise} fallbackToYT autoplay />
                        </div>
                        <div className="text-center">
                        <div className="wo-wt-ex-title rest">{nextExercise.name}</div>
                            <div className="wo-wt-ex-subtitle rest">{nextGoal}</div>
                        </div>
                    </Card>
                    {nextExercise.isBarbell() && (<div className="mt20 underline">
                        <Link className="buttonize-no-color font-grey" to={plateCalculatorModalPathFor(basePath,nextSet.exerciseSpecificationId)}>{t("Plate Calculator")}</Link>
                    </div>)}
                </div>
            )
        }}/>

    )
}

const RestScreenWrapper = ({ exerciseSet, basePath }) => {
    const { t } = useTranslation();
    const nextSet = exerciseSet.nextSet();
    const [ autoAdvance, setAutoAdvance ] = useState(true);
    const logField = exerciseSet.restLogField();
    const logCard = logField && (({ status }) => {
        return (
            <RestLogCard 
                exerciseSet={exerciseSet} 
                setAutoAdvance={setAutoAdvance} 
                t={t} 
                field={logField}  
                timerStatus={status}
            />
        )
    });

    if(nextSet) {
        return (
            <RestScreen 
                time={exerciseSet.restTime} 
                nextPath={exerciseSet.nextPath()} 
                autoAdvance={autoAdvance}  
                nextExercise={nextSet.exercise()}
                nextSet={nextSet}
                nextGoal={`${nextSet.goal(t)}${nextSet.isWeighted() ? ` @${nextSet.unitWeight()}${nextSet.weightSuffix(t)}` : ''}`}
                logCard={logCard}
                basePath={basePath}
            />
        )
    } else {
        return (<Redirect to={exerciseSet.nextPath()} />)
    }
}

const advanceSetCreator = (currentSet,updateSets,history,checkRest,callback) => (values) => {
    
    if(currentSet.workoutSection() === 'workout') {
        currentSet.update({ ...values, logType: 1 },true);
        updateSets(currentSet.workout(),[currentSet],true);
    }
    if(history) {
        history.push(currentSet.nextPath(checkRest));
    }
    if(callback) {
        callback();
    }
};

const ExerciseSetForm = ({ exerciseSet, render, initValOverrides, submit }) => {
    const { t } = useTranslation();
    const initVals = { ...exerciseSet.formValues(true), ...(initValOverrides || {}) };


    return (
        <Formik
            initialValues={ _.parseObjForForm({ ...initVals }) }
            onSubmit={submit}
            validate={values => exerciseSet.update(values,true,t)}
            initialErrors={{}}
        >
            {(formikProps) => render(formikProps)}
        </Formik>
    )
}

let ExerciseSetFormWrapper = ({ updateSets, advanceOnSubmit, checkRest, callback, exerciseSet, ...rest }) => {
    let history = useHistory();
    const submit = advanceSetCreator(exerciseSet,updateSets,advanceOnSubmit ? history : null,checkRest,callback)

    return (
        <ExerciseSetForm {...rest} exerciseSet={exerciseSet} submit={submit} />
    )
}

ExerciseSetFormWrapper = connect(null,mapDispatchToUpdateSets)(ExerciseSetFormWrapper);

const ExerciseVideoAndInfo = ({ basePath, allowEditing, spec, showTag, isInitial }) => {
    const { t } = useTranslation();
    const exercise = spec.exercise;

    return (
        <React.Fragment>
            <div className="ex-vid-container">
                <ExerciseVideo exercise={exercise} fallbackToYT autoplay />
            </div>
            <div className="text-right">
                {showTag && (<div className="wo-wt-ex-tab">{spec.exercisable().fullName(t)}</div>)}
                {!spec.isVideoOnly() && (<ExerciseActionMenu 
                    spec={spec} 
                    basePath={basePath} 
                    allowEditing={allowEditing}
                    isInitial={isInitial}
                />)}
            </div>
            <div className="clearfix"></div>
        </React.Fragment>
    )
}

const ExerciseVideoAndInfoWrapper = ({ exerciseSet, setIndex, showTag, isInitial, suffix }) => {
    const allowEditing = (exerciseSet.workoutSection() === 'workout');

    return (
        <ExerciseVideoAndInfo 
            basePath={workoutDoPathFor(exerciseSet.date(),setIndex,suffix)} 
            showTag={showTag} 
            allowEditing={allowEditing}
            spec={exerciseSet.exerciseSpecification}
            isInitial={isInitial}
        />
    )
}

let ExerciseSetCore = ({ exerciseSet, setIndex, strengthTestSpecIds }) => {

    const { t } = useTranslation();
    const hadStrengthTest = strengthTestSpecIds && strengthTestSpecIds.includes(exerciseSet.exerciseSpecification.id);
    const instructions = exerciseSet.setInstructions(t);

    return (
        <React.Fragment>
            <ExerciseVideoAndInfoWrapper exerciseSet={exerciseSet} setIndex={setIndex} suffix='' />
            <div className="wo-wt-details-wrapper">
                <div className="wo-wt-right-icon"></div>
                <div>
                    <div className="wo-wt-ex-title">{exerciseSet.exercise().name}</div>
                    {exerciseSet.canHaveMultipleSets() && (<div className="wo-wt-subtitle">{exerciseSet.setIndexStr(t)}</div>)}
                    {instructions && <InstructionBox 
                        preInstructions={hadStrengthTest && t("Since you already did the strength test, feel free to skip some sets")}
                        title={`${t("Instructions")}:`} 
                        instructions={instructions} 
                        exerciseSet={exerciseSet} 
                        id={`set-instructions-field`}
                    />}
                    <ExerciseSetFormWrapper exerciseSet={exerciseSet} advanceOnSubmit checkRest defaultReps render={(formikProps) => {
                        return (
                            <ExerciseSetFields exerciseSet={exerciseSet} formikProps={formikProps} />
                        )
                    }} />
                </div>
            </div>
        </React.Fragment>
    )
}

const mapStateToStrengthTestSpecIds = (state,props) => {
    const selector = getStrengthTestSpecIdsSelector(props.exerciseSet.date());
    return {
        strengthTestSpecIds: selector(state)
    }
}

ExerciseSetCore = connect(mapStateToStrengthTestSpecIds)(ExerciseSetCore);

export function buildTransitionMap(basePath,defaultRules,curPathSlug,pathSlugs) {
    let rules = defaultRules || [];
    const page = pathSlugs.indexOf(curPathSlug);
    const pageCnt = pathSlugs.length;

    if(page >= 1) {
        let prevPagesStr = `${basePath}/(`;
        let curPage = 0;
        while(curPage < page) {
            if(curPage !== 0) {
                prevPagesStr += '|';
            }
            prevPagesStr += pathSlugs[curPage];
            curPage++;
        }
        prevPagesStr += `)/*`;
        rules.push([new RegExp(prevPagesStr),transitions.flowFormOut])
    }

    let nextPagesStr = `${basePath}/(`;
    let curPage = page+1;
    while(curPage < pageCnt) {
        if(curPage !== page+1) {
            nextPagesStr += '|';
        }
        nextPagesStr += pathSlugs[curPage];
        curPage++;
    }
    nextPagesStr += `)/*`;
    rules.push([new RegExp(nextPagesStr),transitions.flowFormIn])
    
    return {
        rules
    };
}

class ExerciseSetWrapper extends React.Component {

    constructor(props) {
        super(props);
        const { setIndex, basePath, t, setTitle, exerciseSet, suffix, pathMatch } = props;
        let rules = defaultRules;

        const suffixMatcher = _.isBlank(suffix) ? '/:suffix?' : ':suffix?'
        const curPath = pathMatch.replace(suffixMatcher,suffix);
        const superset = exerciseSet.supersetArr();
        const orderedPaths = superset.orderedPaths();

        const curIndex = orderedPaths.indexOf(curPath) || 0;
        const prevMatches = orderedPaths.slice(0,curIndex);

        const afterMatches = orderedPaths.slice(curIndex+1,orderedPaths.length);

        rules = _.concat(rules,[[prevMatches,transitions.flowFormOut],[afterMatches,transitions.flowFormIn]]);
        const tmap = buildTransitionMap(basePath,rules,setIndex,exerciseSet.resolvedWorkout().orderedIndices());
        props.setupTransitions(tmap);
        setTitle(t(_.upperFirst(exerciseSet.workoutSection())));
    }

    render() {
        const { exerciseSet, setIndex, suffix } = this.props;
        const spec = exerciseSet.exerciseSpecification;
        let Component = ExerciseSetCore;

        if(suffix === 'superset_preview') {
            Component = SupersetPreview;
        } else if(suffix === 'rest') {
            Component = RestScreenWrapper;
        } else if(suffix === 'initial') {
            Component = InitializeScreen;
        }

        const basePath = workoutDoPathFor(exerciseSet.date(),setIndex,(suffix === 'exercise_info' || suffix === 'weight_calculator') ? '' : suffix);
        const weightCalcBaseMatch = workoutDoPathFor(':date',setIndex,':suffix?')

        const infoModal = (<ExerciseInfoModal basePath={basePath} baseMatch={basePath} />)


        if(spec.isGeneric()) {
            return (<PickSpecificExercise spec={spec} infoModal={infoModal} />)
        } else {
            return (
                <div className="text-center">
                    <div className="set-container pb10">
                        <Component exerciseSet={exerciseSet} setIndex={setIndex} basePath={basePath} />
                    </div>
                    {infoModal}
                    <WeightCalculatorModal basePath={basePath} baseMatch={weightCalcBaseMatch} date={spec.date()} />
                    <ExerciseSettingsModal basePath={basePath} baseMatch={weightCalcBaseMatch} />
                    <PlateCalculatorModal basePath={basePath} baseMatch={weightCalcBaseMatch} />
                    {_.isBlank(suffix) && exerciseSet.needsInitialization() && (<Redirect to={exerciseSet.pathFor('initial')} />)}
                    {suffix === 'initial' && !exerciseSet.needsInitialization() && !exerciseSet.hasInitDoneScreen() && (<Redirect to={exerciseSet.pathFor()} />)}
                </div>
            )
        }

    }
}

const resetPromptTransitions = {
    rules: [
        [workoutDoMatch,transitions.none]
    ]
}

const ResetButtons = ({ workout, t, load }) => {
    const [doRedirect,setDoRedirect] = useState(false);

    return (
        <React.Fragment>
            {doRedirect && (<Redirect to={workout.initialPath()} />)}
            {!doRedirect && (<Button 
                id='resume-workout-btn'
                rounded 
                color="primary" 
                outlined 
                noShadow 
                to={workout.initialPath()} 
                onClick={() => setDoRedirect(true)}>
                    <FontAwesomeIcon icon="play"></FontAwesomeIcon> {t("Resume")}
            </Button>)}
            {!doRedirect && (<Button id="restart-workout-btn" rounded color="primary" outlined noShadow className="ml10" onClick={load}>
                <FontAwesomeIcon icon="undo"></FontAwesomeIcon> {t("Restart")}
            </Button>)}
        </React.Fragment>
    )
}

const ResetButtonsLC = loadingContainer({
    "DEFAULT": ResetButtons,
    "SUCCESS": ({ workout }) => (<Redirect to={workout.initialPath()} />)
})

class ResetWorkoutPrompt extends React.Component {

    constructor(props) {
        super(props);
        props.setupTransitions(resetPromptTransitions);
    }

    render() {
        const { t, workout, restartWorkout } = this.props;

        return (
            <div className="container">
                <div className="row">
                <div className="col s12 m10 offset-m1 l8 offset-l2">
                    <Card className="mt70">
                        <CardContent>
                            <div className="left-align-block">
                                <FontAwesomeIcon icon="info-circle" className="info-color"></FontAwesomeIcon> {t("restart workout prompt")}
                            </div>
                        </CardContent>
                        <div className="pa20 text-center">
                            <LoadingContainerParent 
                                skipAutoLoad
                                preloaded={() => false}
                                load={restartWorkout.bind(null,workout.date)}
                                workout={workout}
                                t={t}
                                component={ResetButtonsLC}
                            />
                        </div>
                    </Card>
                </div>
                </div>
            </div>
        )
    }

    componentWillUnmount() {
        const { setStartedAt, workout } = this.props;
        if(!workout.initialPath().includes('done')) {
            setStartedAt(workout.startedAt);
        }
    }

}

const WorkoutsLoggedThisWeek = ({ responseData }) => {
    const { t } = useTranslation();

    return (
        <div className="complete-spacer">
            <div className="complete-stats" id="workouts-complete-stats">{responseData.logged}/{responseData.unlogged}</div>
            <div className="complete-stats-subtitle">{t('Weekly Goal')}</div>
        </div>
    )
}

const WorkoutsLoggedLC = loadingContainer({
    'SUCCESS': WorkoutsLoggedThisWeek,
    ...blankDefaultMap
})

export const BackToHomeButton = ({ t, closeAndGo }) => {
    const history = useHistory();

    return (
        <div className="wo-wt-continue-btn extra-margin-top half">
            <Button rounded color="primary" noShadow={!!closeAndGo} onClick={() => (closeAndGo ? closeAndGo(resolvedHomePath()) : history.push(resolvedHomePath()))}>
                <FontAwesomeIcon icon="home"></FontAwesomeIcon> {t("Back to Home")}
            </Button>
        </div>
    )
}

export const WorkoutDoneHeader = ({ workout }) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <div className={classnames("complete-top")}>{t('And...done!')}</div>
            <div className={classnames("complete-middle")} style={{ textTransform: 'uppercase' }}>{t('Workout complete')}</div>
        </React.Fragment>
    )
}
class WorkoutDone extends React.Component {

    constructor(props) {
        super(props);
        const { updateWorkout, workout } = this.props;
        const nowStr = moment.utc().toISOString()
        if(workout) {
            let endStr = nowStr;
            if(!_.isBlank(workout.finishedAt)) {
                endStr = workout.finishedAt;
            } else {
                updateWorkout(workout, { finishedAt: endStr })
            }
        }
    }

    componentDidMount() {
        const { requestRating } = this.props;
        this.ratingRequestTimeout = setTimeout(requestRating,1000);
    }

    render() {
        const { t, workout, getWorkoutsLoggedThisWeek, closeAndGo, updateSpec, updateWorkout, isModal } = this.props;
        const duration = workout.durationStr();
        const specHandlerCreator = spec => rating => () => {
            updateSpec(spec,{ difficultyRating: rating });
        }
        const workoutHandlerCreator = rating => () => {
            updateWorkout(workout,{ difficultyRating: rating });
        }
        const rateable = workout.rateableExerciseSpecs();

        return (
            <React.Fragment>
                {!isModal && <WorkoutDoneHeader workout={workout} />}
                {workout.user.isClient() && (
                    <div className="inline-block compact-rating-btns">
                        <div className="font-grey">{t('How difficult was it overall?')}</div>
                        <RatingButtons easyLabel={t('Very Easy')} hardLabel={t('Very Hard')} labelPos="top" handlerCreator={workoutHandlerCreator} activeRating={workout.difficultyRating} />
                        {rateable.length > 1 && rateable.map(spec => {

                            return (
                                <div>
                                    <div className="prompt">{spec.exerciseName()}?</div>
                                    <RatingButtons labelPos="top" handlerCreator={specHandlerCreator(spec)} activeRating={spec.difficultyRating} />
                                </div>
                            )
                        })}
                    </div>
                )}
                {!workout.user.isClient() && (
                    <React.Fragment>
                        <svg width="0" height="0">
                            <linearGradient id="medal-gradient">
                                <stop stopColor="#FA932D" offset="0%" />
                                <stop stopColor="#FFC22E" offset="100%" />
                            </linearGradient>
                        </svg>
                        <div><FontAwesomeIcon icon="medal" className="medal-gradient"/></div>
                        <div className="complete-container">
                            {duration && (<div className="complete-spacer">
                            <div className="complete-stats">{duration}</div>
                                <div className="complete-stats-subtitle">{t('Duration')}</div>
                            </div>)}
                            <LoadingContainerParent 
                                load={getWorkoutsLoggedThisWeek.bind(null,workout.date)}
                                preloaded={() => false}
                                component={WorkoutsLoggedLC}
                            />
                        </div>
                    </React.Fragment>
                )}
                {!isModal && (<BackToHomeButton t={t} closeAndGo={closeAndGo} />)}
            </React.Fragment>
        )
    }

    componentWillUnmount() {
        if(this.ratingRequestTimeout) {
            clearTimeout(this.ratingRequestTimeout);
        }
    }

}

WorkoutDone = connect(null,mapDispatchToUpdateSpec)(WorkoutDone)

export { WorkoutDone }
class WorkoutDoneContainer extends React.Component {

    constructor(props) {
        super(props);
        const { setStartedAt, setTitle } = this.props;
        setStartedAt(null);
        setTitle('');
    }

    render() {
        const { setStartedAt, setTitle, ...rest } = this.props;

        return (
            <div className="text-center">
                <div className="set-container pb10" style={{width: '480px'}}>
                    <WorkoutDone {...rest} />
                </div>
            </div>
        )
    }

}

const skipWarmupCreator = (workout,section,updateWorkout,history) => () => {
    let updates;
    if(section === 'warmup') {
        workout.warmedUp = true;
        updates = { warmedUp: true };
        updateWorkout(workout,updates);
        history.push(workout.initialPath())
    } else {
        workout.cooledDown = true;
        updates = { cooledDown: true };
        updateWorkout(workout,updates);
        history.push(workout.donePath())
    }
    
}

const SkipWarmupOverlay = ({workout, section, history, t}) => {
    const [hidden,setHidden] = useState(false);

    if(hidden) {
        return '';
    } else {
        return (
            <div className="transparent-bottom wod-btns">
                <div className="upper-right-btn low-opacity-icon" onClick={() => setHidden(true)}>
                    <FontAwesomeIcon icon={'times'} /> 
                </div>
                <Button rounded color="primary" id="skip-warmup-btn" outlined noShadow onClick={skipWarmupCreator(workout,section,updateWorkout,history)}>
                    <FontAwesomeIcon icon={'arrow-right'}></FontAwesomeIcon> {t(`Skip ${section}`)}
                </Button>
            </div>
        )    
    }
}

class WorkoutDoSuccess extends React.Component {
    
    constructor(props) {
        super(props);
        const { startWorkout, date, workout, updateWorkout, setStartedAt } = this.props;
        startWorkout(date.format(dateFormat));
        const nowStr = moment.utc().toISOString()
        if(workout) {
            let startStr = nowStr;
            if(workout.alreadyStarted() && !_.isBlank(workout.startedAt)) {
                startStr = workout.startedAt;
            } else {
                updateWorkout(workout, { startedAt: nowStr })
            }
            setStartedAt(startStr);
        }
    }
    
    render() {
        const {workout, location, history, t, restartWorkout, setIndex, setTitle, setStartedAt, updateWorkout, getWorkoutsLoggedThisWeek } = this.props;

        if(workout) {
            const basePath = workoutDoBaseFor(workout.date);
            const section = workout.currentSection(setIndex);
            const orderedSets = workout.orderedExerciseSets();
            const indices = workout.orderedIndices();
            const resolvedSet = workout.setByIndex(setIndex);
            
            return (
                <div>
                    {setIndex !== 'reset' && !indices.includes(setIndex) && resolvedSet && (<Redirect to={location.pathname.replace(setIndex,resolvedSet.workoutSetIndex())} />)}
                    <RouteTransitionMap location={location} canUpdate noOverflow>
                        <ResetWorkoutPrompt 
                            path={workoutResetPathFor(workout.date)} 
                            t={t} 
                            workout={workout} 
                            restartWorkout={restartWorkout}
                            setStartedAt={setStartedAt}
                        />
                        {_.flatMap(['rest','superset_preview','initial',''],(suffix) => (
                            orderedSets.map((exerciseSet) => {
                                const setWorkoutIndex = exerciseSet.workoutSetIndex();
                                return (
                                    <ExerciseSetWrapper 
                                        key={setWorkoutIndex}
                                        suffix={suffix}
                                        path={exerciseSet.pathFor(suffix)}
                                        pathMatch={workoutDoMatchFor(workout.date,setWorkoutIndex)}
                                        basePath={basePath}
                                        setIndex={setWorkoutIndex}
                                        exerciseSet={exerciseSet} 
                                        setTitle={setTitle}
                                        t={t}
                                    />
                                )
                            })
                        ))}
                        <RatingPrompt path={workout.donePath()} basePath={workout.donePath()} context="workoutComplete" render={({ requestRating }) => (
                            <WorkoutDoneContainer
                                t={t} 
                                workout={workout} 
                                setTitle={setTitle}
                                setStartedAt={setStartedAt}
                                updateWorkout={updateWorkout}
                                getWorkoutsLoggedThisWeek={getWorkoutsLoggedThisWeek}
                                requestRating={requestRating}
                            />
                        )} />
                    </RouteTransitionMap>
                    {(section === 'warmup' || section === 'cooldown') && (
                        <SkipWarmupOverlay workout={workout} section={section} history={history} t={t} />
                    )}
                    <div className="progress progress-bar-positioning wo-wt fixed-progress big">
                        <div className="determinate" style={{ width: `${workout.percentDone()}%`}}></div>
                    </div>
                    <SignupModal basePath={workoutDoMatch} />
                </div>
            )
        } else {
            return (<Redirect to={resolvedHomePath()} />)
        }
    }
}

const mapDispatchToRestartWorkout = dispatch => ({
    restartWorkout: date => dispatch(restartWorkout(date)),
    updateWorkout: (workout,updates) => dispatch(updateWorkout(workout,updates)),
    getWorkoutsLoggedThisWeek: (date) => dispatch(workoutsLoggedThisWeek(date))
})

const WithWorkout = withWorkout('dirty','page',true)(connect(null,mapDispatchToRestartWorkout)(WorkoutDoSuccess))


const transitionMap = {
    rules: [
        [[ ...workoutPlanSlideOutPaths, workoutLogMatch, workoutPreviewMatch ] ,transitions.slideOut],
        [[progressionPickMatch,swapExerciseSpecMatch, strengthTestMatch, progressionTestMatch, ...emailPaywallMatches], transitions.slideOver]
    ]
};

class WorkoutDoPage extends React.Component {

    constructor(props) {
        super(props);
        props.setupTransitions(transitionMap);
        const { t } = this.props;
        this.state = { title: t("Workout"), startedAt: null };
        this.setTitle = this.setTitle.bind(this);
        this.setStartedAt = this.setStartedAt.bind(this);
    }

    render() {
        const { t, location, history, startWorkout, match: { params: { date, setIndex } }, scrollRef } = this.props;
        const { startedAt } = this.state;

        return (
            <Page ref={scrollRef}>
                <SimpleNav shadow>
                    <SimpleNavTitle>
                        {this.state.title}
                        {startedAt && (<CountupTimer autoStart key={startedAt} startedAt={startedAt} render={({ display }) => (` - ${display}`)} />)}
                    </SimpleNavTitle>
                    <Dropdown 
                        contentComp='ul'
                        options={{constrainWidth: false, alignment: 'right', coverTrigger: false}}
                        triggerRender={({ ref, target }) => {
                            return (
                                <SimpleNavRightButton ref={ref} data-target={target} id="workout-menu-btn" icon="ellipsis-v" className="pr20" />
                            )
                        }}
                        contentRender={({ recalcDims }) => {
                            return (
                                <React.Fragment>
                                    <MuteTimerButton render={({ soundOn, muteTimers }) => {
                                        if(soundOn) {
                                            return (<li onClick={() => muteTimers(1)} id="mute-timers-btn">
                                                <span>
                                                    <FontAwesomeIcon icon="volume-mute" /> {t("Mute timers")}
                                                </span>
                                            </li>)
                                        } else {
                                            return (<li onClick={() => muteTimers(0)} id="unmute-timers-btn">
                                                <span>
                                                    <FontAwesomeIcon icon="volume-up" /> {t("Unmute timers")}
                                                </span>
                                            </li>)
                                        }
                                    }} />

                                    <li>
                                        <Link to={resolvedHomePath()} id={`exit-workout-btn`}>
                                            <FontAwesomeIcon icon="times" /> {t("Exit workout")}
                                        </Link>
                                    </li>
                                </React.Fragment>
                            )
                        }}
                    />
                </SimpleNav>
                <SimpleNavContainer className="pb70">
                    <WithWorkout 
                        date={moment(date)} 
                        location={location} 
                        history={history} 
                        t={t} 
                        startWorkout={startWorkout} 
                        setIndex={setIndex} 
                        setTitle={this.setTitle}
                        setStartedAt={this.setStartedAt}
                    />
                </SimpleNavContainer>
            </Page>
        )
    }

    setTitle(newTitle) {
        this.setState({title: newTitle});
    }

    setStartedAt(startedAt) {
        this.setState({startedAt: startedAt});
    }
}

const mapDispatchToStartWorkout = dispatch => ({
    startWorkout: (date) => dispatch(standaloneStartWorkout(date))
})

export default withTranslation()(connect(null,mapDispatchToStartWorkout)(WorkoutDoPage));