import StartScreen from 'views/StartScreen';
import SignupFlowPage, { SignupGoalPage, SignupEmailPage, QuickstartInit, Quickstart, TrainerInvitePage } from 'views/SignUp';
import WorkoutSetupFlowPage from 'views/WorkoutSetupFlow';
import SignIn from 'views/SignIn';
import Main from 'views/Main';
import { rootPath, startPath, startFlow, 
        workoutSetupMatch,
        conversionMatch, 
        mealPlanSetupMatch,
        paymentModalMatch, userMealMatch, workoutPreviewMatch, 
        editRoutinePath, workoutLogMatch, 
        progressionPickMatch, swapExerciseSpecMatch, 
        editWorkoutTemplateMatch, workoutDoMatch, 
        progressionShowMatch, progressionTestMatch, 
        strengthTestMatch, 
        editRoutineStandaloneMatch,
        editExerciseGroupMatch,
        swapExerciseTemplateMatch,
        addExercisesMatch,
        addExercisesToGroupMatch,
        pickExercisePath,
        macroAnalyticsPath,
        bodyMeasurementsPath,
        quickstartInitPath,
        quickstartMatch,
        mealPlanInitPath,
        mealPlanSignupMatch,
        emailSignupMatch,
        loginMatch,
        strengthBodyStatsPath,
        contactPath,
        mainRouteMatches,
        notificationsPath,
        accountPath,
        assignRoutinePath,
        errorLogMatch,
        generateNewWeekMatch,
        settingsMatch,
        regenerateWeekMatch,
        offPlanMealsSetupMatch,
        addPeopleToMealsMatch,
        editWeeklyMealMatch,
        swapMealMatch, 
        recipeBrowserPath, 
        mealBrowserPath, 
        editTempMealPath, 
        swapRecipeMatch, 
        addRecipeMatch, 
        swapTempRecipeMatch, 
        addTempRecipeMatch, 
        wimageCatsPath, 
        wimageCatMatch, 
        viewTempMealMatch, 
        viewTempRecipeReplacementMatch,
        viewRecipeReplacementMatch, 
        viewMealPathMatch, 
        editRecipeMatch,
        addIngredientsPath,
        switchCliAccountMatch,
        trainerMainMatches,
        assignClientRoutineMatch,
        editRoutineShortcutMatch,
        assignRoutineToClientsMatch,
        inviteMatch,
        clientBasicProfileMatch,
        clientDetailsMatch,
        clientMealPlanSettingsMatch,
        trainerSignupMatch,
        trainerConversionMatch,
        switchAccountMatch,
        pastDuePath,
        standaloneMealPlanMatch,
        switchForGenerateMatch,
        standaloneGlistMatch, 
        standaloneWorkoutPlanMatch,
        workoutPreviewShortcutMatch,
        clientProgressShortcutMatch,
        clientMacroAnalyticsShortcutMatch,
        viewLogsPath,
        viewLogsShortcutMatch,
        trainerHabitsMatch,
        assignClientHabitsMatch,
        clientBmShortcutMatch,
        assignHabitToClientsMatch,
        trainerMealPlannerInitPath,
        trainerBasicProfileMatch,
        workoutRoutinesInitPath,
        helpPath,
        getAppPath,
        trainerBrandingMatch,
        cancelTsubPath,
        viewChatMatch,
        clientMessagingMatch,
        viewInvoicesPath,
        brandedAppSetupPath,
        brandedAppsPath,
        logActivityMatch,
        clientMealPlansMatch,
        viewSharedMealsMatch,
        pdfSetupDonePath,
        switchUserAdminMatch,
        trainerFormsPath,
        editTrainerFormMatch,
        addAssessmentsToFormMatch,
        addExercisesToFormMatch,
        assignFormsToClientsMatch,
        viewClientAssignedFormMatch,
        viewAssignedFormMatch,
        strtstFormMatch,
        clientProgressPicsShortcutMatch,
        progressPicsPath,
        progressMatch,
        nutritionDataInfoPath,
        onboardMatch,
        saleRedirectorMatch,
        anatomicGifGenPath,
        workoutOnboardMatch
    } from 'config/paths';
import ConversionPage from 'views/Conversion';
import MealPlanSetupFlow, { MealPlanInit, MealPlanSignup, GenerateNewWeek, RecipePickClientSwitcher } from 'views/MealPlanSetupFlow';
import UserMealPage from 'views/ViewUserMeal';
import WorkoutPreviewPage, { WorkoutPreviewShortcut } from 'views/WorkoutPreview';
import EditRoutinePage, { EditRoutineShortcut, EditRoutineStandalonePage } from 'views/EditRoutine';
import WorkoutLogPage from 'views/WorkoutLog';
import ProgressionPick, { ProgressionShowPage } from 'views/ProgressionPick';
import SwapExerciseSpec from 'views/SwapExerciseSpec';
import EditWorkoutTemplate from 'views/EditWorkoutTemplate';
import WorkoutDo from 'views/WorkoutDo';
import ProgressionTest from 'views/ProgressionTest';
import StrengthTest, { StrengthTestFormPage } from 'views/StrengthTest';
import SwapExerciseTemplate from 'views/SwapExerciseTemplate';
import AddExercises from 'views/AddExercises';
import PickLoggedExercise from 'views/PickLoggedExercise';
import Progress, { ClientProgressShortcut } from 'views/Progress';
import Settings from 'views/Settings';
import MacroAnalytics, { ClientMacroAnalyticsShortcut } from 'views/MacroAnalytics';
import BodyMeasurements, { ClientBmShortcut } from 'views/BodyMeasurements';
import StrengthAndBodyStatsPage from 'views/StrengthAndBodyStats';
import ContactPage from 'views/Contact';
import Notifications from 'views/Notifications';
import Account, { PastDuePage } from 'views/Account';
import { AssignRoutine, StandaloneWorkoutPlanPage } from 'views/WorkoutPlan';
import ShowErrorLog from 'views/ShowErrorLog';
import RegenerateMealPlanPage from 'views/RegenerateMealPlan';
import OffPlanMeals from 'views/OffPlanMeals';
import AddPeopleToMeals from 'views/AddPeopleToMeals';
import EditWeeklyMeal from 'views/EditWeeklyMeal';
import FindMealReplacement from 'views/FindMealReplacement';
import { MealBrowserPage, RecipeBrowserPage } from 'views/RecipeBrowser';
import EditTempMeal from 'views/EditTempMeal';
import FindRecipeReplacement from 'views/FindRecipeReplacement';
import FindTempRecipeReplacement from 'views/FindTempRecipeReplacement';
import WorkoutImageCats from 'views/WorkoutImageCats';
import WorkoutImageCat from 'views/WorkoutImageCat';
import { ViewBrowserRecipe, ViewRecipeReplacement, ViewTempMeal, ViewTempRecipeReplacement } from 'views/ViewMeal';
import RecipeEditor, { AddIngredientPage } from 'views/RecipeEditor';
import SwitchAccountLoader from 'views/SwitchAccountLoader';
import TrainerMain from 'views/TrainerMain';
import AssignClientRoutine, { AssignRoutineToClients } from 'views/AssignClientRoutine';
import ClientBasicProfile from 'views/ClientBasicProfile';
import ClientDetails from 'views/ClientDetails';
import ClientMealPlanSettings from 'views/ClientMealPlanSettings';
import TrainerSignup, { TrainerMpInit } from 'views/TrainerSignup';
import TrainerConversionPage from 'views/TrainerConversion';
import { StandaloneMealPlanPage } from 'views/MealPlan';
import { StandaloneGroceryListPage } from 'views/GroceryList';
import ViewLogs, { ViewLogsShortcut } from 'views/ViewLogs';
import Habits from 'views/Habits';
import AssignHabits, { AssignHabit } from 'views/AssignHabits';
import { WorkoutRoutinesInit } from 'views/WorkoutRoutines';
import HelpPage from 'views/Help';
import GetTheApp from 'views/GetTheApp';
import TrainerBrandingFlow from 'views/TrainerBrandingFlow';
import TrainerCancel from 'views/TrainerCancel';
import ChatPage from 'views/ChatPage';
import ClientMessaging from 'views/ClientMessaging';
import TrainerInvoices from 'views/TrainerInvoices';
import BrandedAppSetup from 'views/BrandedAppSetup';
import BrandedApps from 'views/BrandedApps';
import LogActivity from 'views/LogActivity';
import ClientMealPlans from 'views/ClientMealPlans';
import ViewSharedMeals from 'views/ViewSharedMeals';
import PDFSetupDone from 'views/PdfSetupDone';
import FormsIndex from 'views/FormsIndex';
import EditTrainerForm from 'views/EditTrainerForm';
import AddAssessmentsIndex from 'views/AddAssessmentsIndex';
import AddExercisesToForm from 'views/AddExercisesToForm';
import AssignForms from 'views/AssignForms';
import { ClientViewAssignedForm, TrainerViewAssignedForm } from 'views/ViewAssignedForm';
import ProgressPhotos, { ClientProgressPicsShortcut } from 'views/ProgressPhotos';
import NutritionInfo from 'views/NutritionInfo';
import OnboardingFlow from 'views/OnboardingFlow';
import SaleRedirector from 'views/SaleRedirector';
import AnatomicGifGenPage from 'views/AnatomicGifGen';
import WorkoutsOnboarding from 'views/WorkoutsOnboarding';

export const topLevelDomains = [
    { exact: true, path: rootPath, component: StartScreen },
    { path: startPath, component: SignupGoalPage },
    { path: startFlow, component: SignupFlowPage },
    { path: onboardMatch, component: OnboardingFlow },
    { path: emailSignupMatch, component: SignupEmailPage },
    { path: inviteMatch, component: TrainerInvitePage },
    { path: loginMatch, component: SignIn },
    { path: switchAccountMatch, component: SignIn },
    { path: mainRouteMatches, component: Main, canUpdate: true },
    { path: trainerMainMatches, component: TrainerMain, canUpdate: true },
    { path: workoutSetupMatch, component: WorkoutSetupFlowPage },
    { path: mealPlanSetupMatch, component: MealPlanSetupFlow },
    { path: [conversionMatch,paymentModalMatch], component: ConversionPage },
    { path: workoutPreviewMatch, component: WorkoutPreviewPage },
    { path: editRoutinePath, component: EditRoutinePage },
    { path: editRoutineStandaloneMatch, component: EditRoutineStandalonePage },
    { path: workoutLogMatch, component: WorkoutLogPage },
    { path: progressionPickMatch, component: ProgressionPick },
    { path: progressionShowMatch, component: ProgressionShowPage },
    { path: swapExerciseSpecMatch, component: SwapExerciseSpec },
    { path: swapExerciseTemplateMatch, component: SwapExerciseTemplate },
    { path: addExercisesMatch, component: AddExercises },
    { path: addExercisesToGroupMatch, component: AddExercises },
    { path: editWorkoutTemplateMatch, component: EditWorkoutTemplate, canUpdate: true },
    { path: editExerciseGroupMatch, component: EditWorkoutTemplate },
    { path: workoutDoMatch, component: WorkoutDo, canUpdate: true },
    { path: progressionTestMatch, component: ProgressionTest },
    { path: strengthTestMatch, component: StrengthTest, canUpdate: true },
    { path: pickExercisePath, component: PickLoggedExercise, canUpdate: true },
    { path: progressMatch, component: Progress, canUpdate: true },
    { path: settingsMatch, component: Settings, canUpdate: true },
    { path: macroAnalyticsPath, component: MacroAnalytics, canUpdate: true },
    { path: bodyMeasurementsPath, component: BodyMeasurements, canUpdate: true },
    { path: quickstartInitPath, component: QuickstartInit },
    { path: quickstartMatch, component: Quickstart, canUpdate: true },
    { path: mealPlanInitPath, component: MealPlanInit },
    { path: mealPlanSignupMatch, component: MealPlanSignup, canUpdate: true },
    { path: assignRoutinePath, component: AssignRoutine},
    { path: strengthBodyStatsPath, component: StrengthAndBodyStatsPage, canUpdate: true },
    { path: contactPath, component: ContactPage, canUpdate: true },
    { path: notificationsPath, component: Notifications },
    { path: accountPath, component: Account },
    { path: pastDuePath, component: PastDuePage },
    { path: errorLogMatch, component: ShowErrorLog, canUpdate: true },
    { path: generateNewWeekMatch, component: GenerateNewWeek, canUpdate: true },
    { path: regenerateWeekMatch, component: RegenerateMealPlanPage },
    { path: offPlanMealsSetupMatch, component: OffPlanMeals },
    { path: addPeopleToMealsMatch, component: AddPeopleToMeals },
    { path: editWeeklyMealMatch, component: EditWeeklyMeal, canUpdate: true },
    { path: swapMealMatch, component: FindMealReplacement, canUpdate: true },
    { path: recipeBrowserPath, component: RecipeBrowserPage, canUpdate: true },
    { path: mealBrowserPath, component: MealBrowserPage, canUpdate: true },
    { path: editTempMealPath, component: EditTempMeal, canUpdate: true },
    { path: swapRecipeMatch, component: FindRecipeReplacement },
    { path: addRecipeMatch, component: FindRecipeReplacement },
    { path: swapTempRecipeMatch, component: FindTempRecipeReplacement },
    { path: addTempRecipeMatch, component: FindTempRecipeReplacement },
    { path: wimageCatsPath, component: WorkoutImageCats, exact: true },
    { path: wimageCatMatch, component: WorkoutImageCat },
    { path: userMealMatch, component: UserMealPage},
    { path: viewRecipeReplacementMatch, component: ViewRecipeReplacement },
    { path: viewTempMealMatch, component: ViewTempMeal },
    { path: viewTempRecipeReplacementMatch, component: ViewTempRecipeReplacement },
    { path: viewMealPathMatch, component: ViewBrowserRecipe },
    { path: editRecipeMatch, component: RecipeEditor, canUpdate: true },
    { path: addIngredientsPath, component: AddIngredientPage, canUpdate: true },
    { path: switchCliAccountMatch, component: SwitchAccountLoader, canUpdate: true },
    { path: switchUserAdminMatch, component: SwitchAccountLoader, canUpdate: true },
    { path: assignClientRoutineMatch, component: AssignClientRoutine, canUpdate: true },
    { path: assignRoutineToClientsMatch, component: AssignRoutineToClients, canUpdate: true },
    { path: editRoutineShortcutMatch, component: EditRoutineShortcut, canUpdate: true },
    { path: clientBasicProfileMatch, component: ClientBasicProfile, canUpdate: true },
    { path: clientDetailsMatch, component: ClientDetails, canUpdate: true },
    { path: clientMealPlanSettingsMatch, component: ClientMealPlanSettings, canUpdate: true },
    { path: trainerSignupMatch, component: TrainerSignup, canUpdate: true },
    { path: trainerBasicProfileMatch, component: TrainerSignup, canUpdate: true },
    { path: trainerConversionMatch, component: TrainerConversionPage, canUpdate: true },
    { path: standaloneMealPlanMatch, component: StandaloneMealPlanPage, canUpdate: true },
    { path: switchForGenerateMatch, component: RecipePickClientSwitcher, canUpdate: true },
    { path: standaloneGlistMatch, component: StandaloneGroceryListPage, canUpdate: true },
    { path: standaloneWorkoutPlanMatch, component: StandaloneWorkoutPlanPage, canUpdate: true },
    { path: workoutPreviewShortcutMatch, component: WorkoutPreviewShortcut, canUpdate: true },
    { path: clientProgressShortcutMatch, component: ClientProgressShortcut, canUpdate: true },
    { path: clientProgressPicsShortcutMatch, component: ClientProgressPicsShortcut, canUpdate: true },
    { path: clientBmShortcutMatch, component: ClientBmShortcut, canUpdate: true },
    { path: clientMacroAnalyticsShortcutMatch, component: ClientMacroAnalyticsShortcut, canUpdate: true },
    { path: viewLogsPath, component: ViewLogs, canUpdate: true },
    { path: viewLogsShortcutMatch, component: ViewLogsShortcut, canUpdate: true },
    { path: trainerHabitsMatch, component: Habits, canUpdate: true },
    { path: assignClientHabitsMatch, component: AssignHabits, canUpdate: true },
    { path: assignHabitToClientsMatch, component: AssignHabit, canUpdate: true },
    { path: trainerMealPlannerInitPath, component: TrainerMpInit, canUpdate: true },
    { path: workoutRoutinesInitPath, component: WorkoutRoutinesInit, canUpdate: true },
    { path: trainerBrandingMatch, component: TrainerBrandingFlow, canUpdate: true },
    { path: cancelTsubPath, component: TrainerCancel, canUpdate: true },
    { path: helpPath, component: HelpPage },
    { path: getAppPath, component: GetTheApp },
    { path: viewChatMatch, component: ChatPage, canUpdate: true },
    { path: clientMessagingMatch, component: ClientMessaging, canUpdate: true },
    { path: viewInvoicesPath, component: TrainerInvoices },
    { path: brandedAppSetupPath, component: BrandedAppSetup, canUpdate: true },
    { path: brandedAppsPath, component: BrandedApps, canUpdate: true },
    { path: logActivityMatch, component: LogActivity },
    { path: clientMealPlansMatch, component: ClientMealPlans },
    { path: viewSharedMealsMatch, component: ViewSharedMeals },
    { path: pdfSetupDonePath,  component: PDFSetupDone },
    { path: trainerFormsPath, component: FormsIndex, canUpdate: true },
    { path: editTrainerFormMatch, component: EditTrainerForm, canUpdate: true },
    { path: addAssessmentsToFormMatch, component: AddAssessmentsIndex, canUpdate: true },
    { path: addExercisesToFormMatch, component: AddExercisesToForm, canUpdate: true },
    { path: assignFormsToClientsMatch, component: AssignForms, canUpdate: true },
    { path: viewClientAssignedFormMatch, component: TrainerViewAssignedForm, canUpdate: true },
    { path: viewAssignedFormMatch, component: ClientViewAssignedForm, canUpdate: true },
    { path: strtstFormMatch, component: StrengthTestFormPage, canUpdate: true },
    { path: progressPicsPath, component: ProgressPhotos, canUpdate: true },
    { path: nutritionDataInfoPath, component: NutritionInfo },
    { path: saleRedirectorMatch, component: SaleRedirector },
    { path: anatomicGifGenPath, component: AnatomicGifGenPage },
    { path: workoutOnboardMatch, component: WorkoutsOnboarding }
];