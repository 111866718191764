import React from 'react';
import BottomNav, { BottomNavChild, SimpleActivityTabsFull, NonLinkActivityTab } from 'components/BottomNav';
import Page, { SimpleNavPage } from'components/Page';
import { matchPath } from 'react-router';
import { homePathProps, mealPlanPath, workoutPlanPath, workoutSetupMatch, mealPlanSetupMatch, userMealMatch, workoutPreviewMatch, editRoutinePath, workoutDoMatch, mainMenuMatches, settingsPath, progressPath, pickExercisePath, macroAnalyticsPath, bodyMeasurementsPath, mealPlanSignupMatch, homePath, webMealPlannerPath, webWorkoutSplitsPath, strengthBodyStatsPath, webMealPlansPath, webBlogPath, loginPath, contactPath, webLandingPath, ambiguousMainMatches, mainRouteMatches, notificationsPath, accountPath, webAdminDashPath, errorLogMatch, generateNewWeekMatch, settingsMatch, regenerateWeekMatch, offPlanMealsSetupMatch, addPeopleToMealsMatch, editWeeklyMealMatch, swapMealMatch, recipeBrowserPath, mealBrowserPath, groceryListPathFor, groceryListMatch, viewMealPathMatch, editRecipeMatch, mealPlanMatch, emailPaywallMatches, webAffiliateProgramPath, allMainMatches, trainerHomePath, trainerRecipesMatch, myRoutinesPath, trainerMessagingPath, trainerHabitsPath, trainersPath, trainerMainMatches, routinesBaseMatch, clientBasicProfileMatch, clientMealPlanSettingsMatch, clientDetailsMatch, trainerHomeMatch, trainerSignupMatch, mainProgressPath, trainerConversionPathFor, trainerMainMenuMatches, pastDuePath, standaloneMealPlanMatch, switchForGenerateMatch, standaloneGlistMatch, trainerMyRecipesPath, standaloneWorkoutPlanMatch, viewLogsPath, webTrainerLandingPath, webProfessionalMealPlannerPath, helpPath, getAppPath, trainerBrandingMatch, viewChatMatch, trainerMessagingMatch, clientMessagingMatch, viewChatPath, clientMessagingPath, viewInvoicesPath, brandedAppSetupPath, brandedAppsPath, logActivityMatch, clientMealPlansMatch, viewSharedMealsMatch, trainerFormsPath, editTrainerFormMatch, addAssessmentsToFormMatch, assignFormsToClientsMatch, viewClientAssignedFormMatch, viewAssignedFormMatch, strengthTestMatch, strtstFormMatch, progressPicsPath, clientProgressPicsShortcutMatch, progressMatch, nutritionDataInfoPath, onboardMatch, workoutOnboardMatch } from 'config/paths';
import { fullTrainerConvPathFor, paywallPathFor, resolvedStartPath } from 'redux/helpers';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { rootPath, startFlow, startEnd, startPath } from 'config/paths';
import * as transitions from 'assets/transitions';
import { userRecordSelector, historySelector, trainerRecordSelector } from 'redux/selectors';
import { connect } from 'react-redux';
import { getChatCount, jumpToBrowser, logoutUser, uploadTrainerLogo } from 'redux/actions';
import * as _ from 'lib/utilities';
import RouteSidenav from 'components/Sidenav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProRequiredButton, { BottomNavChildTPB } from 'partials/ProRequiredButton';
import { NavLogo } from './Logo';
import { NUTRITION, TRAINING, TSIGNUP, logoUrl } from 'config/settings';
import { MinimalImageUpload } from 'components/Form';
import logoPlaceholder from 'assets/img/logo-placeholder.png';
import { Loader } from 'components/LoadingHOC';


export const mealPlanSlideOverPaths = [
    mealPlanSetupMatch,
    userMealMatch,
    generateNewWeekMatch,
    regenerateWeekMatch,
    viewSharedMealsMatch,
    offPlanMealsSetupMatch,
    addPeopleToMealsMatch,
    editWeeklyMealMatch,
    swapMealMatch,
    recipeBrowserPath,
    mealBrowserPath,
    viewMealPathMatch,
    editRecipeMatch,
    standaloneGlistMatch,
    settingsMatch,
    helpPath,
    getAppPath,
    viewClientAssignedFormMatch,
    viewAssignedFormMatch,
    nutritionDataInfoPath,
    ...emailPaywallMatches
]

export const workoutPlanSlideOverPaths = [
    editRoutinePath,
    workoutPreviewMatch,
    workoutDoMatch,
    workoutSetupMatch,
    logActivityMatch,
    strengthTestMatch,
    strtstFormMatch,
    workoutOnboardMatch
]

export const trainerSlideOverPaths = [
    ...emailPaywallMatches,
    ...mealPlanSlideOverPaths,
    ...workoutPlanSlideOverPaths,
    assignFormsToClientsMatch,
    addAssessmentsToFormMatch,
    trainerFormsPath,
    editTrainerFormMatch,
    clientMessagingMatch,
    viewChatMatch,
    trainerBrandingMatch,
    switchForGenerateMatch,
    standaloneMealPlanMatch,
    mealPlanSignupMatch,
    progressMatch,
    macroAnalyticsPath,
    bodyMeasurementsPath,
    progressPicsPath,
    clientProgressPicsShortcutMatch,
    notificationsPath,
    accountPath,
    pastDuePath,
    errorLogMatch,
    clientBasicProfileMatch,
    clientMealPlanSettingsMatch,
    clientMealPlansMatch,
    clientDetailsMatch,
    viewLogsPath,
    viewInvoicesPath,
    brandedAppSetupPath,
    brandedAppsPath
];

export const slideOverPaths = [
    ...trainerSlideOverPaths,
    trainerSignupMatch
]

export const mealPlanSlideOutPaths = [
    standaloneMealPlanMatch,
    ...allMainMatches
]

export const workoutPlanSlideOutPaths = [
    standaloneWorkoutPlanMatch,
    ...allMainMatches
]

const slideOverRule = [slideOverPaths,transitions.slideOver];

const mainSignedOutTransitionMap = {
    rules: [
        [ambiguousMainMatches,'ambiguousCheck'],
        [mainRouteMatches,transitions.none],
        [[rootPath,startPath,startFlow,startEnd,onboardMatch],transitions.slideOver],
        slideOverRule
    ]
}

const defaultTransitionMap = {
    rules: [
        [allMainMatches,transitions.slideOut],
        [[workoutSetupMatch,...emailPaywallMatches,pickExercisePath,startPath, onboardMatch], transitions.slideOver]
    ]
}

export const mainTransitionMap = {
    rules: [
        [ambiguousMainMatches,'ambiguousCheck'],
        [mainRouteMatches,transitions.none],
        [[rootPath,startPath,startFlow,startEnd,onboardMatch],transitions.slideOut],
        slideOverRule,
        [trainerMainMatches,transitions.slideOut]
    ]
};

const showGetApp = () => {
    return !(window.isCordova || _.isPWA());
}


const LogoutButton = ({ logoutUser, close }) => {
    const { t } = useTranslation();
    const logout = () => {
        close();
        logoutUser();
    }
    
    return (
        <li>
            <div id={`logout-btn`} className="link" onClick={logout}>
                <FontAwesomeIcon icon="sign-out-alt" />
                {t("Sign Out")}
            </div>
        </li>
    )
}

const menuPath = (location) => {
    const { pathname } = location;
    if(matchPath(pathname,{ path: mainMenuMatches })) {
        return pathname;
    } else {
        return `${pathname}/menu`;
    }
}

const MenuOption = ({ icon, path, id, text, closeAndGo, openInApp, color, notifications }) => {

    const classNames = classnames("link position-relative no-wrap",{ 'primary-text': color === 'primary'})

    return (
        <React.Fragment>
            <li>
                <div className={classNames} onClick={() => closeAndGo(path,openInApp)} id={id}>
                    <span className="position-relative">
                        <FontAwesomeIcon icon={icon} className={color === 'primary' ? 'primary-text' : ''} />
                        {!_.isBlank(notifications) && notifications > 0 && <span id={`side-notify-${notifications}-icon`} className="notification-icon">{notifications}</span>}
                    </span>
                    {text}
                    
                </div>
            </li>
            <li><div className="divider ma0"></div></li>
        </React.Fragment>
    )
}

let JumpMenuOption = ({ closeAndGo, jumpToBrowser, ...rest }) => {

    return (
        <MenuOption 
            closeAndGo={(path,openInApp) => {
                if(window.isCordova) {
                    jumpToBrowser(path);
                } else {
                    closeAndGo(path,openInApp);
                }
            }}
            {...rest}
        />
    )
}

const mapDispatchToJumpProps = dispatch => ({
    jumpToBrowser: path => dispatch(jumpToBrowser(path))
})

JumpMenuOption = connect(null,mapDispatchToJumpProps)(JumpMenuOption);

const ProReqMenuOption = ({ icon, path, id, text, closeAndGo, context, user }) => {

    return (
        <React.Fragment>
            <li>
                <ProRequiredButton 
                    user={user} 
                    proPath={path} 
                    blockTypes={['hard','soft']}
                    closeAndGo={closeAndGo}
                    context={context}
                    render={({ onClick }) => (
                        <div className="link" id={id} onClick={onClick}>
                            <FontAwesomeIcon icon={icon} /> {text}
                        </div>
                )} />
            </li>
            <li><div className="divider ma0"></div></li>
        </React.Fragment>
    )
}

const GroceryListButton = ({ user }) => {
    const { t } = useTranslation();

    const sharedProps = { 
        icon: "shopping-basket", 
        label: t("Grocery List"),
        path: groceryListPathFor('needed'),
        pathProps: { path: groceryListMatch },
        id: 'glist-nav-btn' 
    }

    const outerButton = (<ProRequiredButton 
        user={user} 
        proPath={groceryListPathFor('needed')} 
        blockTypes={['old','hard']}
        context="grocery_list"
        render={({ onClick }) => (
            <BottomNavChild  {...sharedProps} onClick={onClick} />
        )} />)

    return outerButton;
}

const getClientMessagingPath = (chatIds) => {
    if(chatIds && chatIds.length === 1) {
        return viewChatPath(chatIds[0]);
    }

    return clientMessagingPath;
}

const MessagingButton = ({ unreadCount, closeAndGo, responseData }) => {
    const { t } = useTranslation();

    return (
        <MenuOption icon="comments-alt" text={t('Messaging')} notifications={unreadCount} path={getClientMessagingPath(responseData && responseData.chatIds)} id="messaging-menu-btn" closeAndGo={closeAndGo} />
    )
}

let MessagingButtonLoader = ({ getChatCount, ...props }) => {

    return (
        <Loader 
            load={getChatCount}
            preloaded={() => true}
            alwaysLoad={true}
            successComponent={MessagingButton}
            type="blank"
            {...props}
        />
    )
}

const mapDispatchToMsgBtnProps = dispatch => ({
    getChatCount: () => dispatch(getChatCount())
})

MessagingButtonLoader = connect(null,mapDispatchToMsgBtnProps)(MessagingButtonLoader)

export const MainNav = ({ user, children, location, className, trainer }) => {
    const { t } = useTranslation();
    const uncheckedCount = user && user.totalUncheckedCount(trainer);
    const menuBtn = <BottomNavChild icon="bars" label={t("Menu")} path={menuPath(location)} id="main-menu-btn" notifications={uncheckedCount} />;
    const signedInAs = trainer && user && user.id !== trainer.id && (
        <div className="signed-in-as">
            Signed in as {user.initialsName()}
        </div>
    )


    if(user && user.showSignedInNav()) {
        const homeBtn = (<BottomNavChild id="home-nav-btn" icon="home" label={t("Home")} path={homePath()} pathProps={homePathProps} />);
        const mealPlanBtn = (<BottomNavChild id="mp-nav-btn" icon="utensils-alt" label={t("Meal Plan")}  path={mealPlanPath} pathProps={{ path: mealPlanMatch }} />);
        const workoutPlanBtn = (<BottomNavChild id="wp-nav-btn" icon="dumbbell" label={t("Workout Plan")} path={workoutPlanPath} />);
        const glistBtn = (<GroceryListButton user={user} />);
        const progressBtn = (<BottomNavChild id="prog-nav-btn" icon="chart-line" label={t("Progress")} path={mainProgressPath} />);

        if(user.showTrainingOnly()) {
            return (
                <BottomNav className={className}>
                    {signedInAs}
                    <ul>
                        {homeBtn}
                        {workoutPlanBtn}
                        {progressBtn}
                        {menuBtn}
                    </ul>
                    {children}
                </BottomNav>
            )
        } else if(user.showNutritionOnly()) {
            return (
                <BottomNav className={className}>
                    {signedInAs}
                    <ul>
                        {homeBtn}
                        {mealPlanBtn}
                        {glistBtn}
                        {menuBtn}
                    </ul>
                    {children}
                </BottomNav>
            )
        } else {
            return (
                <BottomNav className={className}>
                    {signedInAs}
                    <ul>
                        {homeBtn}
                        {mealPlanBtn}
                        {workoutPlanBtn}
                        {glistBtn}
                        {menuBtn}
                    </ul>
                    {children}
                </BottomNav>
            )
        }

    } else {
        const showMyPlan = user && user.workoutPlanInitialized();
        return (
            <BottomNav className={className}>
                {signedInAs}
                <ul>
                    <BottomNavChild icon={<NavLogo />} path={webLandingPath()} pathProps={{ path: '/', exact: true }} />
                    <BottomNavChild icon="utensils-alt" label={t('Meal Planner')}  path={webMealPlannerPath()} />
                    {!showMyPlan && <BottomNavChild icon="dumbbell" label={t('Workout Plans')} path={webWorkoutSplitsPath()} />}
                    {showMyPlan && <BottomNavChild icon="dumbbell" label={t('Workout Plan')} path={workoutPlanPath} />}
                    <BottomNavChild icon="search" label={t('Meal Plans')} path={webMealPlansPath()} />
                    <BottomNavChild icon="award" label={t("Professional Software")} path={webProfessionalMealPlannerPath()} />
                    {menuBtn}
                </ul>
                {children}
            </BottomNav>
        )
    }
}

export const MainSidenav = ({ user, trainer, logoutUser, basePath }) => {
    const { t } = useTranslation();
    const uncheckedCount = user && user.uncheckedNotifications().length;
    const unreadCount = user && user.unreadMessageCount();
    const isSignedInAsClient = _.signedInAsClient(trainer,user);

    return (
        <RouteSidenav path={mainMenuMatches} render={
            ({ close, closeAndGo }) => {
                const helpBtn = <MenuOption text={t('Help')} icon="question-circle" path={helpPath} id='help-btn' closeAndGo={closeAndGo} />
                const blogBtn = <MenuOption text={t('Blog')} icon="rss" id={'blog-btn'} path={webBlogPath()} closeAndGo={closeAndGo} />
                const affiliateBtn = <MenuOption text={t('Affiliate Program')} icon="users" path={webAffiliateProgramPath()} closeAndGo={closeAndGo} />
                const contactBtn = <MenuOption text={(user && user.isClient()) ? t('Report a Bug') : `${t('Contact')}/${t('Feedback')}`} path={contactPath} id='contact-btn' icon="envelope" closeAndGo={closeAndGo} />
                const getAppBtn = <MenuOption text={t('Get the App')} icon="mobile-alt" path={getAppPath} id="get-app-btn" closeAndGo={closeAndGo} />
                const logoutBtn = trainer ? <MenuOption text={(user && user.id === trainer.id) ? t("Exit Personal Account") : t('Sign Out of Client')} path={trainerHomePath} id='sign-out-client-btn' icon="sign-out-alt" closeAndGo={closeAndGo} /> : <LogoutButton logoutUser={logoutUser} close={close} />;
                const accountBtn = <MenuOption text={t('Account')} icon="user" path={accountPath} id="account-btn" closeAndGo={closeAndGo} />;
                const limited = user && user.showLimitedClientMenu();
                const showMessaging = user && user.isClient() && user.hasActiveMessaging() && !isSignedInAsClient;

                let options = null;
                if(limited) {
                    options = (
                        <React.Fragment>
                            {showGetApp() && getAppBtn}
                            {showMessaging && (<MessagingButtonLoader unreadCount={unreadCount} closeAndGo={closeAndGo} />)}
                            {contactBtn}
                            {!_.adminSignedInAsUser(user) && logoutBtn}
                        </React.Fragment>
                    )
                } else if(user && user.showSignedInNav()) {
                    options = (
                        <React.Fragment>
                            {user.isAdmin() && _.isPrimaryDomain() && <MenuOption text={'Admin Dashboard'} icon="users" path={webAdminDashPath()} id="admin-dash-btn" closeAndGo={closeAndGo} openInApp />}
                            {!user.isPro() && !user.isClient() && (<MenuOption text={user.hadTrial ? t('Upgrade to Pro') : t('Start Free Trial')} color="primary" icon="award" path={paywallPathFor('upgrade',user)} id="get-pro-btn" closeAndGo={closeAndGo} />)}
                            {showGetApp() && getAppBtn}
                            {showMessaging && (<MessagingButtonLoader unreadCount={unreadCount} closeAndGo={closeAndGo} />)}
                            <MenuOption text={t('Settings')} icon="cog" path={settingsPath} id="settings-btn" closeAndGo={closeAndGo} />
                            {!user.isClient() && helpBtn}
                            <ProReqMenuOption context="progress_pics" text={t("Progress Photos")} icon="image" id="prog-pics-btn" path={progressPicsPath} closeAndGo={closeAndGo} user={user} />
                            {user.showAllFeatures() && (<ProReqMenuOption context="progress" text={`${t("Progress")}/${t('Stats')}`} icon="chart-line" id="progress-btn" path={progressPath} closeAndGo={closeAndGo} user={user} />)}
                            {!user.showTrainingOnly() && (<ProReqMenuOption context="macro_analytics" text={t("Macro Analytics")} icon="chart-pie" id="macro-analytics-btn" path={macroAnalyticsPath} closeAndGo={closeAndGo} user={user} />)}
                            <MenuOption text={t("Daily Logs")} icon="history" id="daily-logs-btn" path={viewLogsPath} closeAndGo={closeAndGo} />
                            <ProReqMenuOption context="body_measurements" text={t("Body Stats")} icon="ruler-combined" id="body-measurements-btn" path={bodyMeasurementsPath} closeAndGo={closeAndGo} user={user} />
                            <MenuOption text={t('Notifications')} notifications={uncheckedCount} icon="bell" path={notificationsPath} id="notifications-btn" closeAndGo={closeAndGo} />
                            {user.mealPlanInitialized() && !user.showTrainingOnly() && (<MenuOption text={t('Recipe Browser')} icon="search" path={recipeBrowserPath} id="recipe-browser-btn" closeAndGo={closeAndGo} />)}
                            {!_.isBlank(user.email) && (accountBtn)}
                            {!user.isClient() && contactBtn}
                            {basePath && user.canBecomeTrainer() && _.isPrimaryDomain() && (<JumpMenuOption text={t("For Trainers/Nutritionists")} icon="info-circle" path={webTrainerLandingPath()} closeAndGo={closeAndGo} />)}
                            {!user.isClient() && _.isPrimaryDomain() && affiliateBtn}
                            {!user.isClient() && _.isPrimaryDomain() && blogBtn}
                            {!_.adminSignedInAsUser(user) && logoutBtn}
                        </React.Fragment>
                    )
                } else {
                    options = (
                        <React.Fragment>
                            <MenuOption text={`${t('Sign Up')}`} icon="user-plus" path={resolvedStartPath()} closeAndGo={closeAndGo} />
                            {showGetApp() && getAppBtn}
                            <MenuOption text={`${t('Routines')}`} icon="dumbbell" path={webWorkoutSplitsPath()} id="workout-routines-btn" closeAndGo={closeAndGo} />
                            <MenuOption text={`${t('For Professionals')}`} icon="award" path={webTrainerLandingPath()} id="for-pros-btn" closeAndGo={closeAndGo} />
                            <MenuOption text={`${t('Strength')}/${t('Body Stats')}`} icon="chart-pie" path={strengthBodyStatsPath} id="str-bod-stats-btn" closeAndGo={closeAndGo} />
                            <MenuOption text={`${t('Premade Meal Plans')}`} icon="calendar-alt" path={webMealPlansPath()} id="meal-plans-btn" closeAndGo={closeAndGo} />
                            {helpBtn}
                            {contactBtn}
                            {affiliateBtn}
                            {blogBtn}
                            <MenuOption text={`${t('Sign In')}`} icon="sign-in-alt" path={loginPath} id="login-btn" closeAndGo={closeAndGo} />
                        </React.Fragment>
                    )
                }
                return (
                    <React.Fragment>
                        <SidenavLogo user={user} />
                        <li><div className="divider ma0"></div></li>
                        <li id={limited ? 'limited-client-menu' : ''}>
                            <div id={`close-sidenav-btn`} className="link" onClick={() => close()}>
                                <span className="icon-cont text-center" style={{fontSize: '20px'}}><FontAwesomeIcon icon="angle-right" className="no-sidenav" /></span>
                            </div>
                        </li>
                        <li><div className="divider ma0"></div></li>
                        {options}
                    </React.Fragment>
                )
            }
        }  />
    )
}

export const TrainerMainNav = ({ user, children, location, className }) => {
    const { t } = useTranslation();
    const uncheckedCount = user && user.uncheckedNotifications().length;
    let unreadMessages = user && user.unreadMessageCount(user);
    if(_.isBlank(unreadMessages) || unreadMessages <= 0) {
        if(user && user.showMessagingLiveNotif()) {
            unreadMessages = 1;
        }
    }
    const menuBtn = <BottomNavChild icon="bars" label={t("Menu")} path={menuPath(location)} id="main-menu-btn" notifications={uncheckedCount} />;

    return (
        <BottomNav className={className}>
            <ul>
                <BottomNavChild icon="users" label={t("Dashboard")} path={trainerHomePath} pathProps={{ path: trainerHomeMatch }} />
                <BottomNavChildTPB type={NUTRITION} icon="apple-alt" label={t("Recipes")} path={trainerMyRecipesPath} pathProps={{ path: trainerRecipesMatch }} />
                <BottomNavChildTPB type={TRAINING} id="routines-nav-btn" icon="dumbbell" label={t("Routines")} path={myRoutinesPath} pathProps={{ path: routinesBaseMatch }} />
                {user.isAppTrainer() && (<BottomNavChildTPB type={null} id="messaging-nav-btn" icon="comments-alt" notifications={unreadMessages} label={t("Messaging")} path={trainerMessagingPath} pathProps={{ path: trainerMessagingMatch }} />)}
                <BottomNavChildTPB type={null} icon="calendar-check" label={t("Habits")} path={trainerHabitsPath} />
                {user.isTrainerAdmin() && (<BottomNavChildTPB type={null} id="team-nav-btn" icon="id-card-alt" label={t("Team")} path={trainersPath} />)}
                {menuBtn}
            </ul>
            {children}
        </BottomNav>
    )
}

let TrainerLogoUpload = ({ user, uploadTrainerLogo }) => {

    return (
        <MinimalImageUpload 
            callback={(result) => uploadTrainerLogo({ logo: _.dataURItoBlob(result) })}
            render={({ field, id }) => {
                return (
                    <SidenavLogoCore user={user} field={field} id={id} />
                )
            }}
        />
    )
}

const mapDispatchToUploadProps = dispatch => ({
    uploadTrainerLogo: data => dispatch(uploadTrainerLogo(data))
})


TrainerLogoUpload = connect(null,mapDispatchToUploadProps)(TrainerLogoUpload)

const SidenavLogoCore = ({ user, field, id }) => {
    const path = user ? user.logoSource('mediumThumb') : logoUrl();

    return (
        <li className="pt10 pb10">
            <div className="sidenav-logo-cont">
                {field}
                <label htmlFor={id} className={classnames({ clickable: field })}>
                    <img src={path} alt="" />
                </label>
                {user && user.isTrainerAdmin() && path === logoPlaceholder && (<FontAwesomeIcon icon="plus-circle" className="circle-img-icon" />)}
            </div>
        </li>
    )
}

const SidenavLogo = ({ user }) => {

    if(!user || !user.isTrainerAdmin()) {
        return (
            <SidenavLogoCore user={user} />
        )
    }

    return (
        <TrainerLogoUpload user={user} />
    )
}

export const TrainerMainSidenav = ({ user, trainer, logoutUser, basePath }) => {
    const { t } = useTranslation();

    return (
        <RouteSidenav path={trainerMainMenuMatches} render={
            ({ close, closeAndGo }) => {
                const helpBtn = <MenuOption text={t('Help')} icon="question-circle" path={helpPath} id='help-btn' closeAndGo={closeAndGo} />
                const affiliateBtn = <MenuOption text={t('Affiliate Program')} icon="users" path={webAffiliateProgramPath()} closeAndGo={closeAndGo} />
                const contactBtn = <MenuOption text={`${t('Contact')}/${t('Feedback')}`} path={contactPath} id='contact-btn' icon="envelope" closeAndGo={closeAndGo} />
                const getAppBtn = <MenuOption text={t('Get the App')} icon="mobile-alt" path={getAppPath} id="get-app-btn" closeAndGo={closeAndGo} />
                const logoutBtn = <LogoutButton logoutUser={logoutUser} close={close} />;
                const upgradeBtn = trainer.isTrainerSubInit() ? <MenuOption text={t('Switch Plan')} icon="trophy" path={trainerConversionPathFor('switch')} closeAndGo={closeAndGo} /> : <MenuOption text={t('Upgrade Account')} icon="trophy" path={fullTrainerConvPathFor(TSIGNUP)} id="upgrade-trainer-btn" closeAndGo={closeAndGo} />
                const accountBtn = <MenuOption text={t('Account')} icon="user" path={accountPath} id="account-btn" closeAndGo={closeAndGo} />;
                const formsBtn = <MenuOption text={t("Forms, Check-ins, etc")} icon={['far','clipboard-check']} path={trainerFormsPath} id="fcas-btn" closeAndGo={closeAndGo} />;

                return (
                    <React.Fragment>
                        <SidenavLogo user={user} />
                        <li><div className="divider ma0"></div></li>
                        <li>
                            <div id={`close-sidenav-btn`} className="link" onClick={() => close()}>
                                <span className="icon-cont text-center" style={{fontSize: '20px'}}><FontAwesomeIcon icon="angle-right" className="no-sidenav" /></span>
                            </div>
                        </li>
                        <li><div className="divider ma0"></div></li>
                        {showGetApp() && getAppBtn}
                        {upgradeBtn}
                        {trainer.isAppTrainer() && formsBtn}
                        {accountBtn}
                        {contactBtn}
                        {affiliateBtn}
                        {helpBtn}
                        {trainer.showPersonalAccountOption() && (<MenuOption text={t('Personal Account')} icon="arrow-right" path={homePath()} id="personal-account-btn" closeAndGo={closeAndGo} />)}
                        {!_.adminSignedInAsUser(user) && logoutBtn}
                    </React.Fragment>
                )
            }
        }  />
    )
}

class MainLayout extends React.Component {

    constructor(props) {
        super(props);
        const { user, setupTransitions } = props;
        if(user && user.showSignedInNav()) {
            setupTransitions(mainTransitionMap);
        } else {
            setupTransitions(mainSignedOutTransitionMap);
        }
    }

    render() {
        const { user, trainer, scrollRef, location, logoutUser, tabs, children, activeTab } = this.props;
        const classNames = classnames({"nav-container": true, "activity-tabs-container": !!tabs, "with-signed-in-as": (trainer && user && trainer.id !== user.id)});

        return (
            <Page ref={scrollRef}>
                <div className={classNames}>
                    {React.Children.map(children,child => React.cloneElement(child,{ isMain: true, activeTab }))}
                    <MainNav location={location} user={user} trainer={trainer}>
                        {tabs}
                    </MainNav>
                </div>
                <MainSidenav user={user} trainer={trainer} logoutUser={logoutUser} />
            </Page>
        )
    }
}

class LayoutSwitcher extends React.Component {

    constructor(props) {
        super(props);
        const { persistedHistory: { entries } } = props;
        this.isInternal = _.isInternalRequest(entries);
        this.state = { activeTab: props.activeTab || 1 }
    }

    render() {
        const { activeTab } = this.state;
        const { children, transitionMap=defaultTransitionMap, tabLabels, containerClass } = this.props;
        if(this.isInternal) {
            const tabs = tabLabels && (
                <SimpleActivityTabsFull
                    Tab={NonLinkActivityTab}
                    tabLabels={tabLabels}
                    setActiveTab={this.setActiveTab}
                    activeTab={activeTab}
                    isMain={false}
                />
            )

            const containerClasses = classnames({ [containerClass]: containerClass, "with-tabs": !!tabs })

            return (
                <SimpleNavPage
                    {...this.props}
                    containerClass={containerClasses}
                    tabs={tabs}
                    transitionMap={transitionMap}
                >
                    {React.Children.map(children,child => React.cloneElement(child,{ activeTab }))}
                </SimpleNavPage>
            )
        } else {
            const tabs = tabLabels && (
                <SimpleActivityTabsFull
                    Tab={NonLinkActivityTab}
                    tabLabels={tabLabels}
                    setActiveTab={this.setActiveTab}
                    activeTab={activeTab}
                    isMain={true}
                />
            )
            return (
                <MainLayout {...this.props} tabs={tabs} activeTab={activeTab} />
            )
        }
    }

    setActiveTab = (activeTab) => {
        const { setActiveTab } = this.props;
        this.setState({activeTab});
        setActiveTab(activeTab);
    }

}

const mapStateToProps = state => ({
    user: userRecordSelector(state),
    persistedHistory: historySelector(state),
    trainer: trainerRecordSelector(state)
});

const mapDispatchToProps = dispatch => ({
    logoutUser: () => dispatch(logoutUser())
})

LayoutSwitcher = connect(mapStateToProps,mapDispatchToProps)(LayoutSwitcher);

export { LayoutSwitcher }